import AndroidImpl from './android';
import IOSImpl from './ios';
import Web from './web';

const android = new AndroidImpl();
const ios = new IOSImpl();
const web = new Web();

const platforms = [android, ios, web];

const goBack = (...params) => {
    callFunc('goBack', ...params);
};

const seatReservedNative = (webinarName, startTime) => {
    callFunc('seatReserved', webinarName, startTime);
};

const setNavigationBarColor = (color) => {
    callFunc('setNavigationBarColor', color);
};

const setStatusBarColor = (color) => {
    callFunc('setStatusBarColor', color);
};

const shareReferral = (content) => {
    callFunc('shareReferral', content);
}

const trackAnalytics = (content) => {
    callFunc('trackAnalytics', content);
}

const showPremiumFeatureDialog = (feature) => {
    callFunc('showPremiumFeatureDialog', feature);
}

const isFeatureAllowed = (feature) => {
    return callFunc('isFeatureAllowed', feature);
}

const callFunc = (fn, ...params) => {
    let result = null;
    platforms.forEach((platform) => {
    const _result = platform[fn](...params);
        if(_result !== undefined){
            result = _result;
        }
    });

    return result;
};

export { goBack, seatReservedNative, setNavigationBarColor, setStatusBarColor, shareReferral, trackAnalytics, isFeatureAllowed, showPremiumFeatureDialog };