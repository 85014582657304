import React from 'react';
import MaterialDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "../../../node_modules/@material-ui/core/Button/Button";
import green from '@material-ui/core/colors/green';

function Dialog({open, onClose, handleConfirm, title, description, confirmText}) {

    return (
        <MaterialDialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{color : "rgb(178, 191, 214)"}}>
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirm} style={{color : green.A100}} autoFocus>
                    {confirmText}
                </Button>
            </DialogActions>
        </MaterialDialog>
    )
}

export default Dialog;