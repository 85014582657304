import React from 'react';
import {ReactComponent as PayTMIcon} from '../assets/images/paytm.svg'
import {ReactComponent as PayPalIcon} from '../assets/images/paypal.svg';
import {ReactComponent as RazorpayIcon} from '../assets/images/master_card.svg';
import {ReactComponent as CODIcon} from '../assets/images/cod.svg';
import {ReactComponent as MercadoIcon} from '../assets/images/mercadopago.svg';


function Icon(props = {}) {

    function getIconForId (id){
        let _id = id.toString().toUpperCase();
        switch (_id) {
            case 'RAZORPAY':
                return <RazorpayIcon {...props}/>;
            case 'PAYPAL' :
                return <PayPalIcon {...props}/>;
            case 'PAYTM' :
                return <PayTMIcon {...props}/>;
            case 'CASH_ON_DELIVERY' :
                return <CODIcon {...props}/>;
            case 'MERCADO_PAGO' :
                return <MercadoIcon {...props}/>;
            default :
                return null
        }
    }

    return (
        <>
            {props.id ? getIconForId(props.id) : null}
        </>
    )
}

export default Icon;