import React, { useEffect, useState } from 'react';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useQuery from "qs-common/Hooks/useQuery";
import ReferralHome from 'qs-components/Referral/ReferralHome';
import ReferralData from "qs-data/referral";
import { CircularProgress } from "@material-ui/core";
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import './style.scss';

export default function ReferralPage() {

    const [referralData, setReferralData] = useState(null);

    const [progressBar, setProgressBar] = useState(true);
    const [errorComponent, showErrorComponent] = useState(false);

    useEffect(() => {
        fetchReferralCode();
    }, []);

    const fetchReferralCode = async () => {
        setProgressBar(true);
        showErrorComponent(false);
        ReferralData.getReferralCodeAndOffer().then((data) => {
            if (data) {
                setReferralData(data);
            }
        }).catch(() => {
            showErrorComponent(true);
        }).then(() => {
            setProgressBar(false);
        });
    }

    const source = useQuery().get("source");
    const desktop = source === "desktop";
    const [_, dispatch] = useAppContext();

    useEffect(() => {
        dispatch({
            type: 'UPDATE_NAVBAR',
            navBar: {
                background: '#242C36',
                color: '#ffffff',
                title: <b>Use QuickSell free</b>,
                boxShadow: 'none',
                borderBottom: '1px solid #242C36',
                hideBack: desktop
            }
        });
        setNavigationBarColor('#242C36');
        setStatusBarColor('#242C36')
    }, [dispatch]);

    return (
        <div className='referralPage' style={{ alignItems: progressBar || errorComponent ? 'center' : 'flex-end' }}>
            {
                progressBar ? <CircularProgress size={36} color="primary" style={{ marginTop: '8px' }} /> :
                    errorComponent ? <CustomErrorComponent onRetryClick={fetchReferralCode} /> :
                        <ReferralHome
                            referralData={referralData}
                        />
            }
        </div>
    );
}
