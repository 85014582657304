import React, {useState, useEffect, useReducer} from 'react';
import style from './style.css';
import computedStyle from './computedStyles';
import Card from "../../../node_modules/@material-ui/core/Card/Card";
import {makeStyles} from "@material-ui/core/styles";
import CardContent from "../../../node_modules/@material-ui/core/CardContent/CardContent";
import Typography from "../../../node_modules/@material-ui/core/Typography/Typography";
import CustomTextField from "../../common/CustomTextField";
import Button from "@material-ui/core/Button";
import Dialog from "../../common/Alerts/Dialog";
import {Route, Link, useRouteMatch, withRouter} from "react-router-dom";
import network from '../../data/network';
import Loader from "../../common/Loader";
import Toast from "../../common/Alerts/Toast";
import { useAppContext } from '../../common/Contexts/AppContext';
import {handleError} from '../../common/error';
import {validateEmail} from '../../data/util';
import {goBack} from '../../os';
import green from '@material-ui/core/colors/green';

const useStyles = makeStyles((theme) => ({
    infoCard: {
        minWidth: 275,
        backgroundColor : '#252B36',
        marginLeft : 15,
        marginRight : 15,
        marginTop : 25,
        color : '#fff'
    },
    content : {
        display : 'flex',
        flexDirection : 'coloumn',
        "&:last-child": {
            paddingBottom: 16
        }
    },
    button: {
        background : '#2FB57A',
        color : '#FFF',
        '&:hover': {
            backgroundColor: '#2FB57A',
            borderColor: '#2FB57A',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#2FB57A',
            borderColor: '#2FB57A'
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function EmailChanger({history, location, match}) {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [accountEmail, setAccountEmail] = useState('');
    const [displayEmail, setDisplayEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailsLoading, setEmailsLoading] = useState(true);
    const [toastState, setToastState] = useState({
        open : false,
        message : ''
    });

    useEffect(() => {
        if(location.hash === '#alert'){
            setDialogOpen(true);
        } else {
            setDialogOpen(false);
        }
    }, [location.hash]);

    useEffect(() => {
        network.getEmails().then(({accountEmail, displayEmail}) => {
            setEmailsLoading(false);
            setAccountEmail(accountEmail);
            setDisplayEmail(displayEmail);
        }).catch(err => {

            if(err.response.data.reason === "FORBIDDEN"){
                setToastState({
                    open : true,
                    message : 'Token invalid'
                })
            }
        })
    }, []);

    const onSendVerificationEmail = () => {
        history.goBack();
        setLoading(true);
        network.sendVerificationEmail(displayEmail).then(() => {
            setLoading(false);
            setToastState({
                open : true,
                message : 'Verification email sent successfully'
            });
        }).catch((err) => {
            setToastState({
                open : true,
                message : 'An error occured : ' + err.toString()
            });

            setLoading(false);

            handleError(err);
        });
    };

    const [_, dispatch] = useAppContext();
    useEffect(() => {
        dispatch({
            type: 'UPDATE_NAVBAR',
            navBar: {
                background: '#2b313c',
                color: '#FFFFFF',
                title: 'Email settings',
            }
        });
    }, [dispatch]);

    return (
       <div>
            <div className="EmailSettingsContainer" style={computedStyle.EmailSettingsContainer}>
                {emailsLoading ? <div className="LoaderContainer"> <Loader/> </div>: <div className="EmailSettingsContentContainer">
                    <Card className={`${classes.infoCard}`}
                          elevation={3}
                    >
                        <CardContent>
                            <Typography style={{fontWeight : 500, fontSize : '1.2rem'}}>
                                What is a display email?
                            </Typography>
                            <Typography style={{fontSize : '1rem'}}>
                                <p style={{marginTop : 10}}>Your display email will be visible on your catalogues for your customers to see.</p>

                                <p style={{marginTop : 10}}>You will also receive new order notifications and any other communication from QuickSell on this email address.</p>
                            </Typography>
                        </CardContent>
                    </Card>

                    <div className="infoContainer">
                        <CustomTextField
                            fullWidth
                            variant="filled"
                            label="Account Email"
                            margin="normal"
                            value={accountEmail}
                            name="email"
                            disabled
                        />
                        <CustomTextField
                            fullWidth
                            variant="filled"
                            label="Display Email"
                            margin="normal"
                            name="displayEmail"
                            type="email"
                            value={displayEmail}
                            error={errors['email']}
                            helperText={errors['email']}
                            onChange={(e) => {
                                setDisplayEmail(e.target.value);
                            }}
                        />

                        {loading ? <Loader small style={{marginTop : 10}}/> :
                            <Button
                                variant="contained"
                                size="large"
                                className={classes.button}
                                style={{marginTop : 10}}
                                onClick={() => {
                                    if(displayEmail.length > 0 && validateEmail(displayEmail)){
                                        setErrors({});
                                        history.push('#alert');
                                    } else {
                                        setErrors({
                                            ...errors,
                                            email : 'Please enter a valid email'
                                        })
                                    }
                                }}
                            >Change display email</Button>
                        }
                    </div>
                </div>}
            </div>
            <Dialog
                open={dialogOpen}
                title={"Email change request"}
                description= {
                    <span>
                        We will send an email on <b style={{color : green.A100}}>{displayEmail}</b> to verify that you are the owner of that account.
                        Please click on the verification link in the email to set <b>{displayEmail}</b> as your display email address
                    </span>
                }
                confirmText={"Proceed with verification"}
                onClose={() => {
                    history.goBack();
                }}
                handleConfirm={onSendVerificationEmail}
            />
            <Toast
                message={toastState.message}
                open={toastState.open}
                onClose={() => {
                    setToastState({
                        open : false,
                        message : ''
                    });
                }}
            />
        </div>

    )
}

export default withRouter(EmailChanger);
