import React, {useEffect, useState} from 'react';
import Ripples from "react-ripples";
import {useHistory, withRouter} from "react-router-dom";
import './style.scss';

import {setNavigationBarColor, setStatusBarColor, trackAnalytics} from '../../os';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useQuery from "qs-common/Hooks/useQuery";
import HSLColorPicker from "qs-components/Theme/HSLColorPicker";
import ThemeSectionPreview from "qs-components/Theme/ThemeSectionPreview";
import CacheRequest from "qs-data/cacheRequest";
import { ReactComponent as BackIcon } from "qs-assets/images/back.svg";
import CacheKeys from "qs-data/cacheKeys";

const ThemesColorPicker = () => {
  const history = useHistory();

  const source = useQuery().get("source");
  const desktop = source === "desktop";
  const [_, dispatch] = useAppContext();

  const [selectedColors, setSelectedColors] = useState(null);
  const [selectedColorPicker, setSelectedColorPicker] = useState(null);

  useEffect(() => {
    dispatch({
      type: "UPDATE_NAVBAR",
      navBar: {
        background: "#141922",
        color: "#FFFFFF",
        title: !selectedColorPicker ? "Choose color" : `Choose ${selectedColorPicker.title} color`,
        hideBack: desktop,
        hide: true
      },
    });
    setNavigationBarColor('#212934');
    setStatusBarColor('#141922')
  }, [dispatch, selectedColorPicker]);

  useEffect(() => {
    const state = history.location.state || {};
    if (state.selectedColors) {
      setSelectedColors(state.selectedColors)
    }
    if (state.selectedColorPicker) {
      setSelectedColorPicker(state.selectedColorPicker)
    }
    const mainAppElement = document.getElementById('App-main');
    mainAppElement.style.paddingTop = 0; //remove empty navbar

    return () => {
      mainAppElement.style.paddingTop = '4rem'; //reinstate navbar padding
    }
  }, []);

  if (!selectedColorPicker || !selectedColors) {
    return null
  }

  const onColorChanged = (updatedColor) => {
    const updatedColorVal = {};
    updatedColorVal[selectedColorPicker.key] = updatedColor;
    setSelectedColors(oldState=> ({ ...oldState, [selectedColorPicker.key]: updatedColor }))

    CacheRequest.setCacheForKey(selectedColorPicker.key, updatedColor);
    CacheRequest.setCacheForKey(CacheKeys.colorUpdatedToggle, true);
  }

  const renderColorPickerSliderSection = () => {
    return <div className='pickerContainer'>
      <Ripples className='backButtonContainer' onClick={onCancel}>
        <BackIcon fill="#FFF" width="15" height="15"/>
        <p className={'back'}>Back</p>
      </Ripples>
      <HSLColorPicker
         selectedColorPicker={selectedColorPicker}
         onColorChanged={onColorChanged}
      />
    </div>
  }

  const renderPreviewSection = () => {
    return  <ThemeSectionPreview
      primaryColor = {selectedColors.primaryColor}
      contrastTextColor = {selectedColors.contrastTextColor}
      pageTextColor = {selectedColors.pageTextColor}
      backgroundColor = {selectedColors.backgroundColor}
    />
  }

  const onCancel = () => {
    history.goBack();
  }

  return (
    <div className='themesColorPickerContainer'>
      {renderPreviewSection()}
      {renderColorPickerSliderSection()}
    </div>
  );
}

export default withRouter(ThemesColorPicker);
