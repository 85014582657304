import Native from "./shared";
import CacheRequest from "qs-data/cacheRequest";
import CacheKeys from "qs-data/cacheKeys";

class Web extends Native {
  constructor() {
    super();
  }

  goBack(...params) {
    const [history] = params || [];
    if (history && history.goBack()) {
      history.goBack();
    } else {
      super.goBack();
    }
  }

  shareReferral (content) {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (!userAgent.match(/Android/i)) {
      window.open(`https://api.whatsapp.com/send?text=${content}`, "_blank");
    }
  }

  trackAnalytics (params) {
    window.parent.postMessage({
      'func': 'trackAnalytics',
      'params': params
    }, "*");
  }
}

export default Web;
