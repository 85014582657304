import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import Ripples from 'react-ripples';
import ReferralCard from '../ReferralCard';
import { ReactComponent as RewardSchemeIcon } from 'qs-assets/images/reward_scheme_icon.svg';
import ReferralRewardSchemeDialog from '../ReferralRewardSchemeDialog';
import './style.scss';

export default function ReferralList({ referralList, onRedeemSuccess }) {

    const [showDialog, toggleShowDialog] = useState(false);

    return (
        <div className={'referralSection'}>
            <div className='rewardSection'>
                <Ripples className='rewardContainer' onClick={() => toggleShowDialog(true)}>
                    <RewardSchemeIcon fill="#FFF" width="16" height="16" />
                    <span className='rewardText'>View reward scheme</span>
                </Ripples>
            </div>
            {
                isEmpty(referralList) ? <p className='emptyReferralList'>Start sharing your referral code with friends now.</p> :
                    referralList.map(function (item, index) {
                        return <ReferralCard
                            key={index}
                            data={item}
                            onRedeemSuccess={onRedeemSuccess} />
                    })
            }
            <ReferralRewardSchemeDialog
                showDialog={showDialog}
                onClose={() => toggleShowDialog(false)}
            />
        </div>
    );
}
