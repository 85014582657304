import React, { useState } from 'react';
import { isNil } from 'lodash';
import Ripples from 'react-ripples';
import CustomDivider from "qs-common/CustomDivider";
import Utility from 'qs-helpers/utility';
import { ReactComponent as EditIcon } from 'qs-assets/images/edit.svg';
import SingleWeightProfilesWithPriceDialog from "../SingleWeightProfilesWithPriceDialog";
import CurrencyMapper from 'qs-helpers/currency';
import "./style.scss";

export default function ({ weightProfile, onSuccess, currencyUnit, weightUnit }) {

  const [addShippingWeightPricingDialog, toggleShippingWeightPriceDialog] = useState(false);
  const [shippingWeightItem, setShippingWeightItem] = useState({});

  const openPriceDialog = (item) => {
    setShippingWeightItem(item);
    toggleShippingWeightPriceDialog(true)
  }

  const closePriceDialog = () => {
    toggleShippingWeightPriceDialog(false)
  }

  const updatePrice = (updatedItem) => {
    onSuccess();
    toggleShippingWeightPriceDialog(false)
  }

  const renderPrice = (weightProfile) => {
    if (weightProfile.perUnit === -1) {
      return "Flat price : " + (CurrencyMapper[currencyUnit] || currencyUnit) + " " + weightProfile.price;
    } else {
      return (CurrencyMapper[currencyUnit] || currencyUnit) + " " + weightProfile.price + ' ' + Utility.getShippingPriceWeightFromKey(weightUnit, weightProfile.perUnit).value
    }
  }

  return <div className='singleWeightProfileContainer' onClick={() => openPriceDialog(weightProfile)}>
    <div className='weightAndPriceContainer'>
      <p className='weight' style={{ margin: '12px' }} >{Utility.getWeightProfileName(weightProfile.weight, weightUnit)}</p>
      <CustomDivider />
      <p className='price'>{isNil(weightProfile.price) ? 'Enter rate' : renderPrice(weightProfile)}</p>
    </div>
    <Ripples className='editIconContainer' onClick={() => openPriceDialog(weightProfile)}>
      <EditIcon fill="#FFF" width="20" height="20"/>
    </Ripples>
    {addShippingWeightPricingDialog && <SingleWeightProfilesWithPriceDialog
      title='Add price'
      currencyUnit={currencyUnit}
      weightUnit={weightUnit}
      shippingWeightItem={shippingWeightItem}
      showDialog={addShippingWeightPricingDialog}
      closeDialog={closePriceDialog}
      onSuccess={updatePrice}
      onClose={closePriceDialog}
    />
    }
  </div>
}
