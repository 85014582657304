import React, { useState, useCallback, useEffect } from 'react';
import Ripples from 'react-ripples';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomCheckboxGroup from 'qs-common/CustomCheckboxGroup';
import Loader from 'qs-common/Loader';
import FieldIcon from './FormFieldIcon';
import { SET_NEXT_STATE, UPDATE_NEW_FIELD } from '../reducer';
import { CHECKOUT_FORM_STATES, CHECKOUT_FORM_FIELD_TYPES } from '../constants';
import { addNewFormFieldComponent } from '../formFieldsData';
import {
  getCheckoutFormTitleDetails,
  getNextFormState,
  checkIfNewFieldIsValid,
  checkIfOptionsAreRequiredFormField,
  getOptionIfRequiredForFormField,
  registerGetSelectedFormField,
  getAvailableOptionsToSelect
} from '../helpers';
import './style.scss';

const EditCheckoutFormFields = ({
  theme = {},
  refreshing,
  newFormField,
  formFieldsData,
  currentFormState,
  onCloseCallback,
  dispatchCheckoutFormsData,
  showSnackbarMessage
}) => {
  const [formFieldLabelHasError, setFormFieldLabelHasError] = useState(false);
  const [formFieldOptionsHasError, setFormFieldOptionsHasError] = useState(false);

  useEffect(() => {
    // creating one additonal route history so that on back click it stays on the same route
    window.history.pushState(null, document.title, window.location.url);
    window.addEventListener('popstate', onCloseCallback);
    return () => window.removeEventListener('popstate', onCloseCallback);
  }, [onCloseCallback]);

  const updateNewFormField = useCallback((updatedFormField) => {
    dispatchCheckoutFormsData({
      type: UPDATE_NEW_FIELD,
      formField: updatedFormField
    });
  }, [dispatchCheckoutFormsData]);

  const updateFormState = changedState => {
    if (!changedState) {
      return;
    }

    const copiedNewFormField = { ...newFormField, ...changedState };
    updateNewFormField(copiedNewFormField);
    registerGetSelectedFormField(() => copiedNewFormField);
  };

  const setCurrentFormState = useCallback(nextState => {
    dispatchCheckoutFormsData({
      type: SET_NEXT_STATE,
      nextState
    });
  }, [dispatchCheckoutFormsData]);

  const setNextFormState = nextFormState => {
    setCurrentFormState(getNextFormState(nextFormState === undefined ? currentFormState : nextFormState));
  };

  const addNewOptionInFormField = () => {
    const copiedOptions = [...(newFormField.fieldOptions || [])];
    copiedOptions.push('');
    updateFormState({ fieldOptions: copiedOptions });
  };

  const onSubmitHandler = useCallback(event => {
    event.preventDefault();

    if (checkIfNewFieldIsValid(newFormField, {
      fieldLabelErrorCallback: setFormFieldLabelHasError,
      fieldOptionsErrorCallback: setFormFieldOptionsHasError
    })) {
      addNewFormFieldComponent(
        newFormField,
        formFieldsData,
        dispatchCheckoutFormsData,
        message => {
          onCloseCallback();
          showSnackbarMessage(message)
          window.history.go(-1);
        }
      );
    }
  }, [newFormField, formFieldsData, dispatchCheckoutFormsData, onCloseCallback, showSnackbarMessage])
  
  const addFieldTypeToFormField = fieldType => {
    setTimeout(() => {
      updateFormState({ fieldType, fieldOptions: getOptionIfRequiredForFormField(fieldType) });
      setNextFormState(CHECKOUT_FORM_STATES.ENTER_NAME);
    }, 500);
  };

  const onChangeFieldLabelHandler = event => {
    const { value: fieldName } = event.target;
    updateFormState({ fieldName });
    setFormFieldLabelHasError(!fieldName);
  };

  const onChangeFieldRequiredHandler = event => {
    const { checked: required } = event.target;
    updateFormState({ required });
  };

  const onChangeOptionValueHandler = (event, optionIndex) => {
    const { value: option } = event.target;
    const copiedFieldOptions = [...(newFormField.fieldOptions || [])];
    copiedFieldOptions[optionIndex] = option;
    setFormFieldOptionsHasError(false);
    updateFormState({ fieldOptions: copiedFieldOptions });
  };

  const handleOnChangeOption = selectedOptions => {
    updateFormState({ fieldOptions: selectedOptions });
  };

  const renderFormTitle = () => {
    const { label, tagline } = getCheckoutFormTitleDetails(currentFormState);
    if (!label && !tagline) {
      return;
    }

    return (
      <Box className="form-field-list-details form-title">
        <h4 className="name">{label}</h4>
        <h6 className="tagline">{tagline}</h6>
      </Box>
    );
  };

  const renderOptionsComponent = () => {
    const { fieldType, fieldOptions } = newFormField;

    // DROPDOWN and MULTISELECT
    if (checkIfOptionsAreRequiredFormField(fieldType)) {
      return (
        <>
          {Array.isArray(fieldOptions) &&
            fieldOptions.map((option, optionIndex) => (
              <TextField
                key={optionIndex}
                value={option}
                className="new-field-label-input"
                variant="outlined"
                label={`Enter Choice ${optionIndex + 1}`}
                placeholder="Enter here"
                error={formFieldOptionsHasError && !option}
                onChange={e => onChangeOptionValueHandler(e, optionIndex)}
              />
          ))}
          <Button
            className="add-new-option-btn"
            type="button"
            variant="outlined"
            color="secondary"
            onClick={addNewOptionInFormField}
          >+ ADD</Button>
        </>
      );
    };

    const availableSelectableOptionsConfig =  getAvailableOptionsToSelect(fieldType);
    // DATE and TIME
    if (availableSelectableOptionsConfig) {
      const { label, options } = availableSelectableOptionsConfig;
      return (
        <CustomCheckboxGroup
          className="new-field-available-options"
          label={label}
          options={options}
          onChange={handleOnChangeOption}
        />
      )
    }
  };

  const renderFormContent = () => {
    const { main: secondaryColor }  = (theme.palette || {}).secondary || { main: {} };
    if (currentFormState === CHECKOUT_FORM_STATES.SELECT_FORM_TYPE) {
      return <List className="field-type-list">
        {CHECKOUT_FORM_FIELD_TYPES.map(({
          fieldType,
          label,
          tagline
        }) => (
          <Ripples key={fieldType} className="field-type-list-item" onClick={() => addFieldTypeToFormField(fieldType)}>
            <div className="icon"><FieldIcon iconType={fieldType} color={secondaryColor} /></div>
            <div className="form-field-list-details">
              <h5 className="name">{label}</h5>
              <h6 className="tagline">{tagline}</h6>
            </div>
          </Ripples>
        ))}
      </List>
    }

    if (currentFormState === CHECKOUT_FORM_STATES.ENTER_NAME) {
      const { fieldName, required } = newFormField || {};
      const requiredCheckboxButton = (
        <FormControlLabel className="new-field-required" control={
          (<Checkbox label="Required" checked={required || false} onChange={onChangeFieldRequiredHandler} />)
        } label="Required Field" />
      );
      const fieldLabelInput = (
        <TextField
          id="new-field-label-input"
          placeholder="Enter here"
          className="new-field-label-input"
          label="Enter your field name here"
          variant="outlined"
          autoFocus
          value={fieldName}
          error={formFieldLabelHasError}
          onChange={onChangeFieldLabelHandler}
        />
      );

      return <>
        {fieldLabelInput}
        {renderOptionsComponent()}
        {requiredCheckboxButton}
      </>
    }
  };

  const renderFormActions = () => {
    if (currentFormState === CHECKOUT_FORM_STATES.SELECT_FORM_TYPE) {
      return;
    }

    return (
      <Box className="actions">
        <Button
          className="submit-btn"
          variant="contained"
          type="submit"
          color="secondary"
          disabled={refreshing}
          onClick={onSubmitHandler}
        >{refreshing ?  <Loader small /> : 'Save'}</Button>
      </Box>
    );
  };

  return (
    <form className="edit-checkout-field-form" onSubmit={onSubmitHandler} noValidate autoComplete="off">
      <div className="form-content">
        {renderFormTitle()}
        {renderFormContent()}
      </div>
      {renderFormActions()}
    </form>
  );
}

export default EditCheckoutFormFields;
