import React, {useEffect, useState, useRef} from 'react';
import Ripples from "react-ripples";
import {useHistory, withRouter} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
import Toast from "qs-common/Alerts/Toast";
import './style.scss';

import {setNavigationBarColor, setStatusBarColor, trackAnalytics} from '../../os';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import {useAppContext} from 'qs-common/Contexts/AppContext';
import useQuery from "qs-common/Hooks/useQuery";
import CacheRequest from "qs-data/cacheRequest";
import CacheKeys from "qs-data/cacheKeys";
import PersonalizedThemeData from "qs-data/personalizedTheme";
import {ReactComponent as DeleteIcon} from 'qs-assets/images/trash.svg';
import DeleteThemeDialog from "qs-components/Theme/DeleteThemeDialog";
import SaveThemeDialog from "qs-components/Theme/SaveThemeDialog";
import ThemeNameDialog from "qs-components/Theme/ThemeNameDialog";
import ThemeUpdateSection from "qs-components/Theme/ThemeUpdateSection";
import { DEFAULT_THEME_ID } from "qs-helpers/utility";
import cacheKeys from "qs-data/cacheKeys";

const ThemeUpdate = () => {
  const history = useHistory();
  const source = useQuery().get("source");
  const desktop = source === "desktop";
  const [_, dispatch] = useAppContext();

  const [finishedGoingBack, setFinishedGoingBack] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [saveThemeProgressBar, setSaveThemeProgressBar] = useState(false);
  const [errorComponent, showErrorComponent] = useState(false);
  const [showDeleteThemeDialog, setShowDeleteThemeDialog] = useState(false);
  const [showSaveThemeDialog, setShowSaveThemeDialog] = useState(false);
  const [showThemeNameDialog, setShowThemeNameDialog] = useState(false);
  const [savePopupShown, setSavePopupShown] = useState(false);
  const [saveThemeButtonClicked, setSaveThemeButtonClicked] = useState(false);

  const [themeId, setThemeId] = useState(CacheRequest.getCacheForKey(CacheKeys.selectedThemeId));
  const [name, setName] = useState(CacheRequest.getCacheForKey(cacheKeys.name));
  const [theme, setTheme] = useState(CacheRequest.getCacheForKey(CacheKeys.selectedTheme) || {});
  const [toastState, setToastState] = useState({open: false, message: ""});

  const cachedPrimaryColor = CacheRequest.getCacheForKey(CacheKeys.primaryColor);
  const cachedPageTextColor = CacheRequest.getCacheForKey(CacheKeys.pageTextColor);
  const cachedContrastTextColor = CacheRequest.getCacheForKey(CacheKeys.contrastTextColor);
  const cachedBackgroundColor = CacheRequest.getCacheForKey(CacheKeys.backgroundColor);

  let cachedColors = {};
  if (cachedPrimaryColor) {
    cachedColors.primaryColor = cachedPrimaryColor
  }
  if (cachedPageTextColor) {
    cachedColors.pageTextColor = cachedPageTextColor
  }
  if (cachedContrastTextColor) {
    cachedColors.contrastTextColor = cachedContrastTextColor
  }
  if (cachedBackgroundColor) {
    cachedColors.backgroundColor = cachedBackgroundColor
  }

  const [selectedColors, setSelectedColors] = useState(Object.keys(cachedColors).length > 0 ? cachedColors : null);
  const [selectedColorPicker, setSelectedColorPicker] = useState(null);

  useEffect(() => {
    if (Object.keys(theme).length === 0) {
      getTheme();
    }

    if (CacheRequest.getCacheForKey(CacheKeys.colorUpdatedToggle)) {
      if (!CacheRequest.getCacheForKey(CacheKeys.nullEntryAddedToHistory)) {
        CacheRequest.setCacheForKey(CacheKeys.nullEntryAddedToHistory, true);
        window.history.pushState(null, null, window.location.href);
      }
      window.addEventListener('popstate', onBackButtonEvent);
      return () => {
        window.removeEventListener('popstate', onBackButtonEvent);
      };
    }
  }, []);

  useEffect(() => {
    if (finishedGoingBack) {
      window.history.go(-1)
    }
  }, [finishedGoingBack])

  useEffect(() => {
    if (savePopupShown && !finishedGoingBack && window.location.hash === '#/theme-update') {
      setSaveThemeButtonClicked(true);
      setShowSaveThemeDialog(true);
      // if (window.confirm("Do you want to save theme ?")) {
      //   pushEmptyStateToHistory();
      // } else {
      //   allowBack();
      // }
    }
  }, [savePopupShown])

  useEffect(()=> {
    if (selectedColorPicker) {
      history.push({
        pathname: '/color-picker',
        state: {
          selectedColors: selectedColors,
          selectedColorPicker: selectedColorPicker
        }
      });
    }
  }, [selectedColorPicker])

  useEffect(() => {
    if (Object.keys(theme).length > 0) {
      theme.name = name;
      dispatch({
        type: "UPDATE_NAVBAR",
        navBar: {
          background: "#141922",
          color: "#FFFFFF",
          hideBack: desktop,
          value: name,
          placeholder: 'Enter theme name',
          readOnlyTextField: true,
          onClick: () => setShowThemeNameDialog(true)
        },
      });
    } else {
      dispatch({
        type: "UPDATE_NAVBAR",
        navBar: {
          background: "#141922",
          color: "#FFFFFF",
          hideBack: desktop,
        },
      });
    }
    setNavigationBarColor('#2E3744');
    setStatusBarColor('#141922')
  }, [dispatch, theme, selectedColorPicker, name]);

  useEffect(()=> {
    if (saveThemeButtonClicked) {
      onThemeSave();
    }
  }, [name])

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    setSavePopupShown(true)
  }

  const pushEmptyStateToHistory = () => {
    window.history.pushState(null, null, window.location.href);
    setFinishedGoingBack(false)
    if (name) {
      onThemeSave()
    } else {
      setShowThemeNameDialog(true);
    }
  }

  const allowBack = () => {
    window.removeEventListener('popstate', onBackButtonEvent);
    setFinishedGoingBack(true);
  }

  const getTheme = () => {
    setProgressBar(true);
    showErrorComponent(false);
    PersonalizedThemeData.getTheme(themeId || DEFAULT_THEME_ID).then((data) => {
      if (data) {
        if (data.id !== DEFAULT_THEME_ID) {
          CacheRequest.setCacheForKey(cacheKeys.name, data.name);
          setName(data.name);
        }
        CacheRequest.setCacheForKey(cacheKeys.selectedTheme, data);
        setTheme(data);
        setSelectedColors({
          primaryColor: data.addToCartButton.color,
          pageTextColor: data.pageTextColor,
          contrastTextColor: data.addToCartButton.textColor,
          backgroundColor: data.homeGrid.backgroundColor
        });
        CacheRequest.setCacheForKey(CacheKeys.primaryColor, data.addToCartButton.color);
        CacheRequest.setCacheForKey(CacheKeys.pageTextColor, data.pageTextColor);
        CacheRequest.setCacheForKey(CacheKeys.contrastTextColor, data.addToCartButton.textColor);
        CacheRequest.setCacheForKey(CacheKeys.backgroundColor, data.homeGrid.backgroundColor);
      }
    }).catch((ex) => {
      console.log('Error while fetching theme', ex)
      showErrorComponent(true);
      setToastState({
        open: true,
        message: "Error while fetching default theme, please try again.",
      });
    }).then(() => {
      setProgressBar(false);
    });
  }

  const addNewTheme = () => {
    const { primaryColor, pageTextColor, contrastTextColor, backgroundColor } = selectedColors;
    const themeObject = {
      primaryColor,
      pageTextColor,
      contrastTextColor,
      backgroundColor,
      name
    };
    setSaveThemeProgressBar(true);
    PersonalizedThemeData.addTheme(themeObject).then((data) => {
      const theme = data.theme;
      setTheme(theme);
      setThemeId(theme.id);
      CacheRequest.setCacheForKey(cacheKeys.selectedTheme, theme);
      CacheRequest.setCacheForKey(cacheKeys.selectedThemeId, theme.id);
      setToastState({
        open: true,
        message: "Theme added successfully.",
      });
      setFinishedGoingBack(true)
      CacheRequest.deleteCacheForKeys([cacheKeys.colorUpdatedToggle]);
      trackAnalytics({
        eventName: 'theme_created',
        props: {
          theme_id: theme.id
        }
      });
    }).catch((ex) => {
      console.log('Error while adding theme', ex)
      setToastState({
        open: true,
        message: "Error while adding theme, please try again.",
      });
    }).then(() => {
      setSaveThemeProgressBar(false);
    });
  }

  const updateTheme = () => {
    setSaveThemeProgressBar(true);
    const { primaryColor, pageTextColor, contrastTextColor, backgroundColor } = selectedColors;
    const themeObject = {
      primaryColor,
      pageTextColor,
      contrastTextColor,
      backgroundColor,
      name,
      id: theme.id
    };
    PersonalizedThemeData.updateTheme(themeObject).then(() => {
      setToastState({
        open: true,
        message: "Theme updated successfully.",
      });
      setFinishedGoingBack(true)
      CacheRequest.deleteCacheForKeys([cacheKeys.colorUpdatedToggle]);
      trackAnalytics({
        eventName: 'theme_updated',
        props: {
          theme_id: theme.id
        }
      });
    }).catch((ex) => {
      console.log('Error while updating theme', ex);
      setToastState({
        open: true,
        message: "Error while updating theme, please try again.",
      });
    }).then(() => {
      setSaveThemeProgressBar(false);
    });
  }

  const onThemeSave = () => {
    if (themeId) {
      updateTheme();
    } else {
      addNewTheme();
    }
  }

  const onSuccessDeleteThemeDialog = () => {
    setShowDeleteThemeDialog(false);
    history.goBack();
  }

  const onSuccessThemeNameDialog = (themeName) => {
    setName(themeName);
    CacheRequest.setCacheForKey(cacheKeys.name, themeName);
    setShowThemeNameDialog(false);
  }

  if (progressBar) {
    return <div className='themesUpdateContainer' style={{ alignItems: 'center', justifyContent: 'center'}}>
      <CircularProgress size={36} color="primary" style={{ marginTop: '8px' }} />
    </div>
  }

  if (errorComponent) {
    return <div className='themesUpdateContainer' style={{ alignItems: 'center', justifyContent: 'center'}}>
      <CustomErrorComponent onRetryClick={getTheme} />
    </div>
  }

  if (Object.keys(theme).length === 0) {
    return null;
  }

  const onSaveButtonFooterClicked = () => {
    if (name) {
      onThemeSave()
    } else {
      setSaveThemeButtonClicked(true)
      setShowThemeNameDialog(true)
    }
  }

  const renderFooter = () => {
    return <div className='footer'>
      {
        themeId ? <Ripples className={'deleteThemeButtonsContainer'} onClick={() =>  setShowDeleteThemeDialog(true)}>
          <DeleteIcon fill="#FFF" width="20" height="20"/>
        </Ripples> : <div />
      }
      {
        saveThemeProgressBar ?  <CircularProgress size={24} color="primary" style={{ marginRight: '16px' }} /> :
          <Ripples className={'saveThemeButtonsContainer'} onClick={onSaveButtonFooterClicked}>
            <span>Save Theme</span>
          </Ripples>
      }
    </div>
  }

  const renderDeleteThemeDialog = () => {
    return showDeleteThemeDialog && <DeleteThemeDialog
      showDialog={showDeleteThemeDialog}
      onClose={() => setShowDeleteThemeDialog(false)}
      onSuccess={onSuccessDeleteThemeDialog}
      theme={theme}
    />
  }

  const renderSaveThemeDialog = () => {
    return showSaveThemeDialog && <SaveThemeDialog
      showDialog={showSaveThemeDialog}
      onClose={allowBack}
      onSave={pushEmptyStateToHistory}
    />
  }

  const renderThemeNameDialog = () => {
    return showThemeNameDialog && <ThemeNameDialog
      showDialog={showThemeNameDialog}
      defaultValue={name}
      onClose={() => setShowThemeNameDialog(false)}
      onSuccess={(themeName) => {onSuccessThemeNameDialog(themeName)}}
    />
  }

  return (
    <div className='themesUpdateContainer'>
      <ThemeUpdateSection
        selectedColors={selectedColors}
        setSelectedColorPicker={setSelectedColorPicker}
      />
      {renderFooter()}
      {renderDeleteThemeDialog()}
      {renderSaveThemeDialog()}
      {renderThemeNameDialog()}
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: "",
          });
        }}
      />
    </div>
  );
}

export default withRouter(ThemeUpdate);
