import React from 'react';
import { useHistory } from "react-router-dom";
import { ReactComponent as NextIcon } from 'qs-assets/images/right_arrow.svg';
import CustomDivider from 'qs-common/CustomDivider';
import Utility from 'qs-helpers/utility';
import "./style.scss";

export default function ({ shippingModes = [], shippingZones = [], weightUnit, isDesktop }) {

    const history = useHistory();

    function addPrice(item) {
        history.push({
            pathname: '/shipping-rates',
            state: {
                mode: item.mode,
                zone: item.zone,
                weightUnit: weightUnit,
                isDesktop: isDesktop
            }
        });
    }

    const shippingProfiles = [];
    shippingModes.forEach(function (mode) {
        if (mode.enabled) {
            const sortedZones = Utility.getSortedZones(shippingZones);
            sortedZones.forEach(function (zone) {
                if (zone.enabled) {
                    shippingProfiles.push({
                        mode: mode,
                        zone: zone
                    })
                }
            })
        }
    })

    return <div className='shippingProfiles'>
        {
            shippingProfiles.map(function (item, index) {
                return <div key={index} onClick={() => addPrice(item)}>
                    <div className='singleShippingProfile'>
                        <span className='shippingProfileText' >{item.mode.value + ' - ' + Utility.getShippingZoneDisplayName(item.zone)}</span>
                        <div className='shippingProfileEnterRates'>
                            <p className='rateText'>ENTER RATES</p>
                            <NextIcon fill="#FFF" width="20" height="20" />
                        </div>
                    </div>
                    {index === shippingProfiles.length - 1 ? null :
                        <CustomDivider customStyle={{ marginBottom: '16px', marginTop: '16px' }} />}
                </div>
            })
        }
    </div>
} 