import React from 'react';
import "./style.scss";

export default function ({ errorMessage = 'Something went wrong', onRetryClick, style }) {
    return <div className='errorMessageContainer' style={style}>
        <span className='errorMessage'>{errorMessage}</span>
        {onRetryClick ? <span>,
            <span className='retryButton' onClick={onRetryClick}>retry</span>
        </span> : null}
    </div>
} 