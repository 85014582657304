import React, { Fragment, useState } from 'react';
import DialogBox from "qs-common/DialogBox";
import "./style.scss";

export default function ({ showDialog, onClose, onSave }) {

  const [closeDialog, setCloseDialog] = useState(false);

  const onSaveClick = async () => {
    setCloseDialog(true);
    setTimeout(()=> {
      onSave();
    }, 200)
  }

  return <DialogBox
    type={"simple"}
    dialogId={'#save-theme'}
    show={showDialog}
    width={300}
    primaryBtnText={'SAVE THEME'}
    secondaryBtnText={'CANCEL'}
    onSuccess={onSaveClick}
    onClose={onClose}
    closeDialog={closeDialog}
  >
    <Fragment>
      <div className='saveTheme'>
        <p className='dialogTitle'>{`This theme is still unsaved. Would you like to save this theme?`}</p>
      </div>
    </Fragment>
  </DialogBox>
}
