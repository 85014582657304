import React, { Fragment, useRef, useState } from 'react';
import { isNil } from 'lodash';
import { TextField, InputAdornment } from '@material-ui/core';
import ShippingData from "qs-data/shipping";
import DialogBox from "qs-common/DialogBox";
import CustomSelect from 'qs-common/CustomSelect';
import Utility from 'qs-helpers/utility';
import CurrencyMapper from 'qs-helpers/currency';
import "./style.scss";

export default function ({ title, shippingWeightItem = {}, showDialog, onClose, onSuccess, currencyUnit, weightUnit }) {
    const amountRef = useRef();
    const perUnits = Utility.getShippingPriceWeightUnits(weightUnit)
    const [isValidAmount, toggleValidAmount] = useState(!isNil(shippingWeightItem.price));
    const [selectedPerUnit, setSelectedPerUnit] = useState(isNil(shippingWeightItem.perUnit) ? perUnits[0] : Utility.getShippingPriceWeightFromKey(weightUnit, shippingWeightItem.perUnit));

    const [progressBar, setProgressBar] = useState(false);
    const [closeDialog, setCloseDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const updateShippingPrice = async () => {
        setProgressBar(true);
        setErrorMessage(null);
        const roundedOffAmount = +parseFloat(amountRef.current.value).toFixed(2);
        const data = {
            "id": shippingWeightItem.shippingPriceId,
            "shippingModeId": shippingWeightItem.shippingModeId,
            "shippingZoneId": shippingWeightItem.shippingZoneId,
            "price": roundedOffAmount,
            "perUnit": selectedPerUnit.key
        }
        ShippingData.setShippingCostPrices(data).then(() => {
            setCloseDialog(true);
            shippingWeightItem.perUnit = selectedPerUnit.key;
            shippingWeightItem.price = roundedOffAmount;
            onSuccess();
        }).catch((err) => {
            setErrorMessage(err);
        }).then(() => {
            setProgressBar(false)
        });
    }

    const onChange = () => {
        let errorMsg = null;
        const isNumberInValid = Utility.isNumberInValid(amountRef);
        if (isNumberInValid) {
          errorMsg = 'Amount cannot be less than 0';
        }
        setErrorMessage(errorMsg);
        toggleValidAmount(!isNumberInValid);
      }

    const unitChange = (e) => {
        setSelectedPerUnit(e.target.value)
    }

    return <DialogBox
        type={"simple"}
        dialogId={'#add-new-price'}
        show={showDialog}
        width={300}
        onClose={onClose}
        onSuccess={updateShippingPrice}
        primaryBtnText={'SAVE'}
        disabledPrimaryButton={!isValidAmount}
        showProgressBar={progressBar}
        closeDialog={closeDialog}
    >
        <Fragment>
            <div className='addNewWeightProfile'>
                <p className='dialogTitle'>{title}</p>
                <TextField
                    autoFocus
                    inputRef={amountRef}
                    type='number'
                    defaultValue={shippingWeightItem.price}
                    onChange={onChange}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{CurrencyMapper[currencyUnit] || currencyUnit}</InputAdornment>,
                    }}
                    error={errorMessage !== null}
                    helperText={errorMessage} />
                <CustomSelect
                    handleChange={unitChange}
                    selectedValue={selectedPerUnit.value}
                    itemList={perUnits}
                    dataKey={'value'}
                />
            </div>
        </Fragment>
    </DialogBox>
}
