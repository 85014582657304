import React, { Fragment, useState } from 'react';
import DialogBox from "qs-common/DialogBox";
import PersonalizedThemeData from "qs-data/personalizedTheme";
import "./style.scss";
import {trackAnalytics} from "../../../os";

export default function ({ showDialog, onClose, onSuccess, theme }) {

  const [progressBar, setProgressBar] = useState(false);
  const [closeDialog, setCloseDialog] = useState(false);

  const deleteTheme = async () => {
    setProgressBar(true);
    PersonalizedThemeData.deleteTheme(theme.id).then(() => {
      trackAnalytics({
        eventName: 'theme_deleted',
        props: {
          theme_id: theme.id
        }
      });
      setCloseDialog(true);
      onSuccess();
    }).catch((err) => {
      console.log('error while deleting theme', err);
    }).then(() => {
      setProgressBar(false)
    });
  }

  return <DialogBox
    type={"simple"}
    dialogId={'#delete-theme'}
    show={showDialog}
    width={300}
    primaryBtnText={'YES'}
    onSuccess={deleteTheme}
    onClose={onClose}
    showProgressBar={progressBar}
    closeDialog={closeDialog}
    danger
  >
    <Fragment>
      <div className='deleteTheme'>
        <p className='dialogTitle'>{`Are you sure you want to delete ${theme.themeName} theme?`}</p>
      </div>
    </Fragment>
  </DialogBox>
}
