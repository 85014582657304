import React, { useEffect, useState } from 'react';
import {withRouter} from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useQuery from "qs-common/Hooks/useQuery";
import FacebookPixelData from "qs-data/facebookPixel";
import './style.scss';
import FacebookPixelId from "qs-components/FacebookPixelId";

const FacebookPixel = () => {
    const [facebookPixelId, setFacebookPixelId] = useState(null);
    const [progressBar, setProgressBar] = useState(true);
    const [errorComponent, showErrorComponent] = useState(false);

    useEffect(() => {
        fetchFacebookPixelCode();
    }, []);

    const fetchFacebookPixelCode = async () => {
        setProgressBar(true);
        showErrorComponent(false);
        FacebookPixelData.getFacebookPixelId().then((data) => {
            if (data) {
                setFacebookPixelId(data);
            }
        }).catch(() => {
            showErrorComponent(true);
        }).then(() => {
            setProgressBar(false);
        });
    }

    const source = useQuery().get("source");
    const desktop = source === "desktop";
    const [_, dispatch] = useAppContext();

    useEffect(() => {
        dispatch({
            type: 'UPDATE_NAVBAR',
            navBar: {
                background: '#242C36',
                color: '#ffffff',
                title: <b>Facebook pixel</b>,
                boxShadow: 'none',
                borderBottom: '1px solid #242C36',
                hideBack: desktop
            }
        });
        setNavigationBarColor('#242C36');
        setStatusBarColor('#242C36')
    }, [dispatch]);

    return (
        <div className='facebookPixelPage' style={{ alignItems: progressBar || errorComponent ? 'center' : 'flex-start' }}>
            {
                progressBar ? <CircularProgress size={36} color="primary" style={{ marginTop: '8px' }} /> :
                    errorComponent ? <CustomErrorComponent onRetryClick={fetchFacebookPixelCode} /> :
                      <FacebookPixelId
                        pixelId={facebookPixelId}
                      />
            }
        </div>
    );
}

export default withRouter(FacebookPixel);
