import React, { useCallback, useEffect, useReducer, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Loader from 'qs-common/Loader';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import { useAppContext } from 'qs-common/Contexts/AppContext';
import Toast from 'qs-common/Alerts/Toast';
import CheckoutFormFields from './CheckoutFormFields';
import EditCheckoutFormFields from './EditCheckoutFormFields';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import { deleteFormFieldComponent, fetchFormFieldComponents } from './formFieldsData';
import { CHECKOUT_FORM_LABELS } from './constants';
import {
  ADD_NEW_FORM_FIELD,
  checkoutFormFieldsReducer,
  getInitalStateOfCheckoutForm,
  UPDATE_NEW_FIELD
} from './reducer';
import './styles.scss';

const primaryColor = '#00A775';
const secondaryColor = '#EEEEEE';
const backgroundColor = '#303B4B';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: secondaryColor
    },
    secondary: {
      main: primaryColor
    },
    background: {
      paper: backgroundColor,
      default: backgroundColor
    },
    type: 'dark'
  }
});

const CheckoutForm = () => {
  const [formFieldToDelete, setFormFieldToDelete] = useState(null);
  const [errorToast, showErrorToast] = useState(null);
  const [, dispatch] = useAppContext();
  const [checkoutFormsData, dispatchCheckoutFormsData] = useReducer(
    checkoutFormFieldsReducer,
    undefined,
    getInitalStateOfCheckoutForm
  );

  const {
    loading,
    refreshing,
    error,
    enableEdit,
    currentFormState,
    formFieldsData,
    newFormFieldData,
    errorWhileUpdate
  } = checkoutFormsData;
    
  useEffect(() => {
    dispatch({
      type: "UPDATE_NAVBAR",
      navBar: {
        background: primaryColor,
        color: secondaryColor,
        title: CHECKOUT_FORM_LABELS.title,
        hideBack: false
      },
    });
    setNavigationBarColor('#212934');
    setStatusBarColor(primaryColor)
  }, [dispatch]);

  // dependency is not needed as it will only calls api once on component mount
  useEffect(() => fetchFormFieldComponents(dispatchCheckoutFormsData), []);

  useEffect(() => {
    if (errorWhileUpdate) {
      showErrorToast({
        open: true,
        message: `Error: ${typeof errorWhileUpdate === 'string' ? errorWhileUpdate : 'update failed!'}`,
        onClose: () => showErrorToast(null)
      });
    }
    return () => showErrorToast(null);
  }, [errorWhileUpdate]);

  const showSnackbarMessage = useCallback(message => {
    showErrorToast({ open: true, message, onClose: () => showErrorToast(null) });
  }, [showErrorToast]);

  const fetchData = () => fetchFormFieldComponents(dispatchCheckoutFormsData);

  const addNewFormField = () => {
    if (error) {
      return;
    }
    dispatchCheckoutFormsData({ type: ADD_NEW_FORM_FIELD });
  };
  
  const onCloseAddNewFormField = useCallback(() => dispatchCheckoutFormsData({
    type: UPDATE_NEW_FIELD, formField: null
  }), []);

  const onDeleteFormField = formField => setFormFieldToDelete(formField);
  
  const onCloseDeleteFormFieldConfirmation = () => setFormFieldToDelete(null);
  
  const onClickConfirmDelete = () => deleteFormFieldComponent(
    formFieldToDelete,
    dispatchCheckoutFormsData,
    showSnackbarMessage
  ).then(onCloseDeleteFormFieldConfirmation)
    .catch(onCloseDeleteFormFieldConfirmation);

  const renderPageContent = () => {
    if (loading) {
      return <div className="content-container"><Loader /></div>;
    }

    if (error) {
      return <div className="content-container">
        <CustomErrorComponent
          errorMessage={CHECKOUT_FORM_LABELS.COMMON_ERROR_MESSAGE}
          onRetryClick={fetchData}
          style={{ textAlign: 'center' }}
        />
      </div>
    }

    if (!enableEdit) {
      return (
        <CheckoutFormFields
          refreshing={refreshing}
          formFieldsData={formFieldsData}
          onDeleteCallback={onDeleteFormField}
          dispatchCheckoutFormsData={dispatchCheckoutFormsData}
          showSnackbarMessage={showSnackbarMessage}
          useDragHandle
        />
      );
    }

    return (
      <EditCheckoutFormFields
        theme={theme}
        refreshing={refreshing}
        newFormField={newFormFieldData}
        formFieldsData={formFieldsData}
        currentFormState={currentFormState}
        onCloseCallback={onCloseAddNewFormField}
        dispatchCheckoutFormsData={dispatchCheckoutFormsData}
        showSnackbarMessage={showSnackbarMessage}
      />
    );
  };

  const renderActionButton = () => {
    if (enableEdit) {
      return;
    }
    return <div className="form-field-action-btns">
      <Button
        className="add-button"
        aria-label="add"
        variant="contained"
        color="secondary"
        disabled={loading || refreshing || error}
        onClick={addNewFormField}
      >
        <Add /> ADD FIELD
      </Button>
    </div>
  };

  const renderConfirmationDialog = () => {
    if (!formFieldToDelete) {
      return;
    }
    return (
      <Dialog open={!!formFieldToDelete}>
        <DialogContent>
          <Typography>{CHECKOUT_FORM_LABELS.CONFIRMATION_BOX_MESSAGE}</Typography>
        </DialogContent>
        <DialogActions>
          {formFieldToDelete && refreshing ? <Loader small /> : ''}
          <Button variant="text" color="secondary" type="submit" disabled={refreshing} onClick={onClickConfirmDelete}>
            {CHECKOUT_FORM_LABELS.CONFIRM_DELETE_BUTTON_TEXT}
          </Button>
          <Button variant="text" color="secondary" type="button" disabled={refreshing} onClick={onCloseDeleteFormFieldConfirmation}>No</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="checkout-form-container">
        {renderPageContent()}
        {renderActionButton()}
        {renderConfirmationDialog()}
      </Box>
      <Toast {...(errorToast || {})} />
    </ThemeProvider>
  );
};

export default CheckoutForm;