import { CHECKOUT_FORM_STATES, FORM_FIELD_TYPES } from './constants';
import { addNewField, dataExists, moveArray, removeFormField, toggleVisibility } from './helpers';

export const FETCH_FORM_FIELD_DATA = 'FETCH_FORM_FIELD_DATA';
export const FETCH_FORM_FIELD_DATA_SUCCESS = 'FETCH_FORM_FIELD_DATA_SUCCESS';
export const FETCH_FORM_FIELD_DATA_FAILURE = 'FETCH_FORM_FIELD_DATA_FAILURE';
export const UPDATE_FORM_FIELDS_DATA = 'UPDATE_FORM_FIELDS_DATA';
export const UPDATE_FORM_FIELDS_DATA_SUCCESS = 'UPDATE_FORM_FIELDS_DATA_SUCCESS';
export const UPDATE_FORM_FIELDS_DATA_FAILURE = 'UPDATE_FORM_FIELDS_DATA_FAILURE';
export const SET_NEXT_STATE = 'SET_NEXT_STATE';
export const SORT_FORM_FIELDS_DATA = 'SORT_FORM_FIELDS_DATA';
export const ADD_NEW_FORM_FIELD = 'ADD_NEW_FORM_FIELD';
export const ADD_NEW_FORM_FIELDS_TO_LIST = 'ADD_NEW_FORM_FIELDS_TO_LIST';
export const ADD_NEW_FORM_FIELDS_TO_LIST_FAILURE = 'ADD_NEW_FORM_FIELDS_TO_LIST_FAILURE';
export const REMOVE_FORM_FIELD = 'REMOVE_FORM_FIELD';
export const UPDATE_NEW_FIELD = 'UPDATE_NEW_FIELD';
export const TOGGLE_VISIBILITY_FORM_FIELD = 'TOGGLE_VISIBILITY_FORM_FIELD';

// TODO: change this key to .SELECT_FORM_TYPE when allowing to select form type
// now: default fieldType is TEXT
const TO_LANDING_EDIT_MODE = CHECKOUT_FORM_STATES.ENTER_NAME;

export const getInitalStateOfCheckoutForm = () => ({
  // fetching the checkout fields list
  loading: false,
  // updating the checkout fields list
  refreshing: false,
  // received error in get list api 
  error: false,
  // this key controls the layout to render in edit/add new form field form
  currentFormState: null,
  // list of added form fields
  formFieldsData: [],
  // new form field to be added or editable existig form field
  newFormFieldData: null,
  // key to enable edit mode in checkout form page
  enableEdit: false,
  // received error message while updating the fields
  errorWhileUpdate: null
});

export const checkoutFormFieldsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_FORM_FIELD_DATA: {
      const refreshing = dataExists(state.formFieldsData);
      return {
        ...state,
        loading: !refreshing,
        refreshing,
        error: false
      };
    };
    case FETCH_FORM_FIELD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        refreshing: false,
        formFieldsData: action.data,
        error: false
      };
    case FETCH_FORM_FIELD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        refreshing: false,
        error: true
      };
    case UPDATE_FORM_FIELDS_DATA:
      return {
        ...state,
        refreshing: true,
        errorWhileUpdate: null
      };
    case UPDATE_FORM_FIELDS_DATA_SUCCESS:
      return {
        ...state,
        refreshing: false,
        currentFormState: null
      };
    case UPDATE_FORM_FIELDS_DATA_FAILURE: {
      const { keepCurrentFormStateSame, error } = action;
      return {
        ...state,
        refreshing: false,
        currentFormState: keepCurrentFormStateSame ? state.currentFormState : null,
        errorWhileUpdate: error
      };
    };
    case SORT_FORM_FIELDS_DATA: {
      const { error } = action;
      const newSortedFormFields = moveArray(state.formFieldsData, action);
      return {
        ...state,
        refreshing: !error,
        errorWhileUpdate: error, // error recieved while re-ordering
        formFieldsData: newSortedFormFields,
      };
    };
    case SET_NEXT_STATE:
      return {
        ...state,
        currentFormState: action.nextState
      };
    case ADD_NEW_FORM_FIELD: {
      const nextPosition = state.formFieldsData.length + 1;
      return {
        ...state,
        enableEdit: true,
        currentFormState: TO_LANDING_EDIT_MODE,
        newFormFieldData: {
          id: nextPosition.toString(),
          fieldLabel: '',
          fieldType: FORM_FIELD_TYPES.TEXT,
          position: nextPosition,
          canToggleVisibility: false,
          visibility: true,
          removable: true,
          editFieldData: true,
          canReorder: true,
          required: false,
          fieldOptions: null
        }
      };
    };
    case ADD_NEW_FORM_FIELDS_TO_LIST: {
      // if formField exists means trying to update edited field;
      const { insertedId, formField } = action;
      const nextFormFieldsData = addNewField(
        state.formFieldsData,
        formField || state.newFormFieldData,
        insertedId
      );
      return {
        ...state,
        loading: false,
        refreshing: false,
        currentFormState: null,
        newFormFieldData: null,
        enableEdit: false,
        formFieldsData: nextFormFieldsData
      };
    };
    case TOGGLE_VISIBILITY_FORM_FIELD: {
      const { fieldId, error, refreshing } = action;
      const updatedFormData = toggleVisibility(state.formFieldsData, fieldId);
      return {
        ...state,
        refreshing,
        formFieldsData: updatedFormData,
        currentFormState: null,
        errorWhileUpdate: error // error recieved while toggling visibility
      };
    };
    case REMOVE_FORM_FIELD: {
      const { fieldId } = action;
      const nextFormFieldsData = removeFormField(state.formFieldsData, fieldId);
      return  {
        ...state,
        loading: false,
        refreshing: false,
        formFieldsData: nextFormFieldsData,
        currentFormState: null
      };
    };
    case UPDATE_NEW_FIELD: {
      const { formField, editing } = action;
      if (editing) {
        return {
          ...state,
          enableEdit: true,
          newFormFieldData: formField,
          currentFormState: TO_LANDING_EDIT_MODE
        };
      }
      return {
        ...state,
        enableEdit: !!formField,
        newFormFieldData: formField ? { ...(state.newFormFieldData || {}), ...formField } : null
      };
    };
    default:
      return state;
  }
}