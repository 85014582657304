import React, {useState} from 'react';
import * as Sentry from '@sentry/browser';
import { saveToken } from "./data/util";
import { HashRouter, Route, Redirect } from "react-router-dom";
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { AppProvider } from './common/Contexts/AppContext';
import NavBar from './common/navbar';
import Routes from './common/routes';

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({ dsn: "https://d475801c13854a0d89ecea0fb8bcd008@o110511.ingest.sentry.io/5209743" });
}

const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#fff'
        },
        secondary: {
            main: '#fff',
            text: 'rgb(178, 191, 214)'
        },
        background: {
            paper: '#252B36'
        }
    },
});

function App() {

    const initialState = {
        navBar: {
            background: 'transparent',
            color: '#000000',
            title: '',
            hide: true
        }
    };

    const [showNavBar, toggleNavBar] = useState(false); 

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_NAVBAR':
                toggleNavBar(true);
                return {
                    ...state,
                    navBar: { ...action.navBar }
                };
            default:
                return state;
        }
    };

    const listenToNewTokenFromParent = () => {
        const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
        const eventer = window[eventMethod];
        const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
        eventer(messageEvent, function (e) {
            if (e && e.type === 'message' && e.data) {
                let eventData = e.data;
                if (typeof eventData === 'string') {
                    if (eventData.indexOf('token') !== -1) {
                        const token = JSON.parse(e.data).token;
                        if (token) {
                            saveToken(token);
                        }
                    }
                }
            }
        });
    };

    listenToNewTokenFromParent();

    return (
        <AppProvider initialState={initialState} reducer={reducer}>
            <HashRouter>
                <ThemeProvider theme={theme}>
                    <NavBar />
                    <section id="App-main" style={{paddingTop: showNavBar ? '4rem' : 0}}>
                        <Route exact path="/">
                            <Redirect to="/payments" />
                        </Route>
                        {Routes.map(route => <Route key={route.path} {...route} />)}
                    </section>
                </ThemeProvider>
            </HashRouter>
        </AppProvider>
    );
}
export default App;
