import axios from 'axios';
import { getToken } from './util';
import { handleError } from '../common/error';

let host = 'https://api-4.dev.quicksell.co';
// let host = "http://localhost:4000";
// let host = 'https://api-staging-3.quicksell.co';

const instance = axios.create();

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    return {
      ...config,
      headers: {
        ...(config.headers || {}),
        Authorization: getToken(),
        'content-type': 'application/json'
      }
    };
  },
  function (error) {
    // Do something with request error
    console.log('ERROR', error);
    return Promise.reject(error);
  }
);

const network = {};

network.fetchAvailablePaymentGateways = () => {
  return instance
    .get(`${host}/v3/external-account/available-payment-gateways`)
    .then((response) => response.data.gateways)
    .catch(handleError);
};

network.fetchPaymentGatewayData = (gatewayId) => {
  return instance
    .get(`${host}/v1/external-account/payment-gateway`, {
      params: { gatewayId }
    })
    .then((response) => response.data)
    .catch(handleError);
};

network.addOrUpdatePaymentGateway = (gatewayId, data) => {
  return instance
    .post(`${host}/v1/external-account/payment-gateway`, { gatewayId, ...data })
    .then((response) => response.data);
};

network.disablePaymentGateway = (gatewayId) => {
  return instance
    .delete(`${host}/v1/external-account/payment-gateway`, {
      data: { gatewayId }
    })
    .then((response) => response.data)
    .catch(handleError);
};

network.sendVerificationEmail = (email) => {
  return instance
    .post(`${host}/user/change-email-request`, { newEmail: email })
    .then((response) => response.data);
};

network.getEmails = () => {
  return instance.get(`${host}/user/get-emails`).then((response) => response.data);
};

network.getUpcomingWebinars = () => {
  return instance.get(`${host}/v1/webinar/upcoming`).then((response) => response.data);
};

network.getWebinarDetails = (id) => {
  return instance.get(`${host}/v1/webinar?webinarId=${id}`).then((response) => response.data);
};

network.getUserInfo = () => {
  return instance.get(`${host}/v1/user/name-and-email`).then((res) => res.data);
};

network.reserveASeat = ({ webinarId, firstName, lastName, email }) => {
  return instance
    .post(`${host}/v1/webinar/register-for-webinar`, {
      webinarId,
      firstName,
      lastName,
      email
    })
    .then((res) => res.data);
};

network.getWebinarAttendance = ({ webinarId }) => {
  return instance
    .post(`${host}/v1/webinar/webinar-attendance`, { webinarId })
    .then((res) => res.data);
};

network.getSalesReport = ({ startDate, endDate }) => {
  return instance
    .get(
      `${host}/v1/reports/sales-report/?startDate=${startDate}&endDate=${endDate}&timezone=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`
    )
    .then((res) => res.data)
    .catch(handleError);
};

network.getVisitorReport = ({ startDate, endDate }) => {
  return instance
    .get(
      `${host}/v1/reports/visitor-excel/?startDate=${startDate}&endDate=${endDate}&timezone=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`
    )
    .then((res) => res.data)
    .catch(handleError);
};

network.getDailyReportStatus = () => {
  return instance
    .get(`${host}/v1/reports/sales-report/status`)
    .then((res) => res.data)
    .catch(handleError);
};

network.setDailyReport = (dailyReportStatus) => {
  return instance
    .post(`${host}/v1/reports/sales-report/status`, { dailyReportStatus })
    .then((res) => res.data)
    .catch(console.log('error found'));
};

/* Apis wrapper */

network.getData = (url, query) => {
  let updatedUrl = host + url;
  if (query) {
    updatedUrl = updatedUrl + query;
  }
  return instance
    .get(updatedUrl)
    .then((res) => res)
    .catch((e) => e);
};

network.postData = (url, data) => {
  return instance
    .post(`${host}${url}`, data)
    .then((res) => res)
    .catch((e) => e);
};

network.putData = (url, data) => {
  return instance
    .put(`${host}${url}`, data)
    .then((res) => res)
    .catch((e) => e);
};

network.deleteData = (url, data) => {
  return instance
    .delete(`${host}${url}`, { data: data })
    .then((res) => res)
    .catch((e) => e);
};

export default network;
