import React from "react";
import ThemeSectionPreview from "../ThemeSectionPreview";
import './style.scss';

export default function ({ selectedColors, setSelectedColorPicker }) {
	const renderColorSection = (title, key, showBorderBottom) => {
		return <div className={`colorSection ${showBorderBottom ? 'borderBottom' : ''}`}
								onClick={() => setSelectedColorPicker({title, key, value: selectedColors[key]})}>
			<div className='color' style={{
				backgroundColor: selectedColors[key]
			}}/>
			<p className='label'>{title}</p>
		</div>
	}

	const renderColorEditorSection = () => {
		return  <div className='editorSection'>
			{renderColorSection('Primary', 'primaryColor', true)}
			{renderColorSection('Primary Text', 'contrastTextColor', true)}
			{renderColorSection('Background', 'backgroundColor', true)}
			{renderColorSection('Background Text', 'pageTextColor', false)}
		</div>
	}

	const renderPreviewSection = () => {
		return  <ThemeSectionPreview
			primaryColor = {selectedColors.primaryColor}
			pageTextColor = {selectedColors.pageTextColor}
			contrastTextColor = {selectedColors.contrastTextColor}
			backgroundColor = {selectedColors.backgroundColor}
		/>
	}

	return (
		<div className='themesEditContainer'>
			{renderPreviewSection()}
			{renderColorEditorSection()}
		</div>
	);
}