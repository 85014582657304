import React from 'react';
import './style.scss';

export default function ColoredButton(props) {
    const {
        primaryColor = '#ffffff',
        primaryContent = <p></p>,
        secondaryColor = '#ffffff',
        secondaryContent = <p></p>,
        className = '',
        style = {},
        ...remains
    } = props;

    const priColors = {
        background: primaryColor,
    };

    const secColors = {
        background: secondaryColor,
    };

    return (
        <div className={`coloredButton ${className}`} style={{...style, ...priColors}} {...remains}>
            <div className="primary">{primaryContent}</div>
            <div className="secondary" style={secColors}>
                {secondaryContent}
            </div>
        </div>
    );
}