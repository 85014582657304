import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import Switch from 'react-switch';
import ShippingData from "qs-data/shipping";
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import "./style.scss";

function ShippingPricingToggle({ shippingCostCollectionUpdate }) {

  const [shippingCostCollectionStatus, setShippingCostCollectionStatus] = useState(null);
  const [progressBar, setProgressBar] = useState(true);
  const [errorComponent, showErrorComponent] = useState(false);

  useEffect(() => {
    fetchShippingCostCollectionStatus();
  }, []);

  useEffect(() => {
    shippingCostCollectionUpdate(shippingCostCollectionStatus);
  }, [shippingCostCollectionStatus]);

  const fetchShippingCostCollectionStatus = async () => {
    setProgressBar(true);
    showErrorComponent(false);
    ShippingData.getShippingCostCollectionStatus()
      .then((data) => {
        setShippingCostCollectionStatus(data);
      })
      .catch(() => {
        showErrorComponent(true);
      })
      .then(() => {
        setProgressBar(false)
      })
  }

  const toggleShippingCostCollection = async () => {
    const updatedToggle = !shippingCostCollectionStatus;
    setShippingCostCollectionStatus(updatedToggle)
    setProgressBar(true)
    ShippingData.updateShippingCostCollectionStatus({
      'shippingCost': updatedToggle
    }).then(() => {
      setProgressBar(false)
    });
  }

  return shippingCostCollectionStatus !== null && <div className='shippingItemContainer shippingCostCollectionContainer' onClick={errorComponent ? null : toggleShippingCostCollection}>
    {/* TODO check for better way */}
    <div style={{ marginTop: errorComponent || progressBar ? '4px' : 0 }}>
      <div className='shippingCostCollectionSwitch'>
        <p className='shippingCostCollectionHeader'>Collect shipping cost</p>
        {
          progressBar ? <CircularProgress size={22} color="primary" /> :
            errorComponent ? null :
              <Switch
                onChange={toggleShippingCostCollection}
                checked={shippingCostCollectionStatus}
                onHandleColor={"#4DA47A"}
                onColor="#C0DACE"
                height={17}
                width={40}
                handleDiameter={25}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow={
                  shippingCostCollectionStatus ? '0px 1px 5px #4DA47A' : '0px 1px 5px rgba(0, 0, 0, 0.6)'
                }
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                id="shipping-cost-switch"
              />
        }
      </div>
      <div className='shippingCostCollectionSubHeader'>
        {errorComponent ? <CustomErrorComponent style={{paddingTop: '4px'}} onRetryClick={fetchShippingCostCollectionStatus} /> :
          <p >Add shipping cost</p>}
      </div>
    </div>
  </div>
}

export default withRouter(ShippingPricingToggle);