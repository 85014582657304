import React, { useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import './style.css';
import { ReactComponent as CheckmarkIcon } from '../../assets/images/checkmark.svg';
import CountDownTimer from './CountDownTimer';
import LiveButton from './LiveButton';
import network from '../../data/network';
import green from '../../../node_modules/@material-ui/core/colors/green';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../../../node_modules/@material-ui/core/Button/Button';
import MaterialDialog from '@material-ui/core/Dialog';
import CustomTextField from '../../common/CustomTextField';
import Loader from '../../common/Loader';
import dateFormat from 'dateformat';
import { seatReservedNative, setNavigationBarColor, setStatusBarColor } from '../../os';
import { handleError } from '../../common/error';
import { useAppContext } from '../../common/Contexts/AppContext';
import CustomErrorComponent from '../../common/CustomErrorComponent';

// webinarState : scheduled, live, ended
// seatState : seatReserved, seatReserving, seatNotReserved

const WebinarState = {
  DOES_NOT_EXIST: 'does_not_exist',
  SCHEDULED: 'scheduled',
  LIVE: 'live',
  ENDED: 'dead'
};

const SeatState = {
  SEAT_LOADING: 'seatLoading',
  SEAT_RESERVED: 'seatReserved',
  SEAT_NOT_RESERVED: 'seatNotReserved',
  SEAT_RESERVING: 'seatReserving'
};

const ScreenState = {
  WEBINAR_LOADING: 'webinar_loading',
  WEBINAR_LOADED: 'webinar_loaded'
};

function Webinar() {
  const { id } = useParams();
  const [errorComponent, showErrorComponent] = useState(false);
  const [screenState, setScreenState] = useState(ScreenState.WEBINAR_LOADING);
  const [webinarData, setWebinarData] = useState({
    webinarId: '',
    webinarTitle: '',
    webinarDescription: '',
    webinarTime: 0,
    webinarDurationMinutes: 0
  });
  const [webinarHost, setWebinarHost] = useState({
    name: '',
    image_url: '',
    designation: ''
  });

  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });
  const [seatState, setSeatState] = useState(SeatState.SEAT_LOADING);
  const [webinarState, setWebinarState] = useState(WebinarState.DOES_NOT_EXIST);
  const [joinUrl, setJoinUrl] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    loadWebinarData();
  }, []);

  const loadWebinarData = () => {
    Promise.all([network.getWebinarDetails(id), network.getUserInfo()])
      .then((answers) => {
        setScreenState(ScreenState.WEBINAR_LOADED);
        const [data, res, attendance] = answers;
        if (!data.webinarId) {
          setWebinarState(WebinarState.DOES_NOT_EXIST);
          return;
        }
        const { webinarId, webinarTitle, webinarDescription, webinarTime, webinarDurationMinutes } =
          data;

        const { name, image_url, designation } = data.host;
        setWebinarData({
          webinarId,
          webinarTitle,
          webinarDescription,
          webinarTime,
          webinarDurationMinutes
        });

        setWebinarHost({
          name,
          image_url,
          designation
        });

        setUserInfo((userInfo) => {
          return {
            ...userInfo,
            ...res
          };
        });

        const webinarStart = webinarTime;
        const webinarEnd = webinarStart + webinarDurationMinutes * 60 * 1000;
        const now = new Date().getTime();

        if (now > webinarEnd) {
          onWebinarDied();
          showErrorComponent(false);
          return;
        }

        if (now < webinarEnd) {
          network
            .getWebinarAttendance({ webinarId })
            .then((attendance) => {
              const seatReserved = attendance;

              if (seatReserved.join_url) {
                setSeatState(SeatState.SEAT_RESERVED);
                setJoinUrl(seatReserved.join_url);
              } else {
                setSeatState(SeatState.SEAT_NOT_RESERVED);
              }

              showErrorComponent(false);
            })
            .catch(() => showErrorComponent(true));
        }
      })
      .catch(() => {
        showErrorComponent(true);
      });
  };

  useEffect(() => {
    const webinarStart = webinarData.webinarTime;
    const webinarEnd = webinarData.webinarTime + webinarData.webinarDurationMinutes * 60 * 1000;
    const now = new Date().getTime();
    if (now < webinarStart) {
      setWebinarState(WebinarState.SCHEDULED);
    }

    if (now > webinarStart && now < webinarEnd) {
      setWebinarState(WebinarState.LIVE);
    }

    if (now > webinarEnd) {
      setWebinarState(WebinarState.ENDED);
    }
  }, [webinarData.webinarTime, webinarData.webinarDurationMinutes]);

  const onReserveSeat = () => {
    // if name || email is not present then ask for it.
    if (!userInfo.firstName || !userInfo.lastName || !userInfo.email) {
      setOpen(true);
      return;
    }

    setOpen(false);

    setSeatState(SeatState.SEAT_RESERVING);

    network
      .reserveASeat({
        webinarId: webinarData.webinarId,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email
      })
      .then((data) => {
        const seatReserved = data;

        if (seatReserved.join_url) {
          setSeatState(SeatState.SEAT_RESERVED);
          setJoinUrl(seatReserved.join_url);
          try {
            seatReservedNative(webinarData.webinarTitle, webinarData.webinarTime);
          } catch (e) {
            handleError(e);
          }
        } else {
          setSeatState(SeatState.SEAT_NOT_RESERVED);
        }
      })
      .catch((err) => {
        console.log(err);
        setSeatState(SeatState.SEAT_NOT_RESERVED);
      });
  };

  const onInputChanged = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserInfo((userInfo) => {
      return {
        ...userInfo,
        [name]: value
      };
    });
  };

  const onWebinarDied = () => {
    setWebinarState(WebinarState.ENDED);
  };

  const [_, dispatch] = useAppContext();
  useEffect(() => {
    dispatch({
      type: 'UPDATE_NAVBAR',
      navBar: {
        background: 'linear-gradient(360deg, #0f101a 0%, #0F101A 100%)',
        color: '#FFFFFF',
        title: '',
        boxShadow: 'none'
      }
    });
    setNavigationBarColor('#2C3152');
    setStatusBarColor('#10101A');
  }, [dispatch]);

  const retryLoadingWebinar = () => {
    setScreenState(ScreenState.WEBINAR_LOADING);
    setSeatState(SeatState.SEAT_LOADING);
    setWebinarState(WebinarState.DOES_NOT_EXIST);
    loadWebinarData();
  };

  if (errorComponent) {
    return (
      <div className='WebinarContainer'>
        <div className='WebinarDoesNotExist'>
          <CustomErrorComponent style={{ margin: 'auto' }} onRetryClick={retryLoadingWebinar} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='WebinarContainer'>
        {screenState === ScreenState.WEBINAR_LOADING && (
          <div className='LoaderContainer'>
            {' '}
            <Loader />
          </div>
        )}

        {screenState === ScreenState.WEBINAR_LOADED &&
          webinarState === WebinarState.DOES_NOT_EXIST && (
            <div className='WebinarDoesNotExist'>We will schedule a webinar soon</div>
          )}

        {screenState === ScreenState.WEBINAR_LOADED &&
          webinarState !== WebinarState.DOES_NOT_EXIST && (
            <div className='WebinarContent'>
              <div className='HeroTitle'>{webinarData.webinarTitle}</div>
              <div className='HeroSubtitle'>{webinarData.webinarDescription}</div>

              {webinarState === WebinarState.LIVE ? <LiveButton /> : null}

              {webinarData.webinarTime > 0 && (
                <div className='YourTime'>
                  {dateFormat(new Date(webinarData.webinarTime), 'dddd, dS mmmm, HH:MMtt')}
                </div>
              )}
              {webinarState === WebinarState.LIVE && seatState === SeatState.SEAT_RESERVED ? (
                <button
                  className='JoinNowButton'
                  onClick={() => {
                    window.open(joinUrl);
                  }}
                >
                  Join now
                </button>
              ) : null}

              {webinarState === WebinarState.SCHEDULED && seatState === SeatState.SEAT_RESERVED && (
                <div className='SeatReservedContainer'>
                  <CheckmarkIcon className='SeatReservedIcon' />
                  <span className='SeatReservedText'>Your seat is reserved</span>
                </div>
              )}

              {seatState === SeatState.SEAT_LOADING ? (
                <div className='SeatLoading'>
                  {' '}
                  <Loader small />{' '}
                </div>
              ) : null}

              {seatState === SeatState.SEAT_NOT_RESERVED && webinarData.webinarId && (
                <button className='ReserveASeatButton' onClick={onReserveSeat}>
                  Reserve a seat
                </button>
              )}

              {seatState === SeatState.SEAT_RESERVING && (
                <Loader small style={{ marginTop: 10, marginBottom: 10 }} />
              )}

              {webinarState === WebinarState.SCHEDULED && (
                <div className='TimeRemainingContainer'>
                  <div className='TimeRemainingLabel'>Time Remaining</div>
                  <CountDownTimer
                    goLiveTime={webinarData.webinarTime}
                    endTime={
                      webinarData.webinarTime + webinarData.webinarDurationMinutes * 10 * 1000
                    }
                    isDead={onWebinarDied}
                    isLiveCallback={() => {
                      setWebinarState(WebinarState.LIVE);
                    }}
                  />
                </div>
              )}

              {webinarState === WebinarState.ENDED && <div> This webinar has ended</div>}

              <div className='HostContainer'>
                <img className='HostImage' src={webinarHost.image_url} />
                <span className='HostName'>{webinarHost.name}</span>
                <span className='HostTitle'>{webinarHost.designation}</span>
              </div>
            </div>
          )}
      </div>

      <MaterialDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Please enter your details'}</DialogTitle>
        <DialogContent>
          <CustomTextField
            fullWidth
            variant='filled'
            label='First name'
            margin='normal'
            name='firstName'
            value={userInfo.firstName}
            onChange={onInputChanged}
          />
          <CustomTextField
            fullWidth
            variant='filled'
            label='Last name'
            margin='normal'
            name='lastName'
            value={userInfo.lastName}
            onChange={onInputChanged}
          />
          <CustomTextField
            fullWidth
            variant='filled'
            label='Email'
            margin='normal'
            name='email'
            value={userInfo.email}
            onChange={onInputChanged}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onReserveSeat} style={{ color: green.A100 }} autoFocus>
            {'Register'}
          </Button>
        </DialogActions>
      </MaterialDialog>
    </>
  );
}

export default Webinar;
