import React, { useState, useReducer, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import payments from '../../../data/payments';
import Loader from '../../../common/Loader/index';
import useStyles from '../../../common/CustomStyles';
import CustomTextField from '../../../common/CustomTextField';
import Toast from '../../../common/Alerts/Toast';
import './style.css';

const paymentChargesData = [
  {
    info: 'Debit Cards more than Rs. 2000',
    charges: '0.90%'
  },
  {
    info: 'Debit Cards less than Rs. 2000',
    charges: '0.40%'
  },
  {
    info: 'Rupay',
    charges: '0.10%'
  },
  {
    info: 'UPI more than Rs. 2000',
    charges: '0.10%'
  },
  {
    info: 'UPI less than Rs. 2000',
    charges: '0.10%'
  },
  {
    info: 'Credit Cards',
    charges: '1.75%'
  },
  {
    info: 'Net-banking (HDFC ICICI AXIS SBI KOTAK)',
    charges: '1.65%'
  },
  {
    info: 'Net-banking (All Others)',
    charges: '1.50%'
  },
  {
    info: 'Mobile Wallets',
    charges: '1.80%'
  },
  {
    info: 'Corporate Cards',
    charges: '2.70%'
  },
  {
    info: 'International Cards',
    charges: '2.85%'
  },
  {
    info: 'Diners / AMEX',
    charges: '2.70%'
  },
  {
    info: 'EMI & Cardless EMI (Zest Money, Early Salary, etc)',
    charges: '2.70%'
  }
];

function formReducer(state, { type, ...data }) {
  if (type === 'input-changed') {
    return {
      ...state,
      [data.name]: data.value
    };
  } else if (type === 'initialize') {
    const credentials = data;
    return {
      ...state,
      ...credentials
    };
  }
}

export default function Rzp({ gatewayData }) {
  const [isActive, setIsActive] = useState(gatewayData['isActive'] || false);
  const [credentialsExist, setCredentialsExist] = useState(
    !!(gatewayData.credentials && gatewayData.credentials.ifscCode)
  );

  const credentials = gatewayData.credentials || {
    name: '',
    email: '',
    businessName: '',
    businessType: '',
    ifscCode: '',
    beneficiaryName: '',
    accountNumber: '',
    accountType: 'current'
  };

  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [toastState, setToastState] = useState({
    message: '',
    open: false
  });
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useReducer(formReducer, credentials);

  useEffect(() => {
    const companyMeta = gatewayData.companyMeta || {};
    dispatch({
      type: 'initialize',
      name: companyMeta.name,
      businessName: companyMeta.companyName,
      email: companyMeta.email,
      ...gatewayData.credentials
    });
  }, [gatewayData]);

  const validateForm = () => {
    const _errors = {};

    Object.keys(state).forEach((element) => {
      if (state[element] === '') {
        _errors[element] = 'This field cannot be left blank';
      }
    });
    setErrors(_errors);
    console.log('ERRORS', _errors);
    return Object.keys(_errors).length;
  };

  const onSubmit = () => {
    let numberErrors = validateForm();
    if (numberErrors) {
      return;
    }
    setLoading(true);
    payments
      .enableRZP(state)
      .then((res) => {
        if (!res) {
          setErrors({});
          setLoading(false);
          setToastState({
            message: 'An error occured',
            open: true
          });
          return;
        }

        setErrors({});
        setLoading(false);
        setToastState({
          message: 'Payment gateway enabled',
          open: true
        });
        setCredentialsExist(true);
        setIsActive(true);
      })
      .catch((err) => {
        let message = 'An error occured';
        if (err.response.data.reason === 'INTERNAL_SERVER_ERROR') {
          message = err.response.data.extraData.description;
        }

        setErrors({});
        setLoading(false);
        setToastState({
          message: message,
          open: true
        });
      });
  };

  const onChange = (e) => {
    dispatch({
      type: 'input-changed',
      name: e.target.name,
      value: e.target.value || ''
    });
    setErrors({});
    setLoading(false);
  };

  const razorpayCompanyTypes = payments.getRazorpayCompanyTypes();

  return (
    <div className='RzpContainer'>
      <div className='RzpPaymentDetails'>
        <div className='RzpTitle'>Settlement schedule</div>
        <div className='RzpSubtitle'>2 working days (excluding Saturday and Sunday)</div>
        <div className='RzpTitle'>Payment gateway charges</div>
        <div className='RzpSubtitleGreen'>Best in industry</div>
        <table className='RzpChargesTabel'>
          <tbody>
            {paymentChargesData.map((tableElement, index) => (
              <tr className='RzpChargesTabelRow' key={index}>
                <td className='RzpChargesTabelElement'>{tableElement.info}</td>
                <td className='RzpChargesTabelElement RzpChargesTabelChargesElement'>
                  {tableElement.charges}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='RzpAdditionalInfo'>
          Additional 18% GST applicable on payment gateway charges
        </div>
      </div>
      <form className={classes.root} noValidate autoComplete='off' disabled>
        <CustomTextField
          fullWidth
          label='Name'
          margin='normal'
          value={state.name}
          name='name'
          onChange={onChange}
          error={!!errors['name']}
          helperText={errors['name']}
          disabled={isActive || credentialsExist}
        />
        <CustomTextField
          fullWidth
          label='Email'
          value={state.email}
          name='email'
          onChange={onChange}
          error={!!errors['email']}
          helperText={errors['email']}
          disabled={isActive || credentialsExist}
        />
        <CustomTextField
          fullWidth
          label='Company name'
          value={state.businessName}
          name='businessName'
          onChange={onChange}
          error={!!errors['businessName']}
          helperText={errors['businessName']}
          disabled={isActive || credentialsExist}
        />
        <FormControl
          fullWidth
          className={classes.formControl}
          error={!!errors['businessType']}
          helperText={errors['businessType']}
          disabled={isActive || credentialsExist}
        >
          <InputLabel id='demo-simple-select-label' className={classes.inputLabel}>
            Business Type
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            onChange={onChange}
            name='businessType'
            value={state.businessType}
          >
            {Object.keys(razorpayCompanyTypes).map((ct, i) => {
              return (
                <MenuItem key={i} value={ct}>
                  {razorpayCompanyTypes[ct]}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <CustomTextField
          fullWidth
          label='IFSC Code'
          name='ifscCode'
          value={state.ifscCode}
          onChange={onChange}
          error={!!errors['ifscCode']}
          helperText={errors['ifscCode']}
          disabled={isActive || credentialsExist}
        />
        <CustomTextField
          fullWidth
          label='Beneficiary name'
          name='beneficiaryName'
          value={state.beneficiaryName}
          onChange={onChange}
          error={!!errors['beneficiaryName']}
          helperText={errors['beneficiaryName']}
          disabled={isActive || credentialsExist}
        />
        <CustomTextField
          fullWidth
          label='Account number'
          name='accountNumber'
          value={state.accountNumber}
          onChange={onChange}
          error={!!errors['accountNumber']}
          helperText={errors['accountNumber']}
          disabled={isActive || credentialsExist}
        />

        {loading ? (
          <div
            style={{
              marginLeft: 24,
              marginTop: 24
            }}
          >
            <Loader small />
          </div>
        ) : isActive ? (
          <Button
            variant='contained'
            size='large'
            className={classes.buttonDisable}
            onClick={() => {
              setLoading(true);
              payments.disableRZP().then(() => {
                setIsActive(false);
                setLoading(false);
                setToastState({
                  message: 'Payment gateway disabled',
                  open: true
                });
              });
            }}
          >
            Disable
          </Button>
        ) : (
          <Button variant='contained' size='large' className={classes.button} onClick={onSubmit}>
            Enable{' '}
          </Button>
        )}
      </form>
      <Toast
        message={toastState.message}
        open={toastState.open}
        onClose={() => {
          setToastState({
            open: false,
            message: ''
          });
        }}
      />
    </div>
  );
}
