import React from 'react';
import TextIcon from 'qs-assets/icons/TextIcon';
import CalenderIcon from 'qs-assets/icons/CalenderIcon';
import HashtagIcon from 'qs-assets/icons/HashtagIcon';
import DropdownIcon from 'qs-assets/icons/DropdownIcon';
import SelectIcon from 'qs-assets/icons/SelectIcon';
import StarIcon from 'qs-assets/icons/StarIcon';
import YesNoIcon from 'qs-assets/icons/YesNoIcon';
import TimeIcon from '../../../assets/icons/TimeIcon';
import { FORM_FIELD_TYPES } from '../constants';


export default ({ iconType, ...restProps }) => {
  switch (iconType) {
    case FORM_FIELD_TYPES.TEXT:
      return <TextIcon {...restProps} />;
    case FORM_FIELD_TYPES.DATE:
      return <CalenderIcon {...restProps} />;
    case FORM_FIELD_TYPES.DROPDOWN:
      return <DropdownIcon {...restProps} />
    case FORM_FIELD_TYPES.MULTI_SELECT:
      return <SelectIcon {...restProps} />;
    case FORM_FIELD_TYPES.YES_NO:
      return <YesNoIcon {...restProps} />
    case FORM_FIELD_TYPES.NUMBER:
      return <HashtagIcon {...restProps} />
    case FORM_FIELD_TYPES.RATING:
      return <StarIcon {...restProps} />
    case FORM_FIELD_TYPES.TIME:
      return <TimeIcon {...restProps} />
    default:
      break;
  }
};