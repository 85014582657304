import React, { useState, useEffect, useCallback, Fragment } from "react";
import Loader from "../../common/Loader";
import { Button } from "@material-ui/core";
import { setNavigationBarColor } from "../../os";
import { makeStyles } from "@material-ui/core/styles";
import Network from "../../data/network";
import { useAppContext } from "qs-common/Contexts/AppContext";
import useQuery from "../../common/Hooks/useQuery";
import Toast from "../../common/Alerts/Toast";
import GenerateReports from "../../components/Report/GenerateReports";
import "./style.css";
import network from "../../data/network";
import { handleError } from "qs-common/error";
import AddUserEmail from "../../components/Report/AddUserEmail";

const useStyles = makeStyles({
  statusBtn: {
    fontSize: 16,
    fontWeight: 500,
    padding: 2,
    maxWidth: 76,
    marginBottom: "1rem",
  },
  dateField: {
    border: "2px solid #515c6d",
    borderRadius: 4,
    paddingLeft: 6,
  },
  recieveBtn: {
    background: "#31BD84",
    color: "#FFFFFF",
    padding: "0.75rem",
    marginBottom: "2rem",
    marginTop: "1rem",
    width: 154,
    "&:hover": {
      background: "#57dd9c",
    },
  },
  disabled: {
    opacity: "0.5",
    cursor: "not-allowed",
    "&:hover": {
      background: "#57dd9c",
    },
  },
});

const TOAST_DURATION = 3000;

export default function Reports() {
  const classes = useStyles();
  const source = useQuery().get("source");
  const desktop = source === "desktop";
  const [_, dispatch] = useAppContext();
  const [updatingStatus, setUpdating] = useState(false);
  const [reportStatus, setReportStatus] = useState(true);
  const [toastState, setToastState] = useState({
    open: false,
    message: "",
  });
  const [email, setEmail] = useState("");
  const [showDialogue, setShowDialogue] = useState(false);

  useEffect(() => {
    dispatch({
      type: "UPDATE_NAVBAR",
      navBar: {
        background: desktop ? "#0f141a" : "#2A303A",
        color: "#FFFFFF",
        title: "Reports",
        hideBack: desktop,
      },
    });
    setNavigationBarColor("#2a303a");
  }, [dispatch, desktop]);

  useEffect(() => {
    const callApi = async () => {
      setUpdating(true);
      try {
        const result = await Network.getDailyReportStatus();
        setReportStatus(result && result.dailyReportStatus);
        setEmail(result && result.email ? result.email : "");
        setUpdating(false);
      } catch (err) {
        setUpdating(false);
        console.error(err);
        setToastState({
          open: true,
          message: "Failed to get current status",
        });
      }
    };
    callApi();
  }, [setUpdating]);

  const setDailyReport = useCallback(
    (data) => {
      const callApi = async () => {
        setUpdating(true);
        try {
          await Network.setDailyReport(data);
          setReportStatus(data);
          setUpdating(false);
        } catch (err) {
          setUpdating(false);
          console.error(err);
          setToastState({
            open: true,
            message: "Failed to set daily report status",
          });
        }
      };
      callApi();
    },
    [setUpdating]
  );

  const onSendVerificationEmail = (value) => {
    network
      .sendVerificationEmail(value)
      .then(() => {
        setToastState({
          open: true,
          message: "Verification email sent successfully",
        });
      })
      .catch((err) => {
        setToastState({
          open: true,
          message: "An error occured : " + err.toString(),
        });
        handleError(err);
      })
      .finally(() => {
        setShowDialogue(false);
      });
  };

  const toggleDailySalesReport = () => {
    return (
      <div>
        <p className='report-heading'>Daily sales report</p>
        <p className='report-info'>
          Receive daily sales reports on your specified emails
        </p>
        {updatingStatus ? (
          <div className='report-loader'>
            <Loader small style={{ margin: 10 }} />
          </div>
        ) : (
          <Fragment>
            {reportStatus ? (
              <p className='report-status active'>Currently active</p>
            ) : (
              <p className='report-status inactive'>Current not active</p>
            )}
            {reportStatus ? (
              <Button
                onClick={() => setDailyReport(false)}
                className={`${classes.statusBtn} disable`}
              >
                DISABLE
              </Button>
            ) : (
              <Button
                onClick={() => setDailyReport(true)}
                className={`${classes.statusBtn} enable`}
              >
                ENABLE
              </Button>
            )}
          </Fragment>
        )}
      </div>
    );
  };

  const renderFooter = () => {
    if (updatingStatus && email.length === 0) {
      return <Loader small style={{ margin: 10 }} />;
    } else {
      return (
        <p className='report-note'>You will receive the reports on {email}</p>
      );
    }
  };

  return (
    <div className='report-container'>
      {/*{toggleDailySalesReport()}*/}
      <GenerateReports
        source={source}
        reportType={"sales-report"}
        trackEvent={"sales_report_downloaded"}
        email={email}
        showDialogue={() => setShowDialogue(true)}
      />
      <GenerateReports
        source={source}
        reportType={"visitor-report"}
        trackEvent={"visitor_report_downloaded"}
        email={email}
        showDialogue={() => setShowDialogue(true)}
      />
      {renderFooter()}
      {showDialogue && (
        <AddUserEmail
          setToastState={setToastState}
          onSendVerificationEmail={onSendVerificationEmail}
          setShowDialogue={setShowDialogue}
        />
      )}
      <Toast
        message={toastState.message}
        open={toastState.open}
        duration={TOAST_DURATION}
        onClose={() => {
          setToastState({
            open: false,
            message: "",
          });
        }}
      />
    </div>
  );
}
