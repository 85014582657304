import React, {useEffect, useState} from 'react';
import Ripples from "react-ripples";
import {useHistory, withRouter} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
import './style.scss';

import { ReactComponent as NextIcon } from 'qs-assets/images/right_arrow.svg';
import {setNavigationBarColor, setStatusBarColor} from '../../os';
import CustomErrorComponent from "qs-common/CustomErrorComponent";
import { useAppContext } from 'qs-common/Contexts/AppContext';
import useQuery from "qs-common/Hooks/useQuery";
import SingleThemeContainer from "qs-components/Theme/SingleThemeContainer";
import PersonalizedThemeData from "qs-data/personalizedTheme";
import CacheRequest from "qs-data/cacheRequest";
import cacheKeys from "qs-data/cacheKeys";
import Toast from "qs-common/Alerts/Toast";

const ThemesManager = () => {
  const history = useHistory();

  const source = useQuery().get("source");
  const desktop = source === "desktop";
  const [_, dispatch] = useAppContext();

  const [themes, setThemes] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [progressBar, setProgressBar] = useState(true);
  const [errorComponent, showErrorComponent] = useState(false);

  const [toastState, setToastState] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    dispatch({
      type: "UPDATE_NAVBAR",
      navBar: {
        background: "#00A775",
        color: "#FFFFFF",
        title: "Themes Manager",
        hideBack: desktop
      },
    });
    setNavigationBarColor('#212934');
    setStatusBarColor('#00A775')
  }, [dispatch]);

  useEffect(() => {
    deletePreviousThemeCache();
    fetchThemeList();
  }, []);

  const deletePreviousThemeCache = () => {
    CacheRequest.deleteCacheForKeys([
      cacheKeys.selectedThemeId,
      cacheKeys.selectedTheme,
      cacheKeys.name,
      cacheKeys.primaryColor,
      cacheKeys.contrastTextColor,
      cacheKeys.pageTextColor,
      cacheKeys.backgroundColor,
      cacheKeys.colorUpdatedToggle,
      cacheKeys.nullEntryAddedToHistory
    ]);
  }

  const fetchThemeList = () => {
    if (themes.length === 0) {
      setProgressBar(true);
      showErrorComponent(false);
    }
    PersonalizedThemeData.getAllThemesForCompany().then((themes) => {
      if (themes) {
        setThemes(themes)
        themes.forEach((theme) => {
          if (theme.isSelected) {
            setSelectedTheme(theme);
          }
        })
      }
    }).catch((ex) => {
      console.log('exception while fetching theme', ex);
      showErrorComponent(true);
    }).then(() => {
      setProgressBar(false);
    });
  }

  const navigateToAddNewTheme = () => {
    setTimeout(() => {
      history.push({
        pathname: '/theme-update'
      });
    }, 100)
  }

  const renderSelectedThemeContainer = () => {
    return (
      <div className='setThemeContainer'>
        <p className={'heading'}>Site theme</p>
        <p className={'subHeading'}>Applied across all catalogues</p>
        <SingleThemeContainer
          theme={selectedTheme}
          hideDivider
          hideSetThemeIcon
        />
      </div>
    );
  }

  const onSelectedThemeChanged = () => {
    setToastState({
      open: true,
      message: "Theme changed successfully.",
    });
    fetchThemeList();
  }

  const renderAllThemes = () => {
    const customStyle = {
      backgroundColor: '#191f27',
      borderRadius: '5px',
      margin: '15px 16px 0',
      boxShadow: '0px 0px 5px 1px rgb(0 0 0 / 22%)'
    }

    return <div className='allThemes'>
      <p className={'heading'}>All themes</p>
      {
        themes.map(function(theme){
          return <SingleThemeContainer
            key={theme.id}
            theme={theme}
            customStyle={customStyle}
            onSelectedThemeChanged={onSelectedThemeChanged}/>
        })
      }
    </div>
  }

  const renderAddNewThemeButton = () => {
    return <Ripples className={'addNewThemeButtonsContainer'} onClick={navigateToAddNewTheme}>
      <span className={'text'}>Create new theme</span>
      <NextIcon fill="#FFF" width="20" height="20" />
    </Ripples>
  }

  if (progressBar) {
    return <div className='themesManagerContainer' style={{justifyContent : 'center'}}>
      <CircularProgress size={24} color="primary" />
    </div>
  }

  if (errorComponent || themes.length === 0 ) {
    return <div className='themesManagerContainer' style={{justifyContent : 'center'}}>
      <CustomErrorComponent onRetryClick={fetchThemeList} />
    </div>
  }

  return (
    <div className='themesManagerContainer'>
      <div className='themesContainer'>
        {selectedTheme && renderSelectedThemeContainer()}
        {renderAllThemes()}
      </div>
      {renderAddNewThemeButton()}
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: "",
          });
        }}
      />
    </div>
  );
}

export default withRouter(ThemesManager);
