import React, { useState, useEffect } from "react";
import payments from "../../data/payments";
import Loader from "../../common/Loader";
import {setNavigationBarColor} from '../../os';
import { useAppContext } from '../../common/Contexts/AppContext';
import './style.css';
import PaymentRow from "./paymentRow";
import useQuery from "../../common/Hooks/useQuery";

function Payments() {
  const source = useQuery().get("source");
  const desktop = source === "desktop";

  const [paymentModes, setPaymentModes] = useState([]);

  useEffect(() => {
    payments.getPaymentMethods(true).then((response) => {
      setPaymentModes((paymentModes) => {
        return paymentModes.concat(response);
      });
    });
  }, []);

    const [_, dispatch] = useAppContext();
    useEffect(() => {
        dispatch({
            type: 'UPDATE_NAVBAR',
            navBar: {
                background: desktop ? '#0f141a' : '#2b313c',
                color: '#FFFFFF',
                title: 'Payment modes',
                hideBack: desktop
            }
        });
        setNavigationBarColor('#3f4653');
    }, [dispatch, desktop]);

  return (
    <div className="pageBackground">
      {paymentModes.length === 0 ? (
        <div className="loaderContainer">
          <Loader />
        </div>
      ) : null}
      {paymentModes.map((paymentMode, i) => {
        return <PaymentRow key={i} paymentMode={paymentMode} source={source} />;
      })}
    </div>
  );
}

export default Payments;
