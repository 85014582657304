import React from 'react';
import { Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import "./style.scss";

export default function ({ handleChange, disabled, checked, label }) {
    return <FormGroup row>
        <FormControlLabel
            control={<Checkbox
                checked={checked}
                disabled={disabled}
                onChange={handleChange}
                name={label} />}
                label={<div className={'customCheckboxLabel'}> {label} </div>}
        />
    </FormGroup>
} 