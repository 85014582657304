import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import DehazeIcon from '@material-ui/icons/Dehaze';
import Loader from 'qs-common/Loader';
import { CHECKOUT_FORM_LABELS } from '../constants';
import { changeOrderCheckoutFields, updateFormFieldComponents } from '../formFieldsData';
import { UPDATE_NEW_FIELD } from '../reducer';
import './styles.scss';

const CheckoutFormFields = ({
  refreshing,
  formFieldsData: formFields,
  dispatchCheckoutFormsData,
  showSnackbarMessage,
  useDragHandle,
  onDeleteCallback = () => {}
}) => {
  const [updatingField, setUpdatingField] = useState(null);
  useEffect(() => {
    if (!refreshing && updatingField) {
      setUpdatingField(null);
    }
  }, [refreshing, updatingField])

  const toggleVisibility = formField => {
    setUpdatingField(formField.id);
    updateFormFieldComponents(
      formField, dispatchCheckoutFormsData, showSnackbarMessage
    );
  };
  const onSortEndHandler = ({ oldIndex, newIndex }) =>  changeOrderCheckoutFields(
    { oldIndex, newIndex, fieldId: formFields[oldIndex].id },
    dispatchCheckoutFormsData,
    showSnackbarMessage
  );
  const onClickEditHandler = formField => dispatchCheckoutFormsData({
    type: UPDATE_NEW_FIELD,
    formField,
    editing: true
  });

  const SortableDragHandle = SortableHandle(({ disabled }) => 
    <DehazeIcon className={`menu-icon${disabled ? ' disabled' : ''}`} />
  );

  const ListItem = ({ formField }) => {
    const {
      id,
      fieldName: fieldLabel,
      canToggleVisibility,
      visibility,
      removable,
      required,
      canReorder,
      editFieldData
    } = formField;

    return (
      <Paper key={id} className="form-field">
        <Box className="form-field-content">
          <Box className={`information${canReorder && !useDragHandle ? ' draggable' : ''}`}>
            <SortableDragHandle id={id} disabled={!canReorder} />
            <div className="form-field-list-details">
              <h5 className="name">{fieldLabel}</h5>
              <h6 className="tagline">{required ? 'Mandatory' : 'Not Mandatory'}</h6>
            </div>
          </Box>
          <Box className="actions-buttons">
            {refreshing && updatingField === id ? <Loader small /> : ''}
            {editFieldData && 
              <Box className="edit-btn" onClick={() => onClickEditHandler(formField)}><Edit /></Box>
            }
            {removable &&
              <Box className="remove-btn" onClick={() => onDeleteCallback(formField)}><Delete /></Box>
            }
            {canToggleVisibility &&
              <Switch id={id} checked={visibility || false} className="toggle-btn" onChange={() => toggleVisibility(formField)}/>
            }
          </Box>
        </Box>
      </Paper>
    );
  }

  const SortableItem = SortableElement(({ formField }) => (<ListItem formField={formField} />));

  const SortableList = SortableContainer(({ formFieldItems }) => {
    return <List className="form-fields">
      {formFieldItems.map((formField, fieldIndex) => (formField.canReorder
        ? <SortableItem key={formField.id} index={fieldIndex} formField={formField} />
        : <ListItem key={formField.id} index={fieldIndex} formField={formField} />
      ))}
    </List>
  });

  const renderFormFields = () => {
    if (Array.isArray(formFields) && formFields.length === 0) {
      return <Typography>{CHECKOUT_FORM_LABELS.noDataMessage}</Typography>
    }

    return <SortableList
      lockAxis="y"
      formFieldItems={formFields}
      onSortEnd={onSortEndHandler}
      useDragHandle={useDragHandle}
      distance={1}
      helperClass="dragging-item"
    />
  };

  return (
    <Box className="form-fields-container">
      <Typography className="tagline">{CHECKOUT_FORM_LABELS.tagline}</Typography>
      {renderFormFields()}
    </Box>
  );
};

export default CheckoutFormFields;