import React, { Fragment, useState } from 'react';
import { isNil } from 'lodash';
import ShippingData from "qs-data/shipping";
import CustomSelect from 'qs-common/CustomSelect';
import DialogBox from "qs-common/DialogBox";
import Utility from 'qs-helpers/utility';
import "./style.scss";

export default function ({ showDialog, onClose, onSuccess, weightUnit }) {

    const weights = Utility.getWeightUnits();
    const [selectedWeight, setSelectedWeight] = useState(weightUnit);

    const [progressBar, setProgressBar] = useState(false);
    const [closeDialog, setCloseDialog] = useState(false);

    const unitChange = (e) => {
        setSelectedWeight(e.target.value)
    }

    const updateWeigthUnit = async () => {
        setProgressBar(true);
        // showErrorComponent(false);
        ShippingData.updateShippingWeightUnit({ unit: selectedWeight }).then(() => {
            onApiSuccess();
        }).catch((err) => {
            onApiFailure(err);
            // showErrorComponent(true);
        }).then(() => {
            setProgressBar(false)
        });
    }

    const onApiSuccess = () => {
        setProgressBar(false);
        setCloseDialog(true);
        onSuccess(selectedWeight);
    }

    const onApiFailure = (error) => {
        console.error('Exception', error);
        setProgressBar(false);
    }

    return showDialog && <DialogBox
        type={"simple"}
        dialogId={'#select-weight'}
        show={showDialog}
        width={300}
        primaryBtnText={'SAVE'}
        onSuccess={updateWeigthUnit}
        onClose={onClose}
        disabledPrimaryButton={isNil(selectedWeight)}
        showProgressBar={progressBar}
        closeDialog={closeDialog}
    >
        <Fragment>
            <div className='weigthDialogContent'>
                <p className='dialogTitle'>Select Weight unit</p>
                <CustomSelect
                    handleChange={unitChange}
                    selectedValue={selectedWeight}
                    itemList={weights}
                />
            </div>
        </Fragment>
    </DialogBox>
}
