import React, { Fragment, useState, useEffect } from "react";
import DialogBox from "qs-common/DialogBox";
import { CircularProgress } from "@material-ui/core";
import "./style.scss";

export default function ({ showDialog, onClose }) {
  const [progressBar, setProgressBar] = useState(true);

  return (
    showDialog && (
      <DialogBox
        type={"simple"}
        dialogId={"#referal-reward-scheme"}
        show={showDialog}
        onSuccess={onClose}
        onClose={onClose}
        secondaryBtnText={"Close"}
        hidePrimaryButton={true}
      >
        <Fragment>
          <div className="referralRewardSchemeContent">
            <p className="dialogTitle">Reward Scheme</p>
            <div className="rewardSchemeImage">
              {progressBar && <CircularProgress size={36} color="primary" />}
              <img
                style={{ visibility: progressBar ? "hidden" : "visible" }}
                src={
                  "https://s3.amazonaws.com/appext.quicksell.co/referral_reward_scheme.png"
                }
                width="100%"
                onLoad={() => setProgressBar(false)}
              />
            </div>
          </div>
        </Fragment>
      </DialogBox>
    )
  );
}
