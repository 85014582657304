import React, { Fragment, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import { isNil } from 'lodash';
import ShippingData from "qs-data/shipping";
import DialogBox from "qs-common/DialogBox";
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import CustomCheckbox from 'qs-common/CustomCheckbox';
import Utility from 'qs-helpers/utility';
import "./style.scss";

export default function ({ selectedWeightProfile, showDialog, onClose, onSuccess, weightUnit, deleteMode }) {
    const minimumWeightRef = useRef();
    const maximumWeightRef = useRef();

    const [noMaxLimit, setNoMaxLimit] = useState(isNil(selectedWeightProfile) ? false : parseFloat(selectedWeightProfile.max) === -1);
    const [progressBar, setProgressBar] = useState(false);
    const [closeDialog, setCloseDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);


    /* Add / Update Shipping mode api call */
    const addOrUpdateWeightProfile = async () => {
        setProgressBar(true);
        setErrorMessage(null);
        const newData = {
            "min": parseFloat(minimumWeightRef.current.value),
            "max": noMaxLimit ? -1 : parseFloat(maximumWeightRef.current.value)
        }
        if (isNil(selectedWeightProfile)) {
            ShippingData.addShippingWeightProfile(newData).then((data) => {
                onApiSuccess();
            }).catch((err) => {
                onApiFailure(err)
            }).then(() => {
                setProgressBar(false)
            });;
        } else {
            newData.id = selectedWeightProfile.id;
            ShippingData.updateShippingWeightProfile(newData).then((data) => {
                onApiSuccess();
            }).catch((err) => {
                onApiFailure(err)
            }).then(() => {
                setProgressBar(false)
            });;
        }
    }

    /* Delete Shipping mode api call */
    const deleteShippingWeight = async () => {
        setProgressBar(true);
        setErrorMessage(null);
        ShippingData.deleteShippingWeightProfile({ id: selectedWeightProfile.id }).then((data) => {
            onApiSuccess();
        }).catch((err) => {
            onApiFailure(err)
        }).then(() => {
            setProgressBar(false)
        });
    }

    const onApiSuccess = () => {
        setCloseDialog(true);
        onSuccess();
    }

    const onApiFailure = (error) => {
        setErrorMessage(error);
    }

    const validateWeights = () => {
        if (Utility.isNumberInValid(minimumWeightRef)) {
            setErrorMessage('Minimum weight should not be less than 0');
            return;
        }

        if (!noMaxLimit) {
            if (Utility.isNumberInValid(maximumWeightRef)) {
                setErrorMessage('Maximum weight should not be less than 0');
                return;
            }
            if (parseFloat(maximumWeightRef.current.value) <= parseFloat(minimumWeightRef.current.value)) {
                setErrorMessage('Maximum weight should be greater than Minimum weight');
                return;
            }
        }

        setErrorMessage(null);
        addOrUpdateWeightProfile();
    }

    const onCheckedStatusChange = (e) => {
        setNoMaxLimit(e.target.checked);
        minimumWeightRef.current.focus();
    }

    return <DialogBox
        type={"simple"}
        dialogId={'#add-new-shipping-weight-profile'}
        show={showDialog}
        width={300}
        onClose={onClose}
        onSuccess={deleteMode ? deleteShippingWeight : validateWeights}
        primaryBtnText={deleteMode ? 'YES' : 'SAVE'}
        showProgressBar={progressBar}
        closeDialog={closeDialog}
        showFooterDivider={!deleteMode}
        danger={deleteMode}
    >
        <Fragment>
            <div className='newWeightProfile'>
                <p className='dialogTitle'>
                    {deleteMode ? `Are you sure you want to delete this weight profile : ${Utility.getWeightProfileName(selectedWeightProfile, weightUnit)}?`
                        : 'Add weight profile'}
                </p>
                {!deleteMode && <div>
                    <div className='weightContainerWithUnit'>
                        <TextField
                            autoFocus
                            label='Minimum Weight'
                            disabled={progressBar}
                            defaultValue={!isNil(selectedWeightProfile) ? selectedWeightProfile.min : null}
                            inputRef={minimumWeightRef}
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                            fullWidth={true}
                            type='number' />
                        <span className='unit'>
                            {weightUnit}
                        </span>
                    </div>
                    {!noMaxLimit && <div className='weightContainerWithUnit'>
                        <TextField
                            label='Maximum Weight'
                            disabled={noMaxLimit || progressBar}
                            defaultValue={!isNil(selectedWeightProfile) ? (selectedWeightProfile.max === -1 ? null : selectedWeightProfile.max) : null}
                            inputRef={maximumWeightRef}
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                            fullWidth={true}
                            type='number' />
                        <span className='unit'>
                            {weightUnit}
                        </span>
                    </div>}
                    {noMaxLimit && <p className='noMaxLimitContent'>Unlimited (No maximum weight)</p>}
                    <CustomCheckbox
                        checked={noMaxLimit}
                        label={'No upper limit'}
                        handleChange={onCheckedStatusChange}
                    />
                    {errorMessage && <CustomErrorComponent errorMessage={errorMessage} />}
                </div>}
            </div>
        </Fragment>
    </DialogBox>
}
