import React, {useState, useEffect} from 'react';
import {useRouteMatch, useParams, Switch, Route} from 'react-router-dom';
import payments from '../../data/payments';
import Rzp from "./rzp/Rzp";
import MercadoPago from './mercadoPago';
import Loader from '../../common/Loader';
import { useAppContext } from '../../common/Contexts/AppContext';
import Paytm from "./Paytm";
import PayPal from "./paypal/PayPal";
import './style.css';
import useQuery from "../../common/Hooks/useQuery";

export default function PaymentDetail(){
    const {id} = useParams();
    const [gatewayData, setPaymentData] = useState({});
    const [loading, setLoading] = useState(true);
    const {path, url} = useRouteMatch();
    const source = useQuery().get('source');
    console.log("USE ROUTE MATCH", path, url, id);

    useEffect(() => {
        setLoading(true);
        payments.getPaymentMethodForId(id).then((data) => {

            setPaymentData(data);
            setLoading(false);
        });

    }, [id]);

    const [_, dispatch] = useAppContext();
    useEffect(() => {
        dispatch({
            type: 'UPDATE_NAVBAR',
            navBar: {
                background: source === 'desktop' ? '#0f141a' : '#2b313c',
                color: '#FFFFFF',
                title: gatewayData.title || null,
                hideBack: false
            }
        });
    }, [dispatch, gatewayData]);

    return (
        <div className="paymentDetailContainer">
            <div className="paymentDetailContent">
                <Switch>
                    {/*<Route path="/payments/CASH_ON_DELIVERY">*/}
                        {/*{<Cod gatewayData={gatewayData}/>}*/}
                    {/*</Route>*/}
                    <Route path="/payments/RAZORPAY">
                        {loading? <div className="loaderContainer"> <Loader /></div> : <Rzp gatewayData={gatewayData}/>}
                    </Route>
                    <Route path="/payments/PAYTM">
                        {loading? <div className="loaderContainer"> <Loader /></div> : <Paytm gatewayData={gatewayData}/>}
                    </Route>
                    <Route path="/payments/PAYPAL">
                        {loading? <div className="loaderContainer"> <Loader></Loader></div> : <PayPal gatewayData={gatewayData}/>}
                    </Route>
                    <Route path="/payments/MERCADO_PAGO">
                        {loading? <div className="loaderContainer"> <Loader></Loader></div> : <MercadoPago gatewayData={gatewayData}/>}
                    </Route>
                </Switch>
            </div>
        </div>
    )
}
