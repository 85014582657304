import { 
  CHECKOUT_FORM_STATES, 
  CHECKOUT_FORM_LABELS,
  FORM_FIELD_TYPES,
  CHECKOUT_FORM_FIELD_TYPES,
  AVAILABLE_DATE_FORMATS,
  AVAILABLE_TIME_FORMATS
} from './constants';

export const getCheckoutFormFieldsDataCacheKey = () => 'GET_CHECKOUT_FORMS_FIELDS_DATA'

/**
 * After registering get selected field it will start returnig new selected field to add or edit
 * @returns returns field value, default = FORM_FIELD_TYPES.TEXT
 */
export let getSelectedFormField = () => ({ fieldType: FORM_FIELD_TYPES.TEXT });

export const registerGetSelectedFormField = fn => {
  getSelectedFormField = fn;
}

export const getCheckoutFormFieldTypeByType = type =>  CHECKOUT_FORM_FIELD_TYPES
  .find(({ fieldType }) => fieldType === type) || {};

export const getLabelOfSelectedFormField = () => {
  const { fieldType } = getSelectedFormField();
  if (!fieldType) {
    return '';
  }
  return getCheckoutFormFieldTypeByType(fieldType).label;
};

export const getCheckoutFormTitleDetails = state => {
  if (state === CHECKOUT_FORM_STATES.SELECT_FORM_TYPE) {
    return {
      label: CHECKOUT_FORM_LABELS.SELECT_FIELD_TYPE_TITLE,
      tagline: CHECKOUT_FORM_LABELS.SELECT_FIELD_TYPE_TAGLINE
    };
  }

  if (state === CHECKOUT_FORM_STATES.ENTER_NAME) {
    const label = getLabelOfSelectedFormField();
    return { label, tagline: CHECKOUT_FORM_LABELS.ENTER_NAME_OF_FIELD_TAGLINE };
  }

  return {};
};

export const getNextFormState = currentState => {
  if (currentState !== undefined) {
    return currentState;
  }

  if (currentState === CHECKOUT_FORM_STATES.SELECT_FORM_TYPE) {
    return CHECKOUT_FORM_STATES.ENTER_NAME;
  }

  if (currentState === CHECKOUT_FORM_STATES.ENTER_NAME) {
    return CHECKOUT_FORM_STATES.SUBMIT;
  }
};

// checks for validity of new field, checks for all required fields and values
export const checkIfNewFieldIsValid = (formField, { fieldLabelErrorCallback, fieldOptionsErrorCallback } = {}) => {
  const { fieldName, fieldType, fieldOptions } = formField;

  let isValid = true;

  if (!fieldName) {
    if (typeof fieldLabelErrorCallback === 'function') {
      fieldLabelErrorCallback(true);
    }
    isValid = false;
  }

  if (
    (fieldType === FORM_FIELD_TYPES.MULTI_SELECT || fieldType === FORM_FIELD_TYPES.DROPDOWN) &&
    (!Array.isArray(fieldOptions) || fieldOptions.length === 0 || fieldOptions.some(option => !option))
  ) {
    if (typeof fieldOptionsErrorCallback === 'function') {
      fieldOptionsErrorCallback(true);
    }
    isValid = false;
  }

  return isValid;
};

/**
 * Checks if options needs to be captured for the field type
 * @param {*} fieldType form field type of selected field 
 * @returns true if options are required for the field
 */
export const checkIfOptionsAreRequiredFormField = fieldType =>
  fieldType === FORM_FIELD_TYPES.DROPDOWN || fieldType === FORM_FIELD_TYPES.MULTI_SELECT;

/**
 * To get options value 
 * @param {*} fieldType 
 * @returns 
 */
export const getOptionIfRequiredForFormField = fieldType => {
  if (checkIfOptionsAreRequiredFormField(fieldType)) {
    return [''];
  };
  if (fieldType === FORM_FIELD_TYPES.TIME || fieldType === FORM_FIELD_TYPES.DATE) {
    return [];
  }
  return null;
}

/**
 * Gets the configuration of available options to select for a field type
 * @param {String} fieldType 
 * @returns { label: {String}, options: Array } configuration based on fieldType if available else null
 */
export const getAvailableOptionsToSelect = fieldType => {
  switch (fieldType) {
    case FORM_FIELD_TYPES.TIME:
      return { label: CHECKOUT_FORM_LABELS.TIME_LABEL, options: AVAILABLE_TIME_FORMATS };
    case FORM_FIELD_TYPES.DATE:
      return { label: CHECKOUT_FORM_LABELS.DATE_LABEL, options: AVAILABLE_DATE_FORMATS }
    default:
      return null;
  }
}

export const dataExists = data => Array.isArray(data) && data.length > 0;

// add a new form field
export const addNewField = (data, field, inseartedId) => {
  if (!field) {
    return data;
  }
  if (inseartedId !== undefined) {
    // updating the new id received from server
    // before this using a dummy id
    field.id = inseartedId;
  }
  const copiedData = [...data];
  const fieldIndex = copiedData.findIndex(({ id }) => id === field.id);
  if (fieldIndex === -1) {
    // assuming all global non-orderable fields are before that.
    const indexOfFirstOrderableField = copiedData.findIndex(({ canReorder }) => canReorder);
    if (indexOfFirstOrderableField === -1) {
      // insert it as at bottom of non-orderable fields
      copiedData.push(field);
    } else {
      copiedData.splice(indexOfFirstOrderableField, 0, field);
    }
  } else {
    copiedData[fieldIndex] = field;
  }
  return copiedData;
};

// removes a form field from the list
export const removeFormField = (data, fieldId) =>
  data.filter(({ id }) => id !== fieldId);

export const toggleVisibility = (data, fieldId) => {
  const index = data.findIndex(({ id }) => id === fieldId);
  if (index === -1) {
    return data;
  }
  const { visibility, canToggleVisibility } = data[index];
  if (!canToggleVisibility) {
    return data;
  }
  const copiedData = [...data];
  copiedData[index] = {
    ...copiedData[index],
    visibility: !visibility
  }
  return copiedData;
};

/**
 * checks if an index is invalid
 * @param {*} index index to be validated
 * @param {*} length length of an array
 * @returns true if index is invalid
 */
const invalidIndex = (index, length) => index === undefined || index < 0 || index >= length;

/**
 * Moved an element from one index[OldIndex] to another[newIndex] in an array
 * @param {Array} data data to be updated
 * @param {Object: { OldIndex: number, newIndex: number }} OldIndex: Element to move from old index
 * @param {Object: { OldIndex: number, newIndex: number }} NewIndex: new index of the moved element
 * @returns sorted array after moving element
 */
export const moveArray = (data, { oldIndex, newIndex }) => {
  if (Array.isArray(data) &&
    !invalidIndex(oldIndex, data.length) &&
    !invalidIndex(newIndex, data.length)
  ) {
    data.splice(newIndex, 0, data.splice(oldIndex, 1)[0]);
  }

  return data;  
};
