import React, { useEffect, useState } from 'react';
import Ripples from 'react-ripples';
import { withRouter, useHistory } from "react-router-dom";
import { isEmpty } from 'lodash';
import {setNavigationBarColor} from '../../os';
import { useAppContext } from "qs-common/Contexts/AppContext";
import useQuery from "qs-common/Hooks/useQuery";
import ShippingPricingToggle from 'qs-components/Shipping/Settings/ShippingPricingToggle'
import HomeLocation from 'qs-components/Shipping/Settings/HomeLocation';
import ShippingWeightUnit from 'qs-components/Shipping/Settings/ShippingWeightUnit';
import ShippingMode from 'qs-components/Shipping/Settings/ShippingMode';
import ShippingZones from 'qs-components/Shipping/Settings/ShippingZone';
import ShippingWeightProfiles from 'qs-components/Shipping/Settings/ShippingWeightProfiles';
import { ReactComponent as NextIcon } from '../../assets/images/right_arrow.svg';
import "./style.scss";

const ShippingProfile = () => {
  const source = useQuery().get("source");
  const desktop = source === "desktop";
  const [_, dispatch] = useAppContext();

  const history = useHistory();

  const [shippingCostCollectionStatus, setShippingCostCollectionStatus] = useState(false);
  const [homeLocation, setHomeLocation] = useState(null);
  const [shippingModes, updateShippingModes] = useState([]);
  const [shippingZones, updateShippingZones] = useState([]);
  const [weightUnit, setWeightUnit] = useState(null);

  const [numberOfProfiles, setNumberOfProfiles] = useState(0);

  useEffect(() => {
    dispatch({
      type: "UPDATE_NAVBAR",
      navBar: {
        background: desktop ? "#0f141a" : "#191F27",
        color: "#FFFFFF",
        title: "Shipping cost settings",
        hideBack: desktop
      },
    });
    setNavigationBarColor('#28303A');
  }, []);

  useEffect(() => {
    setNumberOfProfiles(getNumberOfProfilesSelected());
  }, [shippingModes, shippingZones])

  useEffect(() => {
    if (!shippingCostCollectionStatus) {
      setNumberOfProfiles(0)
    }
  }, [shippingCostCollectionStatus])

  function navigateToShippingProfiles() {
    history.push({
      pathname: '/shipping-profiles',
      state: {
        shippingModes: shippingModes,
        shippingZones: shippingZones,
        weightUnit: weightUnit,
        isDesktop: desktop
      }
    });
  }

  const onWeightUpdated = (weight) => {
    setWeightUnit(weight);
  }

  const getNumberOfProfilesSelected = () => {
    let numberOfProfilesSelected = 0;
    shippingModes.forEach(function (mode) {
      if (mode.enabled) {
        shippingZones.forEach(function (zone) {
          if (zone.enabled) {
            numberOfProfilesSelected++;
          }
        })
      }
    })
    return numberOfProfilesSelected;
  }

  const renderBottomNavigationBar = () => {
    if (isEmpty(shippingModes) || isEmpty(shippingZones) || numberOfProfiles === 0) {
      return null;
    }

    return <Ripples className='actionButtonsContainer' onClick={navigateToShippingProfiles} >
      <span>Enter Rates</span>
      <NextIcon fill="#FFF" width="20" height="20" />
    </Ripples>
  }

  return <div className='shippingSettingsContainer'>
    <div className='shippingComponents' style={{ marginBottom: numberOfProfiles !== 0 ? 0 : '48px' }}>
      <ShippingPricingToggle shippingCostCollectionUpdate={(shippingCostCollectionStatus) => setShippingCostCollectionStatus(shippingCostCollectionStatus)} />
      {
        !shippingCostCollectionStatus ? null :
          <div>
            <HomeLocation onHomeLocationUpdated={(homeLocation) => setHomeLocation(homeLocation)} />
            {
              !homeLocation || !homeLocation.name ? null :
                <div>
                  <ShippingWeightUnit
                    onWeightUpdated={onWeightUpdated} />
                  <ShippingMode
                    onUpdateShippingModes={shippingModes => updateShippingModes(shippingModes)} />
                  <ShippingZones
                    homeLocation={homeLocation}
                    onUpdateShippingZones={shippingZones => updateShippingZones(shippingZones)} />
                  <ShippingWeightProfiles
                    weightUnit={weightUnit} />
                </div>
            }
          </div>
      }
    </div>
    {renderBottomNavigationBar()}
  </div>
}

export default withRouter(ShippingProfile);