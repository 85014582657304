import React, { useCallback, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomLabel from '../CustomLabel';

export default function ({
  className,
  label: fieldLabel,
  options,
  selectedOptions = [],
  onChange = () => {}
}) {
  const [selected, setSelected] = useState(selectedOptions);

  const handleChangeChecked = useCallback((e, optionValue) => {
    const { checked } = e.target;

    let copiedSelected = [...selected];
    if (checked) {
      copiedSelected.push(optionValue);
    } else {
      copiedSelected = selected.filter(value => optionValue !== value);
    }
    setSelected(copiedSelected);
    onChange(copiedSelected);
  }, [onChange, selected]);

  const isChecked = optionValue => !!selected.find(value => value === optionValue);

  return (
    <FormGroup row className={className}>
      <CustomLabel value={fieldLabel} />
      {options.map(({ label, value }, index) => (
        <FormControlLabel
          key={value || index}
          label={<div className={'customCheckboxLabel'}> {label} </div>}
          control={(
            <Checkbox
              checked={isChecked(value)}
              onChange={e => handleChangeChecked(e, value)}
              name={label}
            />
          )}
        />
      ))}
    </FormGroup>
  );
} 