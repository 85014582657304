import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import ShippingData from 'qs-data/shipping';
import {setNavigationBarColor} from '../../os';
import { useAppContext } from "qs-common/Contexts/AppContext";
import Profiles from 'qs-components/Shipping/Profiles';
import "./style.scss";

const ShippingProfile = () => {
  const history = useHistory();
  const [_, dispatch] = useAppContext();

  const [shippingModes, setShippingModes] = useState(null);
  const [shippingZones, setShippingZones] = useState(null);
  const [weightUnit, setWeightUnit] = useState(null);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    let shippingData = history.location.state;
    if (!shippingData) {
      shippingData = ShippingData.getCachedShippingProfileData()
    }
    if (shippingData) {
      setShippingModes(shippingData.shippingModes)
      setShippingZones(shippingData.shippingZones)
      setWeightUnit(shippingData.weightUnit)
      setIsDesktop(shippingData.isDesktop)
      ShippingData.setCacheForShippingProfileData(shippingData);
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: "UPDATE_NAVBAR",
      navBar: {
        background: isDesktop ? "#0f141a" : "#191F27",
        color: "#FFFFFF",
        title: 'Shipping Profiles'
      },
    });
    setNavigationBarColor('#28303A');
  }, [dispatch, isDesktop]);

  return <div className='shippingProfileContainer'>
    {shippingModes && shippingZones && <Profiles
      shippingModes={shippingModes}
      shippingZones={shippingZones}
      weightUnit={weightUnit}
      isDesktop={isDesktop}
    />}
  </div>
}

export default withRouter(ShippingProfile);