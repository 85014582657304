import React from 'react';

export default function ({ color, size  }) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 44 44"
			xmlns="http://www.w3.org/2000/svg"
		>
			<defs />
			<g
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
				transform="translate(-435.000000, -34.000000)"
			>
				<g
					id="icons8-search_filled"
					transform="translate(435.000000, 34.000000)"
					fill={color || '#3833EA'}
					fillRule="nonzero"
				>
					<path d="M17,0 C7.601563,0 0,7.601563 0,17 C0,26.398438 7.601563,34 17,34 C20.355469,34 23.460938,33.015625 26.09375,31.34375 L38.375,43.625 L42.625,39.375 L30.5,27.28125 C32.679688,24.421875 34,20.878906 34,17 C34,7.601563 26.398438,0 17,0 Z M17,4 C24.199219,4 30,9.800781 30,17 C30,24.199219 24.199219,30 17,30 C9.800781,30 4,24.199219 4,17 C4,9.800781 9.800781,4 17,4 Z" />
				</g>
			</g>
		</svg>
	);
}
