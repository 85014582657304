import React, { useState, useEffect } from 'react';
import { isNil, isEmpty } from 'lodash';
import { CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import ShippingData from "qs-data/shipping";
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import SingleWeightProfilesWithPrice from "../SingleWeightProfilesWithPrice";
import "./style.scss";

function WeightProfilesWithPrices({ shipping, currencyUnit, weightUnit }) {

  /* Shipping weight profile add or update */
  const [shippingCostPrices, setShippingCostPrices] = useState([]);
  const [shippingData, setShippingData] = useState([]);
  const [progressBar, setProgressBar] = useState(false);
  const [errorComponent, showErrorComponent] = useState(false);

  useEffect(() => {
    setShippingData(shipping);
  }, []);

  useEffect(() => {
    if (!isNil(shippingData) && !isEmpty(shippingData)) {
      fetchShippingCostPrices();
    }
  }, [shippingData]);

  /* Fetch Shipping mode api call */
  const fetchShippingCostPrices = async () => {
    setProgressBar(true);
    showErrorComponent(false);
    ShippingData.getShippingCostPrices(shippingData.mode.id, shippingData.zone.id).then((weightsWithPrice) => {
      weightsWithPrice.sort((a, b) => parseFloat(a.weight.min) - parseFloat(b.weight.min));
      setShippingCostPrices(weightsWithPrice);
    }).catch(() => {
      showErrorComponent(true);
    }).then(() => {
      setProgressBar(false)
    });
  }

  const renderList = () => {
    if (progressBar) {
      return <CircularProgress size={24} color="primary" style={{ marginTop: '8px' }} />
    } else if (errorComponent) {
      return <CustomErrorComponent style={{ marginTop: '8px' }} onRetryClick={fetchShippingCostPrices} />
    } else {
      return <div className='weightProfileContainer'>
        {shippingCostPrices.map(function (item, index) {
          return <SingleWeightProfilesWithPrice
            currencyUnit={currencyUnit}
            weightUnit={weightUnit}
            onSuccess={() => console.log('successfully updated')}
            weightProfile={item}
            key={index} />
        })}
      </div>
    }
  }

  return renderList()
}

export default withRouter(WeightProfilesWithPrices);