import React, { useState } from 'react';
import payments from '../../../data/payments';
import YouTubeRow from "./YouTubeRow";
import './style.css';
import useStyles from '../../../common/CustomStyles';
import CustomTextField from "../../../common/CustomTextField";
import Loader from "../../../common/Loader";
import Button from "@material-ui/core/Button";
import Toast from '../../../common/Alerts/Toast';

export default function Paytm({gatewayData}){

    const [loading, setLoading] = useState(false);
    const _credentials = gatewayData.credentials || {};
    const [isActive, setIsActive] = useState(gatewayData['isActive'] || false);
    const [credentials, setCredentials] = useState({
        mid : _credentials.mid || '',
        secret_key : _credentials.secret_key || ''
    });

    const [toastState, setToastState] = useState({
        message : '',
        open : false
    });

    const [errors, setErrors] = useState({});

    const classes = useStyles();

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setCredentials((credentials) => {
            return {
                ...credentials,
                [name] : value
            }
        });
        setErrors({});
        // setLoading(false);
    };

    const validateForm = () => {
        const _errors = {};
        Object.keys(credentials).forEach((element) => {
            if(credentials[element] === ''){
                _errors[element] = 'This field cannot be left blank'
            }
        });
        setErrors(_errors);
        console.log("ERRORS", _errors);
        return Object.keys(_errors).length;
    };

    const onSubmit = () => {

        let numberErrors = validateForm();

        if(numberErrors){
            return;
        }
        setLoading(true);
        payments.enablePayTM(credentials).then(() => {
            setErrors({});
            setLoading(false);
            setIsActive(true);
            setToastState({
                message : 'Payment gateway enabled',
                open : true
            });
        }).catch((err) => {
            setErrors({});
            setLoading(false);
            setToastState({
                message : 'An error occured',
                open : true
            })
        });
    };

    return (
        <div className="PaytmContainer">
            <div className="introContainer">
                <div className="introText">
                    Please enter your PayTM credentials in the boxes below. You will find these credentials from your PayTM dashboard
                </div>

                <a className="PaytmLink" target="_blank" href="https://dashboard.paytm.com/next/apikeys">Click here to access PayTM</a>

                <YouTubeRow videoId={gatewayData.youtubeTutorialVideoId}/>
            </div>

            <form className={classes.root} noValidate autoComplete="off">
                <CustomTextField
                    fullWidth
                    label="Merchant ID"
                    margin="normal"
                    value={credentials.mid}
                    name="mid"
                    onChange={onChange}
                    error={errors['mid']}
                    helperText={errors['mid']}
                    disabled={isActive}
                />
                <CustomTextField
                    fullWidth
                    label="Merchant Key(Secret Key)"
                    value={credentials.secret_key}
                    name="secret_key"
                    onChange={onChange}
                    error={!!errors['secret_key']}
                    helperText={errors['secret_key']}
                    disabled={isActive}
                />

                {loading ? <div style={{marginLeft : 24, marginTop : 24}}><Loader small/></div>
                    : isActive ?
                        <div><Button
                            variant="contained"
                            size="large"
                            className={classes.buttonDisable}
                            onClick={() => {
                                setLoading(true);
                                payments.disablePayTM().then(() => {
                                    setIsActive(false);
                                    setLoading(false);
                                    setToastState({
                                        message : 'Payment gateway disabled',
                                        open : true
                                    });
                                });
                            }}
                        >Disable</Button>
                        </div>
                        : <Button
                            variant="contained"
                            size="large"
                            className={classes.button}
                            onClick={onSubmit}
                        >Enable </Button>
                }

            </form>

            <Toast
                open = {toastState.open}
                message = {toastState.message}
                onClose={() => {
                    setToastState({
                        open : false,
                        message : ''
                    })
                }}
            />



        </div>
    )
}
