import { isNil, startCase, orderBy, capitalize } from 'lodash';

export const DEFAULT_THEME_ID = 'light';
export const CUSTOM_THEME_FEATURE = 'THEMES_MANAGER';

const Utility = {

  getWeightUnits() {
      return ['kg', 'pound']
  },

  getShippingZoneDisplayName(zone) {
    let name = capitalize(startCase(zone.name));
    if (!isNil(zone.locations) && zone.locations.length !== 0) {
      name = name + ' (';
      zone.locations.forEach((element, index) => {
        const data = element.name + (index !== (zone.locations.length - 1) ? ', ' : '');
        name += data;
      });
      name += ')';
    }
    return name;
  },

  getShippingPriceWeightUnits(defaultUnit) {
    const units = [{ key: 1, value: 'per ' + defaultUnit }];
    let halfValue = 'per 1/2 ' + defaultUnit;
    if (defaultUnit === 'kg') {
      halfValue = 'per 500 gms';
    }
    units.push({ key: 0.5, value: halfValue });
    units.push({ key: -1, value: 'Flat price' });
    return units;
  },

  getShippingPriceWeightFromKey(defaultUnit, key) {
    const weights = this.getShippingPriceWeightUnits(defaultUnit);
    return weights.find(obj => obj.key === key);
  },

  isNumberInValid(numberRef) {
    const digitPattern = /^\d*\.{0,1}\d{0,4}$/; //upto 4 decimal points
    return isNil(numberRef.current) || isNil(numberRef.current.value) || numberRef.current.value === '' || !digitPattern.test(numberRef.current.value) || parseFloat(numberRef.current.value) < 0;
  },

  getSortedZones(zones) {
    if (!zones) {
      return []
    }

    return orderBy(zones, ['enabled', 'name'], ['desc', 'desc']);
  },

  getWeightProfileName(item, weightUnit) {
    let weight = '';
    if (parseInt(item.max) === -1) {
      weight = 'Above ' + item.min + " " + weightUnit;
    } else {
      weight = item.min + " " + weightUnit + ' - ' + item.max + " " + weightUnit;
    }
    return weight
  },

  copyToClipboard(copyText) {
    const el = document.createElement('textarea');
    el.value = copyText;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  },

  getDateOrdinalNum(n) {
    return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
  },

  getFormattedDate(date) {
    const monthNames = [
      "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ];

    const day = this.getDateOrdinalNum(date.getDate());
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  },

  hexToRgba(hex, opacity) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity})` : null;
  },

  isValidHex(value) {
    return value && value.length === 7 && value[0] === '#';
  }
};

export default Utility;