import {makeStyles, withStyles} from "@material-ui/core/styles";
import reds from "@material-ui/core/colors/red"
const red = reds[400];
const useStyles = makeStyles((theme) => ({
    root: {
        '& > .MuiFormControl-root': {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            marginTop: theme.spacing(3),
        },
        display : 'flex',
        flexWrap : 'wrap'
    },
    formControl: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(3),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    inputLabel : {
        color : '#fff'
    },
    button: {
        margin: theme.spacing(2),
        marginLeft: 24,
        background : '#2FB57A',
        color : '#FFF',
        '&:hover': {
            backgroundColor: '#2FB57A',
            borderColor: '#2FB57A',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#2FB57A',
            borderColor: '#2FB57A',
        }
    },
    buttonDisable : {
        margin: theme.spacing(2),
        background : red,
        color : '#FFF',
        '&:hover': {
            backgroundColor: red,
            borderColor: red,
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: red,
            borderColor: red,
        }
    }
}));

export default useStyles;