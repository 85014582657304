import React from 'react';
import {ReactComponent as Play} from '../../../assets/images/play.svg';

function YouTubeRow({ videoId, title }) {
  const openYouTube = () => {
    window.open(`https://www.youtube.com/watch?v=${videoId}`);
  };

  return (
    <>
      <div className="YouTubeRowContainer" onClick={openYouTube}>
        <div className="PlayIcon">
          <Play />
        </div>
        <div className="RightContainer">
          <div
            className="Title"
            style={{ fontWeight: 600, fontSize: 16 }}
          >
            {title || "How to connect PayTM to QuickSell"}
          </div>
          <div className="SubTitle" style={{ color: "#ffc1be" }}>
            Tap to see video
          </div>
        </div>
      </div>
    </>
  );
}

export default YouTubeRow;
