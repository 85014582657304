import {
  EmailChanger,
  PaymentDetail,
  Payments,
  Webinar,
  WebinarList,
  CustomFields,
  CheckoutForm,
  ReferralPage,
  MyReferralsPage,
  Reports,
  ShippingSettings,
  ShippingProfiles,
  ShippingRates,
  FacebookPixel,
  ThemesManager,
  ThemeUpdate,
  ThemesColorPicker
} from '../pages';

const Routes = [
  {
    path: '/payments',
    component: Payments,
    exact: true
  },
  {
    path: '/payments/:id',
    component: PaymentDetail,
    exact: false
  },
  {
    path: '/change-email',
    component: EmailChanger,
    exact: true
  },
  {
    path: '/webinar',
    component: WebinarList,
    exact: true
  },
  {
    path: '/webinar/:id',
    component: Webinar,
    exact: true
  },
  {
    path: '/custom-fields',
    component: CustomFields,
    exact: true
  },
  {
    path: "/checkout-form",
    component: CheckoutForm,
    exact: true
  },
  {
    path: '/referral',
    component: ReferralPage,
    exact: true
  },
  {
    path: '/referral/track',
    component: MyReferralsPage,
    exact: true
  },
  {
    path: '/reports',
    component: Reports,
    exact: true
  },
  {
    path: '/shipping-settings',
    component: ShippingSettings,
    exact: true
  },
  {
    path: '/shipping-profiles',
    component: ShippingProfiles,
    exact: true
  },
  {
    path: '/shipping-rates',
    component: ShippingRates,
    exact: true
  },
  {
    path: '/facebook-pixel',
    component: FacebookPixel,
    exact: true
  },
  {
    path: '/themes-manager',
    component: ThemesManager,
    exact: true
  },
  {
    path: '/theme-update',
    component: ThemeUpdate,
    exact: true
  },
  {
    path: '/color-picker',
    component: ThemesColorPicker,
    exact: true
  }
];

export default Routes;
