import React, { Fragment, useState } from 'react';
import { isNil, sortBy } from 'lodash';
import { TextField, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ShippingData from "qs-data/shipping";
import CacheRequest from 'qs-data/cacheRequest';
import CacheKeys from 'qs-data/cacheKeys';
import DialogBox from "qs-common/DialogBox";
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import "./style.scss";

export default function ({ showDialog, onClose, onSuccess }) {

    const noResultTextWhenCharLessThanThree = 'Type full city name';
    const noResultText = 'No results found.';

    const [cities, setCities] = useState([]);
    const [updatedLocation, setUpdatedLocation] = useState(null);

    const [progressBar, setProgressBar] = useState(false);
    const [loadingData, setLoadingData] = useState(false);

    const [closeDialog, setCloseDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [noOptionText, setNoOptionText] = useState(noResultTextWhenCharLessThanThree);

    const handleHomeLocationChange = (event, values) => {
        setUpdatedLocation(values);
    };

    const fetchCities = async (e) => {
        const content = (e.target.value).trim();
        if (!isNil(content) && content.length > 2) {
            setErrorMessage(null);
            setLoadingData(true);
            ShippingData.getAllCities(e.target.value).then((allCities) => {
                const sortedCities = sortBy(allCities, o => o.name);
                if (sortedCities.length === 0) {
                    setNoOptionText(noResultText);
                }
                setCities(sortedCities);
            }).catch((err) => {
                onApiFailure(err)
            }).then(() => {
                setLoadingData(false)
            });
        } else {
            setNoOptionText(noResultTextWhenCharLessThanThree);
            setCities([]);
        }
    }

    const updateHomeCity = async () => {
        setProgressBar(true);
        setErrorMessage(null);
        setUpdatedLocation(updatedLocation);
        ShippingData.updateShippingHomeLocation({ cityId: updatedLocation.cityId }).then(() => {
            CacheRequest.deleteCacheForKeys([CacheKeys.shippingZonesCacheKey]);
            onApiSuccess();
        }).catch((err) => {
            onApiFailure(err)
        }).then(() => {
            setProgressBar(false)
        });
    }

    const onApiSuccess = () => {
        setCloseDialog(true);
        onSuccess(updatedLocation);
    }

    const onApiFailure = (error) => {
        setErrorMessage(error);
    }

    return showDialog && <DialogBox
        type={"simple"}
        dialogId={'#select-city'}
        show={showDialog}
        width={300}
        primaryBtnText={'SAVE'}
        onSuccess={updateHomeCity}
        onClose={onClose}
        disabledPrimaryButton={isNil(updatedLocation)}
        showProgressBar={progressBar}
        closeDialog={closeDialog}
    >
        <Fragment>
            <div className='cityDialogContent'>
                <p className='dialogTitle'>Select city</p>
                <Autocomplete
                    loading={loadingData}
                    noOptionsText={noOptionText}
                    onChange={handleHomeLocationChange}
                    id="autocomplete-city-select"
                    options={cities}
                    getOptionLabel={(option) => option.name + ', ' + option.state}
                    renderInput={(params) => <TextField 
                        onChange={fetchCities} 
                        autoFocus {...params} 
                        placeholder={'Type atleast 3 characters'} 
                        margin="normal"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingData ? <CircularProgress color="inherit" size={18} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }} />}
                />
                {errorMessage && <CustomErrorComponent errorMessage={errorMessage} />}
            </div>
        </Fragment>
    </DialogBox>
}
