import React, {useState, useEffect, useReducer} from 'react';
import {CSSTransition} from 'react-transition-group'
;
function LiveButton() {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setInterval(() => {
            setShow((s) => !s)
        }, 700);
    }, []);

    return (
        <>
            <CSSTransition in={show} timeout={700} classNames="live">
                <div className="TimeRemaining" style={{marginBottom : 10}}>LIVE NOW</div>
            </CSSTransition>
        </>
    )
}

export default LiveButton;