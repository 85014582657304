import React, {useState, useReducer, useEffect} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import payments from '../../../data/payments';
import Loader from '../../../common/Loader/index';
import useStyles from "../../../common/CustomStyles";
import CustomTextField from '../../../common/CustomTextField';
import Toast from '../../../common/Alerts/Toast';
import YouTubeRow from "../Paytm/YouTubeRow";
import './style.css';

function formReducer(state, {type, ...data}){
    if(type === 'input-changed'){
        return {
            ...state,
            [data.name] : data.value
        }
    } else if(type === 'initialize'){
        const credentials = data;
        return {
            ...state,
            ...credentials
        }
    }
}

export default function MercadoPago({gatewayData}){

    const [isActive, setIsActive] = useState(gatewayData['isActive'] || false);

    const credentials = gatewayData.credentials || {
        publicKey : '',
        accessToken : '',
        accountCurrency : ''
    };

    const classes = useStyles();
    const [errors, setErrors] = useState({});
    const [toastState, setToastState] = useState({
        message : '',
        open : false
    });
    const [loading, setLoading] = useState(false);
    const [state, dispatch] = useReducer(formReducer, credentials);

    useEffect(() => {
        const companyMeta = gatewayData.companyMeta || {};
        dispatch({
            type : 'initialize',
            name : companyMeta.name,
            businessName : companyMeta.companyName,
            email : companyMeta.email,
            ...gatewayData.credentials
        })
    }, [gatewayData]);

    const validateForm = () => {
        const _errors = {};

        Object.keys(state).forEach((element) => {
            if(state[element] === ''){
                _errors[element] = 'This field cannot be left blank'
            }
        });
        setErrors(_errors);
        console.log("ERRORS", _errors);
        return Object.keys(_errors).length;
    };

    const onSubmit = () => {
        let numberErrors = validateForm();
        if(numberErrors){
            return;
        }
        setLoading(true);
        payments.enableMercado(state).then((res) => {
            if(!res){
                setErrors({});
                setLoading(false);
                setToastState({
                    message : 'An error occured',
                    open : true
                });
                return;
            }

            setErrors({});
            setLoading(false);
            setToastState({
                message : 'Payment gateway enabled',
                open : true
            });
            setIsActive(true);
        }).catch((err) => {
            let message = 'An error occured';
            if(err.response.data.reason === 'INTERNAL_SERVER_ERROR'){
                message = err.response.data.extraData.description;
            }

            setErrors({});
            setLoading(false);
            setToastState({
                message : message,
                open : true
            })
        });
    };

    const onChange = (e) => {

        dispatch({
            type : 'input-changed',
            name : e.target.name,
            value : (e.target.value || '')
        });
        setErrors({});
        setLoading(false);
    };

    return (
        <div className={'mercadoContainer'}>
            <div className="introContainer">
                <YouTubeRow videoId={gatewayData.youtubeTutorialVideoId} title={'How to connect Mercado to QuickSell'}/>
            </div>
            <form className={classes.root} noValidate autoComplete="off" disabled>
                <CustomTextField
                    fullWidth
                    label="Public Key"
                    margin="normal"
                    value={state.publicKey}
                    name="publicKey"
                    onChange={onChange}
                    error={!!errors['publicKey']}
                    helperText={errors['publicKey']}
                    disabled={isActive}
                />
                <CustomTextField
                    fullWidth 
                    label="Access Token"
                    value={state.accessToken}
                    name="accessToken"
                    onChange={onChange}
                    error={!!errors['accessToken']}
                    helperText={errors['accessToken']}
                    disabled = {isActive}
                />
                <FormControl
                    fullWidth
                    className={classes.formControl}
                    error={!!errors['accountCurrency']}
                    helperText={errors['accountCurrency']}
                    disabled={isActive}
                >
                    <InputLabel id="demo-simple-select-label" className={classes.inputLabel}>Account Currency</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={onChange}
                        name="accountCurrency"
                        value={state.accountCurrency}
                    >
                        { Array.isArray(gatewayData.gatewayConfig.supportedCurrencies) && gatewayData.gatewayConfig.supportedCurrencies.map((ct, i) => {
                            return <MenuItem key={i} value={ct.currencyCode}>{ct.currencyCode+ " - " + ct.countryName }</MenuItem>
                        })}
                    </Select>
                </FormControl>

                {loading ? <div style={{marginLeft : 24, marginTop : 24}}><Loader small/></div>
                    : isActive ?
                        <Button
                            variant="contained"
                            size="large"
                            className={classes.buttonDisable}
                            onClick={() => {
                                setLoading(true);
                                payments.disableMercado().then(() => {
                                    setIsActive(false);
                                    setLoading(false);
                                    setToastState({
                                        message : 'Payment gateway disabled',
                                        open : true
                                    });
                                });
                            }}
                        >Disable</Button>
                        : <Button
                            variant="contained"
                            size="large"
                            className={classes.button}
                            onClick={onSubmit}
                        >Enable </Button>
                }

            </form>
            <Toast
                message={toastState.message}
                open = {toastState.open}
                onClose={() => {
                    setToastState({
                       open : false,
                       message : ''
                    });
                }}
            />

        </div>
    )
}
