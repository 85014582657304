import React, { Fragment } from 'react';
import DialogBox from "qs-common/DialogBox";
import "./style.scss";

export default function ({ showDialog, onClose, successMessage = 'You have successfully redeemed. Continue using QuickSell and grow your business' }) {

    return showDialog && <DialogBox
        type={"simple"}
        dialogId={'#referal-redeemed'}
        show={showDialog}
        width={300}
        onSuccess={onClose}
        onClose={onClose}
        secondaryBtnText={'Close'}
        hidePrimaryButton={true}
    >
        <Fragment>
            <div className='referralRedeemedDialogContent'>
                <p className='dialogTitle'>Congratulations</p>
                <p className='dialogContent'>{successMessage}</p>
            </div>
        </Fragment>
    </DialogBox>
}
