export const CHECKOUT_FORM_LABELS = {
  title: 'Checkout Form',
  tagline: 'You can collect customer preferences and additional information before checkout.',
  noDataMessage: 'No form fields found',
  SELECT_FIELD_TYPE_TITLE: 'Collect Info',
  SELECT_FIELD_TYPE_TAGLINE: 'Choose a type of question',
  ENTER_NAME_OF_FIELD_TAGLINE: 'Enter your field name',
  CONFIRMATION_BOX_MESSAGE: 'Are you sure you want to delete this field?',
  CONFIRM_DELETE_BUTTON_TEXT: 'Yes, Delete',
  COMMON_ERROR_MESSAGE: 'Something went wrong!!, couldn\'t fetch checkout form fields',
  TIME_LABEL: 'Select Time Format',
  DATE_LABEL: 'Select Date Format'
};

export const FORM_FIELD_TYPES = {
  TEXT: 'TEXT',
  MULTI_SELECT: 'MULTI_SELECT',
  DATE: 'DATE',
  NUMBER: 'NUMBER',
  TIME: 'TIME',
  YES_NO: 'YES_NO',
  DROPDOWN: 'SINGLE_SELECT',
  RATING: 'RATING'
};

export const CHECKOUT_FORM_STATES = {
  SELECT_FORM_TYPE: 0,
  ENTER_NAME: 1,
  SUBMIT: 2
};

export const CHECKOUT_FORM_FIELD_TYPES = [
  {
    label: 'Text',
    tagline: 'Personal details or text based questions',
    fieldType: FORM_FIELD_TYPES.TEXT,
  },
  {
    label: 'Date',
    tagline: 'Delivery or any other date',
    fieldType: FORM_FIELD_TYPES.DATE,
  },
  {
    label: 'Number',
    tagline: 'Alternate phone number etc',
    fieldType: FORM_FIELD_TYPES.NUMBER,
  },
  {
    label: 'Time',
    tagline: 'Questions with a time',
    fieldType: FORM_FIELD_TYPES.TIME,
  },
  {
    label: 'Dropdown',
    tagline: 'To choose one option among many',
    fieldType: FORM_FIELD_TYPES.DROPDOWN,
  },
  // {
  //   label: 'Multi-select Multiple Choice',
  //   tagline: 'Time slots and other preferences',
  //   fieldType: FORM_FIELD_TYPES.MULTI_SELECT,
  // },
  // {
  //   label: 'Questions with answers yes or no',
  //   tagline: 'Time slots and other preferences',
  //   fieldType: FORM_FIELD_TYPES.YES_NO,
  // },
  // {
  //   label: 'Rating',
  //   tagline: 'Feedback on experince, product etc',
  //   fieldType: FORM_FIELD_TYPES.RATING,
  // }
];

export const AVAILABLE_DATE_FORMATS = [
  { label: 'DD/MM/YYYY', value: 'dd/mm/yyyy' },
  { label: 'MM/DD/YYYY', value: 'mm/dd/yyyy' },
  { label: 'DD/MM/YY', value: 'dd/mm/yy' },
  { label: 'MM/DD/YY', value: 'mm/dd/yy' }
];

export const AVAILABLE_TIME_FORMATS = [
  { label: '24 Hour', value: '24' },
  { label: '12 Hour', value: '12' },
];
