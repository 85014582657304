import React from 'react';
import "./style.scss";
import SearchIcon from "qs-assets/icons/SearchIcon";
import OrderBarIcon from "qs-assets/icons/OrderBarIcon";
import RightArrowIcon from "qs-assets/icons/RightArrowIcon";

export default function ({ primaryColor, contrastTextColor, pageTextColor, backgroundColor}) {

  const renderHeader = () => {
    return <div className='header'>
      <div className='logoAndCompanyName'>
        <div className='logo' />
        <p className='companyName' style={{
          color: pageTextColor
        }}>Company name</p>
      </div>
      <div className='searchIcon'>
        <SearchIcon color={primaryColor} width="16" height="16" />
      </div>
    </div>
  }

  const renderTagsSection = () => {
    return <div className='tagsContainer'>
      <div className='tags' style={{
        backgroundColor: primaryColor,
        color: '#fff'
      }}>~~~</div>
      <div className='tags' style={{
        border: `solid 1px ${primaryColor}`,
        color: primaryColor,
        backgroundColor: 'transparent'
      }}>~~~</div>
    </div>
  }

  const renderProductContainer = () => {
    return <div className='productContainer'>
      {renderProduct(false)}
      {renderProduct(true)}
    </div>
  }

  const renderProduct = (showDiscount) => {
    return <div className='product'>
      {showDiscount && <div className='discountBadge' style={{
        backgroundColor: primaryColor
      }}
      >~~~</div>}
      <div className='productImage' />
      <div className='priceAndDescription'>
        <p className='description'>~~~~~~</p>
        <p className='price'>Rs. ~~~</p>
      </div>
      <div className='addToCartButton' style={{
        backgroundColor: primaryColor,
        color: contrastTextColor
      }}>ADD</div>
    </div>
  }

  const renderOrderBar = () => {
    return <div className='orderBar' style={{
      backgroundColor: primaryColor,
      color: contrastTextColor
    }}>
      <div className='leftContainer'>
        <div className='orderBarIcon'>
          <OrderBarIcon color={contrastTextColor} width="16" height="16" />
        </div>
        <div>
          <p className='totalItemCount'>~~~ items</p>
          <p className='totalPrice'>Rs. ~~~</p>
        </div>
      </div>
      <div className='rightContainer'>
        <div className='viewBasket'>~~~</div>
        <div className='nextIcon'>
          <RightArrowIcon color={contrastTextColor} width="24" height="16" />
        </div>

      </div>
    </div>
  }

  return <div className='previewSection'>
    <div className='previewContainer' style={{
      backgroundColor: backgroundColor
    }}>
      <div className='topSection'>
        {renderHeader()}
        <p className='descriptionContainer' style={{
          color: pageTextColor
        }}>~~~~~</p>
        <p className='productCountContainer' style={{
          color: pageTextColor
        }}># of items</p>
        {renderTagsSection()}
        {renderProductContainer()}
      </div>
      {renderOrderBar()}
    </div>
  </div>
}
