import React, { useEffect, useState } from 'react';
import { setNavigationBarColor, setStatusBarColor } from '../../os';
import { CircularProgress } from "@material-ui/core";
import { useAppContext } from 'qs-common/Contexts/AppContext';
import ReferralList from 'qs-components/Referral/ReferralList';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import ReferralData from "qs-data/referral";
import './style.scss';

export default function MyReferralsPage(props) {

    const [referralList, setReferralList] = useState([]);
    const [progressBar, setProgressBar] = useState(true);
    const [errorComponent, showErrorComponent] = useState(false);

    useEffect(() => {
        fetchReferralCode();
    }, []);

    const fetchReferralCode = async () => {
        setProgressBar(true);
        showErrorComponent(false);
        ReferralData.getReferralFriendList().then((data) => {
            setReferralList(data);
        }).catch(() => {
            showErrorComponent(true);
        }).then(() => {
            setProgressBar(false);
        });
    }

    const [_, dispatch] = useAppContext();

    useEffect(() => {
        dispatch({
            type: 'UPDATE_NAVBAR',
            navBar: {
                background: '#242C36',
                color: '#ffffff',
                title: <b>Your referrals</b>,
                boxShadow: 'none',
                borderBottom: '1px solid #242C36'
            }
        });
        setNavigationBarColor('#242C36');
        setStatusBarColor('#242C36')
    }, [dispatch]);

    return (
        <div className='myReferralPage' style={{justifyContent : progressBar || errorComponent ? 'center' : 'flex-start'}}>
            {
                progressBar ? <CircularProgress size={36} color="primary" style={{ marginTop: '8px' }} /> :
                    errorComponent ? <CustomErrorComponent onRetryClick={fetchReferralCode} /> :
                        <ReferralList
                            referralList={referralList}
                            onRedeemSuccess={fetchReferralCode}
                        />
            }
        </div>
    );
}
