import React, { useState } from "react";
import { Button, Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DialogBox from "qs-common/DialogBox";
import green from "@material-ui/core/colors/green";
import Dialog from "qs-common/Alerts/Dialog";
import { useHistory } from "react-router-dom";
import CustomTextField from "qs-common/CustomTextField";

const useStyles = makeStyles({
  recieveBtn: {
    background: "#31BD84",
    color: "#FFFFFF",
    marginBottom: "10px",
    marginTop: "20px",
    width: 154,
    "&:hover": {
      background: "rgba(87,221,156,0.88)",
    },
  },
  steps: {
    display: "flex",
    flexFlow: "column",
  },
  inputField: {
    marginTop: "20px",
  },
  content: {
    padding: "10px 0px 40px",
    lineHeight: "1.5",
  },
  stepTwoButton: {
    color: "#92CFB3",
    width: "max-content",
    alignSelf: "flex-end",
  },
  highlightEmail: {
    fontSize: "bold",
    color: "#92CFB3",
  },
});

export default function AddUserEmail({
  setToastState,
  onSendVerificationEmail,
  setShowDialogue,
}) {
  const classes = useStyles();
  const [newEmail, setNewEmail] = useState("");
  const [step, setStep] = useState(0);

  const validateEmail = () => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(newEmail);
  };

  const submitEmail = () => {
    if (!validateEmail()) {
      setToastState({
        open: true,
        message: "Enter Valid Email",
      });
      return;
    }
    setStep(1);
  };

  return (
    <DialogBox
      width={400}
      show={true}
      title={step === 0 ? "Email setting" : "Email verification"}
      type={"custom"}
      hideFooter={true}
      onClose={() => setShowDialogue(false)}
    >
      <div className={`${classes.steps}`}>
        {step === 0 ? (
          <>
            <span style={{ color: "#999DA4", marginTop: 0, marginBottom: 10 }}>
              To receive the report, Please set the email id on your account
            </span>
            <CustomTextField
              fullWidth
              variant="filled"
              label="Email"
              margin="normal"
              name="displayEmail"
              type="email"
              value={newEmail}
              onChange={(event) => {
                setNewEmail(event.target.value);
              }}
            />
            <Button
              onClick={() => submitEmail()}
              className={`${classes.recieveBtn}`}
            >
              SET EMAIL ID
            </Button>
          </>
        ) : (
          <Dialog
            open={true}
            title={"Email verification"}
            description={
              <span>
                We will send an email on{" "}
                <b style={{ color: green.A100 }}>{newEmail}</b> to verify that
                you are the owner of that account. Please click on the
                verification link in the email to set <b>{newEmail}</b> as your
                primary email communication channel.
              </span>
            }
            confirmText={"Proceed with verification"}
            onClose={() => setShowDialogue(false)}
            handleConfirm={() => onSendVerificationEmail(newEmail)}
          />
        )}
      </div>
    </DialogBox>
  );
}
