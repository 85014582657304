import ApiWrapper from './apiWrapper';

let apis = {
  productFieldEndPoint: '/v1/product-field'
};

/* Custom fields apis */

const getProductFields = () => {
  return ApiWrapper.getDataPromise(apis.productFieldEndPoint, "fields");
};

const addProductField = (data) => {
  return ApiWrapper.postDataPromise(apis.productFieldEndPoint, data);
};

const updateProductField = (data) => {
  return ApiWrapper.putDataPromise(apis.productFieldEndPoint, data);
};

const deleteProductField = (data) => {
  return ApiWrapper.deleteDataPromise(apis.productFieldEndPoint, data);
};

export default {
  addProductField,
  getProductFields,
  deleteProductField,
  updateProductField
}