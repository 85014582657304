import network from './network';

let api = {};
let cache = {
    methods : []
};
let paymentModesFetchPromise = null;

// TODO : Error handling
// TODO : see how to use async/await
api.getPaymentMethods = (refresh) => {
    return new Promise((resolve, reject) => {
        if(refresh || !cache.methods || cache.methods.length === 0){
            network.fetchAvailablePaymentGateways().then((data) => {
                cache.methods = data;
                resolve(cache.methods);
            })
        } else {
            resolve(cache.methods);
        }
    });

};

api.getPaymentMethodForId = (id) => {
    return new Promise((resolve, reject) => {
        api.getPaymentMethods(false).then((methods) => {
            const method = methods.filter(method =>  method.id === id )[0];
            network.fetchPaymentGatewayData(id).then((resp) => {
                resolve({...method, ...resp});
            });
        });

    });
};

api.getRazorpayCompanyTypes = () => {
    return {
        'individual' : 'Individual',
        'partnership' : 'Partnership',
        'proprietorship' : 'Proprietorship',
        'public_limited' : 'Public Limited',
        'private_limited' : 'Private Limited',
        'llp' : 'Limited Liability Partnership',
        'ngo' : 'Non governmental organization',
        'trust' : 'Trust',
        'society' : 'Society',
        'not_yet_registered' : 'Not Yet Registered',
        'educational_institutes' : 'Educational Institutes',
        'other' : 'Other'
    }
};

// network.fetchPaymentGatewayData('RAZORPAY').then((resp) => {
//     console.log("SINGLE", resp);
// });


api.createOrUpdatePaymentAccount = (id, data) => {
    return network.addOrUpdatePaymentGateway(id, data);
};

api.disablePaymentGateway = (id) => {
    return network.disablePaymentGateway(id);
};

api.enableCOD = () => {
    return api.createOrUpdatePaymentAccount("CASH_ON_DELIVERY", {credentials : {}});
};

api.disabledCOD = () => {
    return api.disablePaymentGateway("CASH_ON_DELIVERY");
};

api.disableRZP = () => {
  return api.disablePaymentGateway("RAZORPAY");
};

api.enableRZP = (data) => {
  return api.createOrUpdatePaymentAccount("RAZORPAY", {credentials : data});
};

api.disablePayTM = () => {
    return api.disablePaymentGateway("PAYTM");
};

api.enablePayTM = (data) => {
    return api.createOrUpdatePaymentAccount("PAYTM", {credentials : data})
};

api.disablePayPal = () => {
    return api.disablePaymentGateway("PAYPAL");
};

api.enablePayPal = (data) => {
    return api.createOrUpdatePaymentAccount("PAYPAL", {credentials : data})
};


api.enableMercado = (data) => {
    return api.createOrUpdatePaymentAccount("MERCADO_PAGO", {credentials : data})
};

api.disableMercado = () => {
    return api.disablePaymentGateway("MERCADO_PAGO");
};

export default api;


//
// {name, tncAccepted, businessName, businessType,
//     beneficiaryName, accountNumber, ifscCode,
//     accountType, mid, secret_key}



