import React from 'react';

export default ({ color = "#00A674", width = 24, height = 25 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
      <path d="M12.2917 2.46369C12.2762 2.46354 12.2607 2.46365 12.2452 2.46402C12.0003 2.46933 11.7615 2.54102 11.5548 2.67129C11.3482 2.80156 11.1815 2.98542 11.073 3.20289L8.63258 8.09937L3.17486 8.88556C2.92757 8.92122 2.69528 9.02463 2.50422 9.18411C2.31316 9.34359 2.17096 9.55278 2.09366 9.78806C2.01637 10.0233 2.00707 10.2753 2.06681 10.5156C2.12655 10.7558 2.25296 10.9747 2.43175 11.1476L6.38134 14.9592L5.449 20.3411C5.40682 20.5851 5.43443 20.8359 5.52871 21.0651C5.62299 21.2944 5.78018 21.493 5.98251 21.6384C6.18483 21.7839 6.42422 21.8704 6.67361 21.8881C6.923 21.9059 7.17244 21.8543 7.39373 21.739L12.2743 19.1979L17.1565 21.739C17.3778 21.8542 17.6272 21.9058 17.8766 21.8879C18.1259 21.8701 18.3652 21.7836 18.5675 21.6382C18.7698 21.4927 18.9269 21.2942 19.0212 21.065C19.1154 20.8358 19.1431 20.585 19.1009 20.3411L18.1686 14.9592L22.1182 11.1476C22.297 10.9747 22.4234 10.7558 22.4831 10.5156C22.5429 10.2753 22.5336 10.0233 22.4563 9.78805C22.379 9.55278 22.2368 9.34358 22.0457 9.18411C21.8547 9.02463 21.6224 8.92122 21.3751 8.88556L15.9173 8.09937L13.4769 3.20289C13.3674 2.98343 13.1988 2.79822 12.9896 2.66776C12.7804 2.5373 12.5388 2.46667 12.2916 2.46369H12.2917Z" fill={color}/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width={width} height={height} fill="white"/>
      </clipPath>
      </defs>
    </svg>

  );
}