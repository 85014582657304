import { handleError } from "qs-common/error";
import network from './network';
import CacheRequest from './cacheRequest';

const generalErrorMessage = 'Something went wrong.'


/* GET api call wrapper */

const getDataPromise = (endPoint, responseKey, cacheKey, query) => {
  return new Promise((resolve, reject) => {
    if (cacheKey) {
      const cachedData = CacheRequest.getCacheForKey(cacheKey);
      if (cachedData) {
        resolve(cachedData);
        return;
      }
    }
    network.getData(endPoint, query).then((apiResponse) => {
      if (apiResponse && apiResponse.data && cacheKey) {
        const data = !responseKey ? apiResponse.data : apiResponse.data[responseKey];
        CacheRequest.setCacheForKey(cacheKey, data);
      }
      handleResponse({apiResponse, resolve, reject, responseKey});
    }).catch(err => {
      handleError(err);
      reject(generalErrorMessage)
    })
  });
};

/* POST api call wrapper */

const postDataPromise = (endPoint, params, cacheKey) => {
  return new Promise((resolve, reject) => {
    network.postData(endPoint, params).then((apiResponse) => {
      if (cacheKey) {
        CacheRequest.deleteCacheForKeys([cacheKey]);
      }
      handleResponse({apiResponse, resolve, reject});
    }).catch(err => {
      handleError(err);
      reject(generalErrorMessage)
    })
  });
};

/* PUT api call wrapper */

const putDataPromise = (endPoint, params, cacheKey) => {
  return new Promise((resolve, reject) => {
    network.putData(endPoint, params).then((apiResponse) => {
      if (cacheKey) {
        CacheRequest.deleteCacheForKeys([cacheKey]);
      }
      handleResponse({apiResponse, resolve, reject});
    }).catch(err => {
      handleError(err);
      reject(generalErrorMessage)
    })
  });
};

/* DELETE api call wrapper */

const deleteDataPromise = (endPoint, params, cacheKey) => {
  return new Promise((resolve, reject) => {
    network.deleteData(endPoint, params).then((apiResponse) => {
      if (cacheKey) {
        CacheRequest.deleteCacheForKeys([cacheKey]);
      }
      handleResponse({apiResponse, resolve, reject});
    }).catch(err => {
      handleError(err);
      reject(generalErrorMessage)
    })
  });
};

/* Validate response and call back resolve/reject */

const handleResponse = ({apiResponse, resolve, reject, responseKey}) => {
  if (!apiResponse) {
    reject(generalErrorMessage)
  } else if (apiResponse.response) {
    handleError(apiResponse.response);
    reject(apiResponse.response.data.message) // Error object -> apiResponse.response
  } else {
    if (apiResponse.data) {
      if (responseKey) {
        resolve(apiResponse.data[responseKey]);
      } else {
        resolve(apiResponse.data);
      }
    } else {
      reject(generalErrorMessage)
    }
  }
}

export default {
  getDataPromise,
  postDataPromise,
  putDataPromise,
  deleteDataPromise,
  handleResponse
}