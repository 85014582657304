import React, {useRef} from "react";
import { withRouter } from "react-router-dom";
import {
  Toolbar,
  Box,
  IconButton,
  Typography,
  AppBar,
  TextField,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useAppContext } from "./Contexts/AppContext";
import { ReactComponent as EditIcon } from 'qs-assets/images/edit.svg';
import { goBack } from "../os";
import "./style.css";
import Ripples from "react-ripples";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const NewToolbar = withStyles({
  root: {
    minHeight: 64,
    height: 64,
  },
})(Toolbar);

function GenericNavBar(props) {
  const { history } = props;
  const { navBar } = useAppContext()[0];
  const {
    title = "",
    background = "#fff",
    color = "#000",
    boxShadow,
    borderBottom,
    hideBack,
    hide,
    actions,
    value,
    placeholder,
    onClick,
    readOnlyTextField
  } = navBar;
  const classes = useStyles();
  const bgStyle = boxShadow ? { background, boxShadow } : { background };
  const borderBottomStyle = borderBottom ? { borderBottom : borderBottom} : {};
  const clrStyle = { color };
  const actionStyle = actions
    ? { display: "flex", flexDirection: "row", justifyContent: "space-between" }
    : {};
  if (hide) return null;
  return (
    <AppBar style={{ ...bgStyle, ...clrStyle, ...actionStyle, ...borderBottomStyle }}>
      <NewToolbar>
        {!hideBack && (
          <IconButton
            onClick={() => goBack(history)}
            edge="start"
            className={`icon ${classes.menuButton}`}
            color="primary"
            aria-label="menu"
          >
            <ArrowBackIcon style={clrStyle} />
          </IconButton>
        )}
        {
          readOnlyTextField ?
            <div style={{display: 'flex', alignItems: 'flex-end'}} onClick={onClick}>
              <TextField
                value={value || ''}
                placeholder={placeholder}
                InputProps={{
                  readOnly: true
                }}
              />
              <div style={{marginLeft: '10px'}}>
                <Ripples onClick={onClick}>
                  <EditIcon fill="#FFF" width="16" height="16"/>
                </Ripples>
              </div>
            </div>
           :
            <Typography
              variant="h6"
              style={clrStyle}
              className={`heading ${classes.title}`}
              color="primary"
              noWrap
            >
              {title}
            </Typography>
        }
      </NewToolbar>
      {actions && <Box className="actions-box">{actions}</Box>}
    </AppBar>
  );
}

export default withRouter(GenericNavBar);
