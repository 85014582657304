import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { isNil } from 'lodash';
import ShippingData from "qs-data/shipping";
import Label from 'qs-common/CustomLabel';
import AddNewItem from 'qs-common/AddNewItem';
import CustomDivider from 'qs-common/CustomDivider';
import CustomCheckbox from 'qs-common/CustomCheckbox';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';
import ShippingModeDialog from '../ShippingModeDialog';
import { ReactComponent as EditIcon } from 'qs-assets/images/edit.svg';
import { ReactComponent as DeleteIcon } from 'qs-assets/images/trash.svg';
import "./style.scss";

function ShippingMode({ onUpdateShippingModes }) {

    const [shippingModes, onShippingModeUpdate] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [selectedShippingMode, setSelectedShippingMode] = useState(null);
    const [deleteMode, toggleDeleteMode] = useState(null);
    const [progressBar, setProgressBar] = useState(false);
    const [errorComponent, showErrorComponent] = useState(false);

    useEffect(() => {
        fetchShippingModes();
    }, []);

    useEffect(() => {
        onUpdateShippingModes(shippingModes);
    }, [shippingModes]);

    /* Fetch Shipping mode api call */
    const fetchShippingModes = async () => {
        setProgressBar(true);
        showErrorComponent(false);
        ShippingData.getShippingModes().then((data) => {
            onShippingModeUpdate(data);
        }).catch(() => {
            showErrorComponent(true);
        }).then(() => {
            setProgressBar(false)
        });
    }

    /* Update Shipping mode api call */
    const updateShippingMode = async (mode) => {
        ShippingData.updateShippingMode({
            "id": mode.id,
            "enabled": mode.enabled
        }).then(() => {
            setProgressBar(false)
        });
    }

    /* Shipping Mode update */
    const updateShippingModeCheckState = useCallback(
        e => {
            const newModes = Array.from(shippingModes);;
            const newMode = newModes.filter(obj => {
                return obj.value === e.target.name
            });
            newMode[0].enabled = e.target.checked;
            onShippingModeUpdate(newModes);
            updateShippingMode(newMode[0]);
        },
        [shippingModes]
    );

    const editShippingMode = (mode) => {
        setSelectedShippingMode(mode);
        setShowDialog(true)
    }

    const deleteShippingMode = (mode) => {
        setSelectedShippingMode(mode);
        toggleDeleteMode(true);
        setShowDialog(true)
    }

    const onSuccess = () => {
        setShowDialog(false);
        toggleDeleteMode(false);
        setSelectedShippingMode(null);
        fetchShippingModes();
    }

    const openDialog = () => {
        setShowDialog(true)
    }

    const onClose = () => {
        setShowDialog(false);
        toggleDeleteMode(false);
        setSelectedShippingMode(null);
    }

    const renderList = () => {
        if (progressBar) {
            return <CircularProgress size={24} color="primary" style={{ margin: '8px 0' }} />
        } else if (errorComponent) {
            return <CustomErrorComponent onRetryClick={fetchShippingModes} />
        } else {
            return shippingModes.map(function (item, index) {
                return <div className='singleShippingMode' key={index}>
                    <CustomCheckbox
                        key={index}
                        checked={isNil(item.enabled) ? false : item.enabled}
                        label={item.value}
                        handleChange={updateShippingModeCheckState}
                    />
                    <div className='actionItemsContainer'>
                        <div className='shippingModeEditIconContainer'>
                            <EditIcon fill="#FFF" width="20" height="20" onClick={() => editShippingMode(item)} />
                        </div>
                        <div className='shippingModeDeleteIconContainer'>
                            <DeleteIcon fill="#FFF" width="16" height="16" onClick={() => deleteShippingMode(item, true)} />
                        </div>
                    </div>
                </div>;
            })
        }
    }

    const renderAddNewShippingMode = () => {
        if (progressBar || errorComponent) {
            return null;
        }

        return <div>
            <CustomDivider />
            <AddNewItem text='Add Shipping mode' onClick={() => openDialog(false)} />
        </div>
    }

    return <div className='shippingItemContainer'>
        <Label value={'Shipping mode'} />
        {renderList()}
        {renderAddNewShippingMode()}
        {showDialog && <ShippingModeDialog
            selectedShippingMode={selectedShippingMode}
            showDialog={showDialog}
            onClose={onClose}
            onSuccess={onSuccess}
            deleteMode={deleteMode}
        />}
    </div>
}

export default withRouter(ShippingMode);