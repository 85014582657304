import cacheRequest from "qs-data/cacheRequest";
import checkoutFormFields from "qs-data/checkoutFormFields";
import { getCheckoutFormFieldsDataCacheKey } from "./helpers";
import {
  ADD_NEW_FORM_FIELDS_TO_LIST,
  FETCH_FORM_FIELD_DATA,
  FETCH_FORM_FIELD_DATA_FAILURE,
  FETCH_FORM_FIELD_DATA_SUCCESS,
  REMOVE_FORM_FIELD,
  SORT_FORM_FIELDS_DATA,
  TOGGLE_VISIBILITY_FORM_FIELD,
  UPDATE_FORM_FIELDS_DATA,
  UPDATE_FORM_FIELDS_DATA_FAILURE,
  UPDATE_FORM_FIELDS_DATA_SUCCESS
} from "./reducer";

export const fetchFormFieldComponents = (dispatchAction) => {
  dispatchAction({ type: FETCH_FORM_FIELD_DATA });
  const listener = (error, response) => {
    if (error) {
      dispatchAction({ type: FETCH_FORM_FIELD_DATA_FAILURE, error });
      return;
    }
    const { data } = response;
    if (data) {
      dispatchAction({ type: FETCH_FORM_FIELD_DATA_SUCCESS, data });
    }
  }
  cacheRequest.attachListener(getCheckoutFormFieldsDataCacheKey(), listener);
  cacheRequest.makeRequest(
    getCheckoutFormFieldsDataCacheKey(),
    checkoutFormFields.getCheckoutFields
  );
  return () => cacheRequest.removeListener(getCheckoutFormFieldsDataCacheKey(), listener);
};

export const addNewFormFieldComponent = (newFormField, formFields, dispatchAction, callbackFn) => {
  const { id, fieldName, fieldType, fieldOptions, required, visibility } = newFormField;

  const formFieldApiParams = { id: undefined, fieldName, fieldType, fieldOptions, required, visibility };

  let apiFunction = checkoutFormFields.addCheckoutField;
  let successMessage = 'Added new checkout field';
  
  const editingTheExistingField = !!formFields.find(({ id }) => (newFormField.id === id));
  // only call update api
  if (editingTheExistingField) {
    formFieldApiParams.id = id;
    apiFunction = checkoutFormFields.updateCheckoutField;
    successMessage = 'Updated the checkout field';
  }
  
  dispatchAction({ type: UPDATE_FORM_FIELDS_DATA });
  return apiFunction(formFieldApiParams)
    .then((response) => {
      const { insertedIds = [] } = response;
      // will handle the editing the existing field case inside the reducer;
      dispatchAction({ type: ADD_NEW_FORM_FIELDS_TO_LIST, insertedId: insertedIds[0], formField: newFormField });
      callbackFn(successMessage);
    })
    .catch(error => dispatchAction({ type: UPDATE_FORM_FIELDS_DATA_FAILURE, keepCurrentFormStateSame: true, error }));
};

export const updateFormFieldComponents = (formField, dispatchAction, showSnackbarMessage) => {
  const {
    id,
    fieldName,
    fieldType,
    fieldOptions,
    required,
    visibility
  } = formField;

  dispatchAction({ type: TOGGLE_VISIBILITY_FORM_FIELD, fieldId: id, formField, refreshing: true });
  return checkoutFormFields.updateCheckoutField({
      id,
      fieldName,
      fieldType,
      fieldOptions,
      required,
      visibility: !visibility
    })
    .then(() => {
      dispatchAction({ type: UPDATE_FORM_FIELDS_DATA_SUCCESS });
      showSnackbarMessage(`Checkout field marked as ${visibility ? 'hidden' : 'visible'}`);
    })
    .catch(() => dispatchAction({ type: TOGGLE_VISIBILITY_FORM_FIELD, fieldId: id, error: true, refreshing: false }));
};

export const deleteFormFieldComponent = ({ id: fieldId }, dispatchAction, showSnackbarMessage) => {
  dispatchAction({ type: UPDATE_FORM_FIELDS_DATA });
  return checkoutFormFields.deleteCheckoutField(fieldId)
    .then(() => {
      dispatchAction({ type: REMOVE_FORM_FIELD, fieldId });
      showSnackbarMessage('Deleted the checkout field');
    })
    .catch(error => dispatchAction({ type: UPDATE_FORM_FIELDS_DATA_FAILURE, error }))
};

export const changeOrderCheckoutFields = ({ fieldId, oldIndex, newIndex }, dispatchAction, showSnackbarMessage) => {
  if (newIndex === oldIndex) {
    // avoiding api call in case of moving to same the index
    return Promise.resolve();
  } 
  dispatchAction({ type: SORT_FORM_FIELDS_DATA, oldIndex, newIndex });
  return checkoutFormFields.reorderCheckoutFields(fieldId, newIndex)
    .then(() => {
      dispatchAction({ type: UPDATE_FORM_FIELDS_DATA_SUCCESS });
      showSnackbarMessage('Updated the order of the field');
    })
    .catch(error => dispatchAction({ type: SORT_FORM_FIELDS_DATA, oldIndex: newIndex, newIndex: oldIndex, error }));
};