import React, { useState } from 'react';
import { ArrowForward, Share } from '@material-ui/icons';
import { useHistory } from "react-router-dom";
import Toast from "qs-common/Alerts/Toast";
import { ReactComponent as ReferralImage } from 'qs-assets/images/refer.svg';
import ColoredButton from 'qs-common/ColoredButton';
import Utility from 'qs-helpers/utility';
import { shareReferral } from '../../../os';
import Analytics from "qs-data/analytics";
import './style.scss';

export default function Referral({ referralData }) {

    const history = useHistory();
    const [toastState, setToastState] = useState({
        open: false,
        message: "",
    });

    const trackShareReferralEvent = async (event) => {
        Analytics.trackEvent({eventName: event});
    }

    const navigateToReferralList = () => {
        trackShareReferralEvent('track_referral');
        history.push({
            pathname: '/referral/track'
        });
    }

    const copyReferralCodeToClipboard = () => {
        trackShareReferralEvent('copied_referral_code');
        Utility.copyToClipboard(referralData.referralCode);
        setToastState({
            open: true,
            message: "Referral code copied to clipboard.",
        });
    }

    const shareReferralCode = () => {
        trackShareReferralEvent('share_referral_code');
        const referralContent = `Hey! \n\nQuickSell is a fast, simple and secure app that I use to share my catalogue and product list with my customers. \n\nGet it from https://quicksell.co/refer/${referralData.referralCode} and use my referral code ${referralData.referralCode} to start your free subscription.`;
        shareReferral(referralContent);
    }

    const buttons = [
        {
            primaryColor: '#5D6EC2',
            secondaryColor: '#47529C',
            primaryContent: <span className="primaryButtonText textWhite">Share invite link</span>,
            secondaryContent: <span className="secondaryButtonText"><Share /></span>,
            onClick: shareReferralCode
        },
        {
            primaryColor: '#000000',
            secondaryColor: '#292929',
            primaryContent: <div className="code"><span className="secondaryButtonText">Referral code</span><span className="primaryButtonText textWhite">{referralData.referralCode}</span></div>,
            secondaryContent: <span className="secondaryButtonText">COPY</span>,
            onClick: copyReferralCodeToClipboard
        },
        {
            primaryColor: '#ffffff',
            secondaryColor: '#ABB6BD',
            primaryContent: <span className="primaryButtonText">Track referrals</span>,
            secondaryContent: <span className="secondaryButtonText"><ArrowForward /></span>,
            onClick: navigateToReferralList
        }
    ];

    return <div className="referralHome">
        <div className='contentContainer'>
            <div className="content">
                <ReferralImage className="referralImage" />
                <p className="main">Refer and grow</p>
                <p className="info">Get <span>{referralData.referralOfferShortText}</span> for every referral</p>
            </div>
        </div>
        <div className="actions">
            {buttons.map((btn, i) => (<ColoredButton key={i} {...btn} />))}
        </div>
        <Toast
            open={toastState.open}
            message={toastState.message}
            onClose={() => {
                setToastState({
                    open: false,
                    message: "",
                });
            }}
        />
    </div>
}
