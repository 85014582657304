import React, { Fragment } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemIcon,
  Divider,
} from "@material-ui/core";
import { ReactComponent as BinSvg } from "../../assets/images/bin.svg";
import { ReactComponent as EditSvg } from "../../assets/images/edit.svg";
import { ReactComponent as LockSvg } from "../../assets/images/lock.svg";
import useStyles from "./styles.js";

export default function FieldsList(props) {
  const { fields = [], onEdit, onDelete } = props;
  const classes = useStyles();
  return (
    <List>
      {Array.isArray(fields) &&
        fields.map(
          ({ fieldName: field, fieldType: type, visibility, id }, index) => (
            <Fragment key={id}>
              <ListItem onClick={() => onEdit(index)} className={classes.listItem}>
                <Box>
                  <p className={`field-text ${classes.listText}`}>{field}</p>
                  <div className="type-box">
                    <p className={`field-type ${classes.listSubText}`}>{type}</p>
                    <Box
                      className={`${classes.visibility} ${classes[visibility]} list-mob-visibility`}
                    >
                      {visibility === "PRIVATE" && (
                        <LockSvg
                          width="12.20"
                          height="16"
                          className={classes.image}
                        />
                      )}
                      {visibility}
                    </Box>
                  </div>
                </Box>
                <ListItemSecondaryAction
                  className={`${classes.listActions} list-actions`}
                >
                  <Box
                    className={`${classes.visibility} ${classes[visibility]} list-visibility`}
                  >
                    {visibility === "PRIVATE" && (
                      <LockSvg
                        width="12.20"
                        height="16"
                        className={classes.image}
                      />
                    )}
                    {visibility}
                  </Box>
                  <ListItemIcon className={classes.listButton}>
                    <EditSvg
                      width="22"
                      height="22"
                      onClick={() => onEdit(index)}
                      fill="#9CA0AC"
                      className={classes.image}
                    />
                  </ListItemIcon>
                  <ListItemIcon className={classes.listButton}>
                  <BinSvg
                    width="22"
                    height="22"
                    onClick={() => onDelete(id)}
                    fill="#9CA0AC"
                    className={classes.image}
                  />
                </ListItemIcon>
                </ListItemSecondaryAction>
              </ListItem>
              {index < fields.length - 1 && <Divider className={classes.divider} />}
            </Fragment>
          )
        )}
    </List>
  );
}
