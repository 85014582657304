import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Switch from '@material-ui/core/Switch';
import payments from '../../data/payments';
import Loader from '../../common/Loader'
import Toast from '../../common/Alerts/Toast';
import {getToken} from "../../data/util";
import Icon from '../../common/Icon';


const useStyles = makeStyles({
    root: {
        minWidth: 275,
        backgroundColor: '#252B36',
        marginLeft: 15,
        marginRight: 15,
        marginTop: 15,
        color: '#fff'
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'coloumn',
        "&:last-child": {
            paddingBottom: 16
        }
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    }
});

const CustomSwitch = withStyles({
    switchBase: {
        color: '#949EAE',
        '&$checked': {
            color: '#3E925C',
        },
        '&$checked + $track': {
            backgroundColor: '#2F4B30',
        },
    },
    checked: {},
    track: {},
})(Switch);

function PaymentRow({ paymentMode = {}, source = 'mobile' }) {
    const classes = useStyles();
    const [isActive, setActive] = useState(paymentMode && paymentMode.isActive || false);
    const [loading, setLoading] = useState(false);
    const [toastState, setToastState] = useState({
        open: false,
        message: ''
    });


    const toggleChange = (e) => {
        if (paymentMode.id === 'CASH_ON_DELIVERY') {
            setLoading(true);
            let activate = !isActive;
            if (activate) {
                payments.enableCOD().then(() => {
                    setActive(true);
                    setLoading(false);
                    setToastState({
                        open: true,
                        message: 'COD Enabled'
                    })
                })
            } else {
                payments.disabledCOD().then(() => {
                    setActive(false);
                    setLoading(false);
                    setToastState({
                        open: true,
                        message: 'COD Disabled'
                    })
                })
            }
        }
    };

    const { url } = useRouteMatch();

    const properTitle = (paymentMode.title || '').split('/').map((val) => val.trim()).join('/ ');

    return (
        <>
            <Card className={`paymentRow ${classes.root}`} elevation={3} onClick={toggleChange}>

                <ConditionalLink
                    condition={!['CASH_ON_DELIVERY'].includes(paymentMode.id)}
                    link={`${url}/${paymentMode.id}?token=${getToken()}&source=${source}`}
                >
                    <CardContent className={classes.content}>
                        <div>
                            <Icon className="PrimaryIcon" id={paymentMode.id}/>
                        </div>
                        <div className="paymentName">
                            {properTitle}
                            {paymentMode.subtitle ? <div className="SubTitleContainer">
                                {paymentMode.subtitleIcon ? <img className="Icon" src={paymentMode.subtitleIcon} /> : null}
                                <span>{paymentMode.subtitle}</span>
                            </div> : null}
                        </div>
                        {loading ? <div className="RowLoaderContainer"><Loader small /></div> : <CustomSwitch
                            checked={isActive}
                            name="checkedB"
                            onChange={toggleChange}
                            inputProps={{ 'aria-label': 'primary checkbox' }} />}

                    </CardContent>
                </ConditionalLink>
            </Card>
            <Toast
                message={toastState.message}
                open={toastState.open}
                onClose={() => {
                    setToastState({
                        open: false,
                        message: ''
                    })
                }}
            />
        </>

    )
}

const ConditionalLink = ({ condition, link, children }) =>
    condition ? <Link to={link}>{children}</Link> : children;

export default PaymentRow;
