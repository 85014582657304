class Native {
    goBack (){}
    seatReserved(startTime){}
    setNavigationBarColor(color){}
    setStatusBarColor(color){}
    isFeatureAllowed(feature){}
    showPremiumFeatureDialog(feature){}
    shareReferral () {}
    trackAnalytics () {}
}

export default Native;

