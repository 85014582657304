import Native from './shared';

const Android = window.Android || {};

class AndroidImpl extends Native{
    constructor(){
        super();
    }

    goBack () {
        if(Android.goBack) {
            Android.goBack();
        } else {
            super.goBack();
        }
    }

    seatReserved(webinarName, startTime){
        if(Android.seatReserved){
            Android.seatReserved(webinarName, startTime);
        }
    }

    setNavigationBarColor(color){
        if(Android.setNavigationBarColor){
            Android.setNavigationBarColor(color);
        }
    }

    shareIntent(url, content, contentType) {
        if (Android.shareIntent) {
            Android.shareIntent(url, content, contentType);
        }
    }

    setStatusBarColor(color) {
        if(Android.setStatusBarColor){
            Android.setStatusBarColor(color);
        }
    }

    isFeatureAllowed(feature) {
        if (Android.isFeatureAllowed){
            return Android.isFeatureAllowed(feature);
        }
        return true;
    }

    showPremiumFeatureDialog(feature) {
        if (Android.showPremiumFeatureDialog){
            Android.showPremiumFeatureDialog(feature);
        }
    }

    shareReferral (content) {
        this.shareIntent(null, content, 'text/plain');
    }

    trackAnalytics (params) {
        if(Android.sendEvent){
            Android.sendEvent(params.eventName, JSON.stringify(params.props));
        }
    }
}

export default AndroidImpl;