import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";
import "./style.scss";

import PersonalizedThemeData from "qs-data/personalizedTheme";
import { ReactComponent as EditIcon } from 'qs-assets/images/edit.svg';
import CacheRequest from "qs-data/cacheRequest";
import cacheKeys from "qs-data/cacheKeys";
import Ripples from "react-ripples";
import Toast from "qs-common/Alerts/Toast";
import {CUSTOM_THEME_FEATURE} from "qs-helpers/utility";
import {isFeatureAllowed, showPremiumFeatureDialog, trackAnalytics} from "../../../os";

const SingleThemeContainer = ({ theme, hideSetThemeIcon, onSelectedThemeChanged, customStyle }) => {

  const history = useHistory();
  const colors = [theme.addToCartButton.color, theme.addToCartButton.textColor, theme.homeGrid.backgroundColor, theme.pageTextColor]
  const [progressBar, setProgressBar] = useState(false);
  const isEditable = theme.id !== 'light' && theme.id !== 'dark';
  const [toastState, setToastState] = useState({open: false, message: ""});

  const editTheme = (themeId) => {
    CacheRequest.setCacheForKey(cacheKeys.selectedThemeId, themeId);
    setTimeout(() => {
      history.push({
        pathname: '/theme-update'
      });
    }, 100)
  }

  const updateSelectedTheme = (e, themeId) => {
    e.stopPropagation();
    if (isFeatureAllowed(CUSTOM_THEME_FEATURE)) {
      setProgressBar(true);
      PersonalizedThemeData.setSelectedThemeIdForCompany(themeId).then(() => {
        trackAnalytics({
          eventName: 'theme_set',
          props: {
            theme_id: themeId
          }
        });
        onSelectedThemeChanged();
      }).catch((ex) => {
        console.log("Error caught while updating selected theme", ex);
      }).then(() => {
        setTimeout(() => {
          setProgressBar(false);
        }, 1000)
      });
    } else {
      showPremiumFeatureDialog(CUSTOM_THEME_FEATURE);
    }
  }

  const renderColors = () => {
    return <div className='colorsContainer'>
      {
        colors.map((color, i) => {
          return <span className='singleColor' key={`${color}-${i}`} style={{backgroundColor: color}}/>;
        })
      }
    </div>
  }

  const editSingleTheme = (themeId) => {
    if (isEditable) {
      editTheme(themeId)
    } else {
      setToastState({
        open: true,
        message: "QuickSell Basic themes can't be edited",
      });
    }
  }

  return <div className='singleThemeContainer' key={theme.id} >
      <div className='container' style={customStyle}>
        <Ripples className='rippleContainer' onClick={() => editSingleTheme(theme.id)}>
        <div>
          <p className='title'>{theme.name}</p>
          {renderColors()}
        </div>
        <div className='actionItemsContainer'>
          {
            !progressBar && isEditable ? <div className='themeEditIconContainer'>
              <EditIcon fill="#FFF" width="20" height="20" />
            </div> : null
          }
          {
            !hideSetThemeIcon && (
              progressBar ? <div className='leftContainer'> <CircularProgress size={20} color="primary" /></div> :
                theme.isSelected ? <span className='setThemeButton selectedThemeButton'>SELECTED</span> :
                  <span className='setThemeButton' onClick={(e) => updateSelectedTheme(e, theme.id)}>APPLY THEME</span>
            )
          }
        </div>
        </Ripples>
      </div>
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({open: false, message: ""});
        }}
      />
  </div>
}

export default SingleThemeContainer;
