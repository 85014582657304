import React, { useEffect, useState} from 'react';
import Slider from 'react-rangeslider';
import {TextField} from "@material-ui/core";
import colorsys from "colorsys";
import "./style.scss";
import "./range.scss";
import Utility from "qs-helpers/utility";

export default function ({ selectedColorPicker, onColorChanged }) {
  const hslValue = colorsys.hex2Hsl(selectedColorPicker.value);
  const [hexValue, setHexValue] = useState(selectedColorPicker.value);
  const [hue, setHue] = useState(hslValue.h);
  const [saturation, setSaturation] = useState(hslValue.s);
  const [light, setLight] = useState(hslValue.l);

  useEffect(() => {
    const hex = colorsys.hsl2Hex(hue, saturation, light);
    updateHexInputIfHslDifferent(hex);
    const sliders = document.getElementsByClassName('rangeslider__handle');
    for (let i = 0; i < sliders.length; i++) {
      sliders.item(i).style.background = hex;
    }

    const saturationSlider = document.getElementsByClassName('sat');
    if (saturationSlider) {
      for (let i = 0; i < saturationSlider.length; i++) {
        saturationSlider.item(i).style.backgroundImage = `linear-gradient(to top,
          hsl(${hue}, 10%, 60%),
          hsl(${hue}, 20%, 60%),
          hsl(${hue}, 30%, 60%),
          hsl(${hue}, 40%, 60%),
          hsl(${hue}, 50%, 60%),
          hsl(${hue}, 60%, 60%),
          hsl(${hue}, 70%, 60%),
          hsl(${hue}, 80%, 60%),
          hsl(${hue}, 90%, 60%),
          hsl(${hue}, 100%, 60%)
        )`;
      }
    }

    const lightnessSlider = document.getElementsByClassName('light');
    if (lightnessSlider) {
      for (let i = 0; i < lightnessSlider.length; i++) {
        lightnessSlider.item(i).style.backgroundImage = `linear-gradient(to top,
          hsl(${hue}, 100%, 10%),
          hsl(${hue}, 100%, 20%),
          hsl(${hue}, 100%, 30%),
          hsl(${hue}, 100%, 40%),
          hsl(${hue}, 100%, 50%),
          hsl(${hue}, 100%, 60%),
          hsl(${hue}, 100%, 70%),
          hsl(${hue}, 100%, 80%),
          hsl(${hue}, 100%, 90%),
          hsl(${hue}, 100%, 100%)
        )`;
      }
    }
  }, [hue, saturation, light])

  /* Since 2 colors can have same hsl, eg : #cecece and #cfcfcf, so update hex value only if it's different */
  const updateHexInputIfHslDifferent = (hex) => {
    const hslOld = colorsys.hex2Hsl(hexValue);
    const hslNew = colorsys.hex2Hsl(hex);
    if (hslOld.h !== hslNew.h || hslOld.s !== hslNew.s || hslOld.l !== hslNew.l) {
      setHexValue(hex)
      onColorChanged(hex);
    } else {
      setHexValue(hexValue)
      onColorChanged(hexValue);
    }
  }

  const onHexInputChange = (e) => {
    const val = e.target.value;
    val.length === 0 ? setHexValue('#') : setHexValue(val);
    if (Utility.isValidHex(val)) {
      const hsl = colorsys.hex2Hsl(val);
      setHue(hsl.h);
      setSaturation(hsl.s);
      setLight(hsl.l);
    }
  }

  const colorPickerSlider = (title, containerClassName, minValue, maxValue, value, updateFunc) => {
    return <div className={`singleContainer`}>
      <p className='title'>{title}</p>
      <div className={`sliderContainer ${containerClassName}`}>
        <Slider
          value={value}
          orientation="vertical"
          onChange={(value) => updateFunc(value)}
          min={minValue}
          max={maxValue}
          step={1}
          tooltip={false}
        />
      </div>
    </div>
  }

  return <div className="hslMainContainer">
    <div className='topSection'>
      <div className='colorPreview' style={{backgroundColor: `hsl(${hue}, ${saturation}%, ${light}%)`}}/>
      <TextField
        className={'colorHex'}
        inputProps={{style: { textAlign: 'center' }}}
        value={hexValue}
        InputProps={{ disableUnderline: true }}
        onChange={onHexInputChange}
      />
    </div>
    <div className="colorPickerContainer">
      { colorPickerSlider('H', 'hue', 1, 360, hue, setHue) }
      { colorPickerSlider('S', 'sat', 1, 100, saturation, setSaturation) }
      { colorPickerSlider('L', 'light', 1, 100, light, setLight) }
    </div>
  </div>
}
