import CacheKeys from './cacheKeys';
import ApiWrapper from './apiWrapper';

let apis = {
  allCataloguesEndPoint: '/v1/catalogue/allCatalogues',
  companyThemeEndPoint: '/v1/catalogue-theme/company-themes',
  selectedThemeEndPoint: '/v1/experiment/selected-theme',
  themeEndPoint: '/v1/catalogue-theme/theme'
};

/* Theme fields get and set */

const getAllCatalogues = () => {
  return ApiWrapper.getDataPromise(apis.allCataloguesEndPoint, 'catalogues', null);
};

const getAllThemesForCompany = () => {
  return ApiWrapper.getDataPromise(apis.companyThemeEndPoint, 'themes', CacheKeys.allThemes);
};

const setSelectedThemeIdForCompany = (themeId) => {
  return ApiWrapper.postDataPromise(apis.selectedThemeEndPoint, { themeId }, CacheKeys.allThemes);
};

const addTheme = (theme) => {
  return ApiWrapper.postDataPromise(apis.themeEndPoint, { theme }, CacheKeys.allThemes);
};

const updateTheme = (theme) => {
  return ApiWrapper.putDataPromise(apis.themeEndPoint, { theme }, CacheKeys.allThemes);
};

const deleteTheme = (themeId) => {
  return ApiWrapper.deleteDataPromise(`${apis.themeEndPoint}?id=${themeId}`, { themeId }, CacheKeys.allThemes);
};

const getTheme = (themeId) => {
  return ApiWrapper.getDataPromise(`${apis.themeEndPoint}?id=${themeId}`, 'theme');
};

export default {
  getAllCatalogues,
  getAllThemesForCompany,
  setSelectedThemeIdForCompany,
  addTheme,
  updateTheme,
  deleteTheme,
  getTheme
}