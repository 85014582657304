import React, { useState, useEffect, useCallback, Fragment } from "react";
import {
  Typography,
  Button,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import * as Sentry from "@sentry/browser";
import FieldsList from "./fieldsList";
import { useAppContext } from "../../common/Contexts/AppContext";
import CustomFieldDialogBox from "../../common/CustomFieldDialogBox";
import Loader from "../../common/Loader";
import useQuery from "../../common/Hooks/useQuery";
import { ReactComponent as EditSvg } from "../../assets/images/edit.svg";
import useStyles from "./styles";
import "./style.css";
import customFieldData from "qs-data/customFields"
import Toast from "../../common/Alerts/Toast";

const FIELD_TYPES = ["STRING", "NUMBER", "BOOLEAN"];

const DLG_BUTTONS = ["Private", "Public"];

const ROW_HEIGHT = 80;
const HEADER_HEIGHT = 64;

let lastAddedValue = "";

export default function CustomFields(props) {
  const classes = useStyles();
  const [showAddDlg, setShowAddDlg] = useState(false);
  const [dlgText, setDlgText] = useState("");
  const [dlgType, setDlgType] = useState("");
  const [btnSelected, setBtnSelected] = useState("");
  const [textErr, setTxtErr] = useState(false);
  const [typeErr, setTypeErr] = useState(false);
  const [btnErr, setBtnErr] = useState(false);
  const [allFields, setAllFields] = useState([]);
  const [loadingFields, setLoadingFields] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [showErrorDlg, setShowErrorDlg] = useState({});
  const [toastState, setToastState] = useState({
    open: false,
    message: "",
  });
  const [updatingFields, setUpdatingFields] = useState(false);
  const desktop = useQuery().get("source") === "desktop";
  const dispatch = useAppContext()[1];
  const { history } = props;
  const {
    location: { hash, search },
  } = history;

  const getFields = useCallback(() => {
    const callApi = async () => {
      try {
        const fields = await customFieldData.getProductFields();
        setAllFields(fields);
        if (lastAddedValue !== "") {
          const index = fields.findIndex(
            ({ fieldName }, i) => fieldName === lastAddedValue
          );
          const scrollHeight = HEADER_HEIGHT + index * ROW_HEIGHT;
          if (
            scrollHeight > window.innerHeight &&
            (scrollHeight < window.scrollY - window.innerHeight ||
              scrollHeight > window.scrollY + window.innerHeight) &&
            lastAddedValue !== ""
          ) {
            window.scrollTo(0, scrollHeight);
          }
        }
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        toggleErrorDlg("Failed to get fields", e.message);
      }
      setLoadingFields(false);
    };
    callApi();
  }, []);

  const addField = useCallback(
    (data) => {
      setUpdatingFields(true);
      const callApi = async () => {
        try {
          await customFieldData.addProductField(data);
          setToastState({
            open: true,
            message: "Field saved successfully",
          });
          setUpdatingFields(false);
        } catch (e) {
          setUpdatingFields(false);
          console.error(e);
          Sentry.captureException(e);
          toggleErrorDlg("Failed to add field", e);
        }
        getFields();
      };
      callApi();
    },
    [getFields]
  );

  const deleteField = async (id) => {
    try {
      await customFieldData.deleteProductField({ fieldIds: [id] });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      toggleErrorDlg("Failed to delete field", e);
    }
    getFields();
  };

  const updateField = useCallback(
    (data) => {
      setUpdatingFields(true);
      const callApi = async () => {
        try {
          await customFieldData.updateProductField(data);
          setToastState({
            open: true,
            message: "Field saved successfully",
          });
          setUpdatingFields(false);
        } catch (e) {
          setUpdatingFields(false);
          console.error(e);
          Sentry.captureException(e);
          toggleErrorDlg("Failed to update field", e);
        }
        getFields();
      };
      callApi();
    },
    [getFields]
  );

  useEffect(() => {
    setLoadingFields(true);
    getFields();
  }, [getFields]);

  useEffect(() => {
    if (hash === "#active") {
      setShowAddDlg(true);
    } else {
      setShowAddDlg(false);
    }
  }, [hash]);

  const toggleAddDialog = useCallback(() => {
    if (hash === "#active") {
      setDialogData();
      history.goBack();
    } else {
      history.push(`${search}#active`);
    }
  }, [hash, history, search]);

  const onAddField = () => {
    setEditIndex(-1);
    toggleAddDialog();
  };

  const handleFieldText = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setDlgText(value);
  }, []);

  const handleSelect = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setDlgType(value);
  }, []);

  const setDialogData = (data = {}) => {
    const {
      fieldName = "",
      fieldType = "",
      visibility = "",
      textErr = false,
      typeErr = false,
      btnErr = false,
    } = data;
    setDlgText(fieldName);
    setDlgType(fieldType);
    setBtnSelected(visibility);
    setTxtErr(textErr);
    setTypeErr(typeErr);
    setBtnErr(btnErr);
  };

  const onDlgAddField = useCallback(() => {
    let isError = false;
    if (dlgType.length === 0) {
      setTypeErr(true);
      isError = true;
    }
    if (dlgText.length === 0) {
      setTxtErr(true);
      isError = true;
    }
    if (btnSelected.length === 0) {
      setBtnErr(true);
      isError = true;
    }
    if (!isError) {
      const newField = {
        fieldName: dlgText.trim(),
        fieldType: dlgType,
        visibility: btnSelected.toUpperCase(),
      };
      lastAddedValue = dlgText;
      if (editIndex > -1) {
        const { id } = allFields[editIndex];
        if (id) {
          const updatedField = { fieldId: id, updates: { ...newField } };
          updateField(updatedField);
        }
      } else {
        addField(newField);
      }
      setEditIndex(-1);
      setDialogData();
      toggleAddDialog();
    }
  }, [
    dlgType,
    dlgText,
    btnSelected,
    allFields,
    editIndex,
    addField,
    updateField,
    toggleAddDialog,
  ]);

  const handleBtnClick = useCallback((btn) => {
    setBtnErr(false);
    setBtnSelected(btn);
  }, []);

  const onListEdit = (index) => {
    const data = index < allFields.length ? allFields[index] : {};
    setEditIndex(index);
    setDialogData(data);
    toggleAddDialog();
  };

  const onListDelete = (id) => {
    deleteField(id);
  };

  const addFieldButton = (
    <Button
      variant="contained"
      onClick={onAddField}
      className={`${classes.navBtn} btn-top ${!desktop ? "d-none" : ""}`}
    >
      Add field
    </Button>
  );

  useEffect(() => {
    dispatch({
      type: "UPDATE_NAVBAR",
      navBar: {
        background: desktop ? "#0f141a" : "#2b313c",
        color: "#FFFFFF",
        title: "Custom fields",
        hideBack: desktop,
        actions: addFieldButton,
      },
    });
  }, [dispatch, desktop]);

  const toggleErrorDlg = useCallback(
    (type, error) => {
      if (Object.keys(showErrorDlg).length > 0) {
        setShowErrorDlg({});
      } else {
        setShowErrorDlg({
          type,
          error,
        });
      }
    },
    [showErrorDlg]
  );

  const onContactUs = () => {
    window.open(
      `mailto:support@quicksell.co?subject=${showErrorDlg.type}&body=${showErrorDlg.error}.`
    );
    toggleErrorDlg();
  };

  return (
    <Box
      className={`${classes.customFields} ${
        allFields.length === 0 && "customfields-mainbox"
      }`}
    >
      <div className={`${classes.titleContainer} ${ allFields.length === 0 ? classes.noFieldsTitle : ''}`}>
        <div className={classes.titleInfo}>
          {/* <Typography className={classes.title} color="primary" gutterBottom>
            Custom fields
          </Typography> */}
          <p
            className={`main-info ${classes.info} ${
              allFields.length === 0 && "title-info"
            } ${loadingFields && "loading-fields"}`}
            gutterBottom
          >
            Create custom fields that you can specify for each product
          </p>
        </div>
      </div>
      {loadingFields ? (
        <Box className="custom-field loader">
          <Loader style={{ marginTop: 10, marginBottom: 10 }} />
        </Box>
      ) : (
        <Box>
          <FieldsList
            fields={allFields}
            onEdit={onListEdit}
            onDelete={onListDelete}
          />
        </Box>
      )}
      <Box className="button-box">
        {allFields.length === 0 ? (
          <Button
            onClick={onAddField}
            className={`no-fields-box ${classes.newFieldBtn} ${
              desktop ? "d-none" : ""
            } btn-bottom`}
          >
            <EditSvg fill="#FFF" width="16" height="16" className="edit-img" />
            <Typography className="no-fields">Create field</Typography>
          </Button>
        ) : (
          <IconButton
            className={`${classes.newFieldBtn} ${desktop ? "d-none" : ""} ${
              classes.plusSvg
            } btn-bottom`}
            onClick={onAddField}
          >
            +
          </IconButton>
        )}
      </Box>
      <CustomFieldDialogBox
        show={showAddDlg || updatingFields}
        width={updatingFields ? 95 : 313}
        height={updatingFields ? 95 : 432}
        dark
        primaryBtnText={editIndex === -1 ? "ADD FIELD" : "SAVE FIELD"}
        title="Add new field"
        onClose={toggleAddDialog}
        onSuccess={onDlgAddField}
        disableBackdropClick
        type={updatingFields ? "simple" : ""}
      >
        {updatingFields ? (
        <Box className="custom-field loader">
          <Loader />
        </Box>
        ) : (
        <Fragment>
          <TextField
            className={classes.newField}
            error={textErr}
            onChange={handleFieldText}
            value={dlgText}
            label="Field name"
            fullWidth
            autoFocus
          />
          <FormControl
            variant="outlined"
            className={classes.formControl}
            error={typeErr}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Field type
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={dlgType}
              onChange={handleSelect}
              label="Field type"
              disabled={editIndex !== -1}
            >
              {FIELD_TYPES.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography className={classes.txtVisibility}>
            Field visibility
          </Typography>
          <Typography className={classes.txtControl}>
            Control whether this field will be publically visible to customers
          </Typography>
          {DLG_BUTTONS.map((btn) => (
            <Button
              key={btn}
              className={`${classes.dlgButton} ${
                btnSelected.toLowerCase() === btn.toLowerCase()
                  ? classes.selected
                  : ""
              }`}
              variant={
                btnSelected.toLowerCase() === btn.toLowerCase()
                  ? "contained"
                  : "outlined"
              }
              onClick={() => handleBtnClick(btn)}
              error={btnErr}
            >
              {btn}
            </Button>
          ))}
          {btnErr && (
            <Typography className={classes.btnError}>
              Please select a visibility setting
            </Typography>
          )}
        </Fragment>
        )}
      </CustomFieldDialogBox>
      <CustomFieldDialogBox
        show={Object.keys(showErrorDlg).length > 0}
        dark
        title={showErrorDlg.type}
        primaryBtnText="Contact us"
        disableBackdropClick
        onSuccess={onContactUs}
        onClose={toggleErrorDlg}
      >
        <p className="error-info main">{`${showErrorDlg.error}`}</p>
        <p className="error-info">
          Please contact support for further assistance.
        </p>
      </CustomFieldDialogBox>
      <Toast
        open={toastState.open}
        message={toastState.message}
        onClose={() => {
          setToastState({
            open: false,
            message: "",
          });
        }}
      />
    </Box>
  );
}
