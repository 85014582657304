import React, { useRef } from 'react';
import rt from 'remaining-time';

function ListCountDownTime({ webinarTime }) {
  const timeObjRef = useRef(rt.date(webinarTime));

  const getDataToShow = () => {
    if (new Date() > new Date(webinarTime)) {
      return 'Live now';
    }

    const timeObj = timeObjRef.current;

    if (timeObj.day >= 2) {
      return timeObj.day + ' days from now';
    }

    if (timeObj.day > 0 || timeObj.hour > 0) {
      const numberOfHours = 24 * timeObj.day + timeObj.hour;
      return numberOfHours + (numberOfHours === 1 ? ' hour' : ' hours') + ' remaining';
    }

    if (timeObj.minute > 0) {
      return timeObj.minute + ' minutes remaining';
    }
  };

  return <div className='ListCountDownTime'>{getDataToShow()}</div>;
}

export default ListCountDownTime;
