import React, { Fragment, useState, useEffect } from 'react';
import { ArrowForward } from '@material-ui/icons';
import { CircularProgress } from "@material-ui/core";
import ReferralData from "qs-data/referral";
import './style.scss';
import Utility from 'qs-helpers/utility';
import ReferralRedeemedDialog from '../ReferralRedeemedDialog';
import CustomErrorComponent from 'qs-common/CustomErrorComponent';

export default function ReferralCard({ data, onRedeemSuccess }) {

    const [progressBar, setProgressBar] = useState(false);
    const [showDialog, toggleShowDialog] = useState(false);
    const [referralReward, setReferralReward] = useState({});
    const [errorComponent, showErrorComponent] = useState(false);

    const { name, email, status, userId, redeemedOn } = data;

    useEffect(() => {
        if (status === 'REDEEMABLE') {
            fetchRewardContent();
        }
    }, []);

    const fetchRewardContent = async () => {
        setProgressBar(true);
        showErrorComponent(false);
        ReferralData.getReferralReward(userId).then((data) => {
            setReferralReward(data);
        }).catch(() => {
            showErrorComponent(true);
        }).then(() => {
            setProgressBar(false);
        });
    }

    const redeemReferralCode = async (friendUserId) => {
        setProgressBar(true);
        ReferralData.redeemReferralCoupon({ 'friendUserId': friendUserId }).then((data) => {
            toggleShowDialog(true);
        }).catch((e) => {
            console.log('error while redeeming coupon', e)
        }).then(() => {
            setProgressBar(false);
        });
    }

    const getStatusContent = (status) => {
        switch (status) {
            case 'PAYMENT_PENDING':
                return 'Friend joined, Not yet paid';
            case 'REDEEMABLE':
                return `Redeem ${referralReward.duration} days free`;
            case 'REDEEMED':
                const formattedDate = Utility.getFormattedDate(new Date(redeemedOn));
                return 'Redeemed on ' + formattedDate;
            default:
                return ''
        }
    }

    const onClose = () => {
        toggleShowDialog(false);
        onRedeemSuccess();
    }

    const isRedeemable = status === 'REDEEMABLE';

    return (
        <Fragment>
            <div className="ref-card" onClick={isRedeemable ? () => redeemReferralCode(userId) : null}>
                <div className="name">{name}</div>
                <div className="email">{email}</div>
                {
                    progressBar ? <CircularProgress size={24} color="inherit" /> :
                        <div className='redeemStatus' >
                            {
                                errorComponent ? <CustomErrorComponent onRetryClick={fetchRewardContent} /> :
                                <span className={`status ${status}`}>{getStatusContent(status)}</span>
                            }
                            {isRedeemable && <span className={status}><ArrowForward className={`redeemArrow ${status}`}/></span>}
                        </div>
                }
            </div>
            <ReferralRedeemedDialog 
                showDialog={showDialog}
                onClose={onClose}
                successMessage={`You have redeemed ${referralReward.duration} days free of QuickSell ${referralReward.planType} for your referral. Continue using QuickSell and grow your business`}
            />
        </Fragment>
    );
}
