import eventbus from 'eventing-bus';
import * as Sentry from '@sentry/browser';

// TODO: importing it causes a circular dependency through 4 5 files, to be solved later
// CacheReq => DexieInit => Rehydration => CacheReq
// import { NATIVE_STORAGE_EVENT_BUS_KEY } from '../DexieInit';
const NATIVE_STORAGE_EVENT_BUS_KEY = 'NATIVE_STORAGE_EVENT_BUS_KEY';

const OPERATION_STATUS = {
  LOADING: 'LOADING',
  REFRESHING: 'REFRESHING',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  UPDATE: 'UPDATE'
};

let responseCache = {};
let promises = {};
let listeners = {};

const reset = () => {
  responseCache = {};
  promises = {};
  listeners = {};
};

const notifyListeners = (key, err, payload) => {
  if (!Array.isArray(listeners[key])) {
    return;
  }

  /*
    Copy the original listener array. If a listener executes its removal then
    the current array on which iteration is ongoing will be modified and
    unexpected results may show up. Hence take a copy and iterate on it
  */
  const copyListenersList = [...listeners[key]];
  copyListenersList.forEach(listener => {
    listener(err, payload);
  });
};

const attachListener = (key, listener) => {
  if (!listeners[key]) {
    listeners[key] = [];
  }

  listeners[key].push(listener);
};

const removeListener = (key, listener) => {
  if (!listeners[key]) {
    return;
  }

  const pos = listeners[key].indexOf(listener);

  if (pos !== -1) {
    listeners[key].splice(pos, 1);
  }
};

const checkAndNotifyListeners = key => {
  if (responseCache[key]) {
    notifyListeners(key, null, {
      status: OPERATION_STATUS.REFRESHING,
      data: responseCache[key]
    });
  } else {
    notifyListeners(key, null, {
      status: OPERATION_STATUS.LOADING
    });
  }
};

const sendToNativeStorage = (response, options) => {
  if (options.shouldNotStoreInNative) {
    return;
  }

  eventbus.publish(
    NATIVE_STORAGE_EVENT_BUS_KEY,
    options.nativeStorageKey,
    response,
    options.extraData
  );
};

// options: { isBatched, sharedCacheKey, batchCallback, shouldNotStoreInNative, nativeStorageKey, extraData }
const makeRequest = (key, api, { params = [], options = {} } = {}) => {
  if (options.isBatched) {
    (params[0] || []).forEach(id => {
      checkAndNotifyListeners(`${options.sharedCacheKey}${id}`);
    });
  } else {
    checkAndNotifyListeners(key);
  }

  if (!promises[key]) {
    promises[key] = api(...(params || []));
  }

  promises[key]
    .then(response => {
      if (options.isBatched) {
        options.batchCallback(response, null, options.extraData);
        delete promises[key];
        sendToNativeStorage(response, options);
        return;
      }

      responseCache[key] = response;
      sendToNativeStorage(response, options);

      notifyListeners(key, null, {
        status: OPERATION_STATUS.SUCCESS,
        data: response
      });

      delete promises[key];
    })
    .catch(err => {
      if (options.isBatched) {
        options.batchCallback(null, err, options.extraData);
        delete promises[key];
        return;
      }

      notifyListeners(key, err);

      delete promises[key];
    });
};

const getCacheForKey = key => responseCache[key] || null;

const setCacheForKey = (key, value, { error = null } = {}) => {
  if (!error) {
    responseCache[key] = value;
  } else {
    Sentry.captureException(error);
  }

  notifyListeners(key, error, {
    status: OPERATION_STATUS.UPDATE,
    data: responseCache[key]
  });
};

const deleteCacheForKeys = keys => {
  keys.forEach(key => {
    delete responseCache[key];
    notifyListeners(key, null, {
      status: OPERATION_STATUS.UPDATE,
      data: null
    });
  });
};

const doesCacheExistForKey = key => typeof responseCache[key] !== 'undefined';

export default {
  reset,
  OPERATION_STATUS,
  attachListener,
  removeListener,
  makeRequest,
  getCacheForKey,
  setCacheForKey,
  deleteCacheForKeys,
  notifyListeners,
  doesCacheExistForKey,
  checkAndNotifyListeners
};
